import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ReactQuill from 'react-quill'
import imageCompression from 'browser-image-compression'
import heic2any from 'heic2any'
import { uploadToS3 } from '../actions/user'
import 'react-quill/dist/quill.snow.css'
import '../assets/QuillEditor.css'

let timeout = null

class QuillEditor extends Component {
  constructor(props) {
    super(props)
    this.state = {
      text: '',
      cursorBottomPosition: 0,
    }

    QuillEditor.modules = {
      toolbar: [
        [{ header: '1' }, { header: '2' }],
        ['bold', 'italic', 'strike', 'link'],
        ['image'],
      ],
      clipboard: {
        matchVisual: false,
      },
    }

    this.imageHandler = this.imageHandler.bind(this)
    this.insertToEditor = this.insertToEditor.bind(this)
    this.getCompressedImage = this.getCompressedImage.bind(this)
    this.convertHEICToAny = this.convertHEICToAny.bind(this)
  }

  componentDidMount() {
    const thisEditor = this.quillTextarea.editor

    // autofocus
    thisEditor.getSelection(true)
    thisEditor.setSelection(thisEditor.getLength(), 0)

    // add custom image handler to editor
    if (!this.props.readOnly) {
      thisEditor.getModule('toolbar').addHandler('image', () => {
        this.imageHandler()
      })
    }

    // UX - Add sticky toolbar
    let qlToolbar = document.querySelectorAll('.quillEditor .ql-toolbar')[0]
    if (qlToolbar !== undefined) {
      let stop = qlToolbar.offsetTop,
        docBody =
          document.documentElement || document.body.parentNode || document.body,
        hasOffset = window.pageYOffset !== undefined,
        scrollTop

      window.onscroll = function (e) {
        scrollTop = hasOffset ? window.pageYOffset : docBody.scrollTop
        if (scrollTop >= stop) {
          qlToolbar.classList.add('sticky')
        } else {
          qlToolbar.classList.remove('sticky')
        }
      }
    }
  }

  insertToEditor(url) {
    // push image url to editor.
    if (url) {
      const range = this.quillTextarea.editor.getSelection()
      this.quillTextarea.editor.insertEmbed(range.index, 'image', url)
    } else {
      console.log('Image upload failed')
    }
  }

  async convertHEICToAny(file) {
    try {
      console.log('HEIC image is being converted...')
      await heic2any({
        blob: file,
        toType: 'image/jpeg',
        quality: 0.8,
      }).then((result) => (file = result))
    } catch (error) {
      console.warn(error)
    }
    if (/^image\//.test(file.type)) {
      try {
        file.name = 'converted.jpeg'
        this.getCompressedImage(file)
      } catch (err) {
        console.log(err)
      }
    } else {
      console.warn('Could not convert image at the moment, please try later.')
    }
  }

  imageHandler() {
    let input = document.createElement('input')
    let temp = document.getElementById('quill-custom-container')
    input.setAttribute('type', 'file')
    input.style.cursor = 'pointer'
    input.style.opacity = 0
    input.style.position = 'absolute'
    input.style.width = '1px'
    input.style.height = '1px'
    input.style.accept = 'image/*'
    temp.appendChild(input)
    input.click()

    // Listen upload local image and save to S3
    input.addEventListener('change', () => {
      const file = input.files[0]

      // Disable editor
      this.quillTextarea.editor.enable(false)
      // Save current cursor state
      const range = this.quillTextarea.editor.getSelection()
      // Insert temporary loading placeholder image
      this.quillTextarea.editor.insertEmbed(
        range.index,
        'image',
        `${window.location.origin}/images/placeholder.gif`
      )
      // Move cursor to right side of image (easier to continue typing)
      this.quillTextarea.editor.setSelection(range.index + 1)

      // file type is only image.
      if (
        this.props.iPhoneExtensions &&
        this.props.iPhoneExtensions.includes(file.name.split('.')[1])
      ) {
        this.convertHEICToAny(file)
      } else if (/^image\//.test(file.type)) {
        try {
          this.getCompressedImage(file)
        } catch (err) {
          console.log(err)
        }
      } else {
        console.warn('You could only upload images.')
      }
    })
  }

  async getCompressedImage(imageFile) {
    var options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 2000,
      useWebWorker: false,
    }
    try {
      console.log('Image is being compressed...')
      let compressedFile = ''
      await imageCompression(imageFile, options).then(
        (result) => (compressedFile = result)
      )
      console.log('Image is being sent to S3...')
      this.props
        .uploadToS3(compressedFile, Date.now())
        .then((result) => this.insertToEditor(result))
        .then(() => {
          // remove placeholder gif
          const range = this.quillTextarea.editor.getSelection()
          this.quillTextarea.editor.deleteText(range.index - 1, 1)
          // enable editor
          this.quillTextarea.editor.enable(true)
          this.quillTextarea.editor.setSelection(range.index + 1)
        })
    } catch (error) {
      console.warn('Image could not be uploaded: ', error)
    }
  }

  _handleChange = (value) => {
    this.setState({ text: value })
    const { textCallback } = this.props

    // UX to add about 50px room on the bottom of the page when writer reaches to the bottom of the screen while typing
    const { cursorBottomPosition } = this.state
    if (this.quillTextarea) {
      const thisEditor = this.quillTextarea.editor
      const lastIndex = thisEditor.getSelection()

      if (lastIndex) {
        // TODO: cause thisEditor.getSelection() returns undefined when content is pasted into Quill editor.
        const newCursorBottomPosition = thisEditor.getBounds(lastIndex).bottom
        if (
          cursorBottomPosition !== newCursorBottomPosition &&
          newCursorBottomPosition + 300 > window.innerHeight
        ) {
          this.setState({
            cursorBottomPosition: newCursorBottomPosition,
          })
          window.scrollBy(0, 40)
        }
      }
    }

    let newWordArray = []
    setTimeout(function () {
      let words = value.split(/\s+/)
      for (var i = 0; i < words.length; i++) {
        if (words[i]) {
          newWordArray.push(words[i])
        }
      }
    }, 500)

    clearTimeout(timeout)

    timeout = setTimeout(function () {
      textCallback(value, newWordArray.length)
    }, 500)
  }

  render() {
    const { readOnly, value } = this.props

    return (
      <div id="quill-custom-container">
        <ReactQuill
          className="quillEditor"
          defaultValue={value ? value : this.state.text}
          theme="snow"
          readOnly={readOnly}
          modules={
            readOnly
              ? { toolbar: false, clipboard: { matchVisual: false } }
              : QuillEditor.modules
          }
          placeholder={'Type something here...'}
          onChange={readOnly ? null : this._handleChange}
          ref={(quilltext) => (this.quillTextarea = quilltext)}
          scrollingContainer="html, body"
        />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    iPhoneExtensions: state.user.iPhoneExtensions,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      uploadToS3,
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(QuillEditor)
