import React, { useEffect, useState } from 'react'
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverlay,
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  rectSortingStrategy,
} from '@dnd-kit/sortable'

import { Book, BookGrid } from './Book'

export const SortableBooks = (props) => {
  const [activeId, setActiveId] = useState(null)
  const [items, setItems] = useState([])
  const { callbackNewOrder } = props

  useEffect(() => {
    setItems(props.books)
  }, [props.books])

  useEffect(() => {
    callbackNewOrder(items)
  }, [JSON.stringify(items)])

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )

  const handleDragEnd = (event) => {
    const { active, over } = event

    if (active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.findIndex(({ id }) => id === active.id)
        const newIndex = items.findIndex(({ id }) => id === over.id)

        return arrayMove(items, oldIndex, newIndex)
      })
    }
  }

  const handleDragStart = (event) => {
    setActiveId(event.active.id)
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
    >
      <SortableContext items={items} strategy={rectSortingStrategy}>
        {items.map((item) => {
          return (
            <Book
              id={item.id}
              key={item.id}
              book={item}
              disabled={!props.reorderMode} // Disable when reorderMode is Off(false) I know it's confusing lol
            />
          )
        })}
      </SortableContext>

      <DragOverlay>
        {activeId ? (
          <BookGrid id={activeId} book={items.find((i) => i.id === activeId)} />
        ) : null}
      </DragOverlay>
    </DndContext>
  )
}
