import Amplify, { API, graphqlOperation } from 'aws-amplify'
import aws_exports from '../aws-exports'
import * as mutations from '../graphql/mutations'
import * as subscriptions from '../graphql/subscriptions'
import * as customQueries from '../customgraphql/queries'
import * as queries from '../graphql/queries'

import avocado from '../assets/images/avocado.svg'
import watermelon from '../assets/images/watermelon-icon.svg'

Amplify.configure(aws_exports)

// Create a Notification
// -----------------------------------------------------------------------------
export function createNotificationSubscription() {
  return async (dispatch, getState) => {
    const subscription = await API.graphql(
      graphqlOperation(subscriptions.onCreateNotification)
    ).subscribe({
      next: (eventData) => {
        const notification = eventData.value.data.onCreateNotification
        if (
          getState().user.isAuthenticated &&
          notification.user.id === getState().user.userData.id
        )
          dispatch({ type: 'CREATE_NOTIFICATION_SUBSCRIPTION', notification })
      },
    })
    return () => subscription.unsubscribe()
  }
}

// Get More Notifications
// -----------------------------------------------------------------------------
export function getMoreNotifications(username, nextToken) {
  return async (dispatch) => {
    try {
      const data = await API.graphql(
        graphqlOperation(customQueries.getUser, {
          id: username,
          nextTokenNotification: nextToken,
        })
      )
      const newNotificationData = {
        items: data.data.getUser.notifications.items,
        nextToken: data.data.getUser.notifications.nextToken,
      }
      dispatch({ type: 'ADD_NOTIFICATION_DATA', newNotificationData })
    } catch (err) {
      console.log('error reading notification: ', err)
    }
  }
}

// Delete Notification
// -----------------------------------------------------------------------------
export function deleteNotification(notifId) {
  return (dispatch) => {
    try {
      API.graphql(
        graphqlOperation(mutations.deleteNotification, {
          input: { id: notifId },
        })
      )
      dispatch({ type: 'DELETE_NOTIFICATION', notifId })
    } catch (err) {
      console.log('error deleting notification: ', err)
    }
  }
}

// Update Notification
// -----------------------------------------------------------------------------
export function readNotification(notifId) {
  return (dispatch) => {
    try {
      API.graphql(
        graphqlOperation(mutations.updateNotification, {
          input: { id: notifId, read: true },
        })
      )
      dispatch({ type: 'UPDATE_NOTIFICATION', notifId })
    } catch (err) {
      console.log('error reading notification: ', err)
    }
  }
}

// Create Notification when add comment
// -----------------------------------------------------------------------------
export async function createNotificationFromComment(
  commentInput,
  journalAuthorId,
  userData
) {
  if (commentInput.commentAuthorId === journalAuthorId) return
  const data = await API.graphql(
    graphqlOperation(customQueries.getPublicJournal, {
      id: commentInput.commentJournalId,
    })
  )
  const comments = data.data.getPublicJournal.comments.items
  const notification = {
    content: `added a new comment on your journal`,
    category: 'comment',
    link: `/public-journal/${commentInput.commentJournalId}#${
      comments[comments.length - 1].id
    }`,
    read: false,
    actionUser: {
      userID: userData.id,
      displayName: userData.displayName,
      photoURL: userData.profileImage,
    },
    notificationUserId: journalAuthorId,
  }
  await API.graphql(
    graphqlOperation(mutations.createNotification, { input: notification })
  )
  sendPushNotification(
    notification.notificationUserId,
    (notification.actionUser.displayName
      ? notification.actionUser.displayName
      : notification.actionUser.userID) +
      ' ' +
      notification.content,
    notification.category
  )

  sendEmailNotification(
    notification.notificationUserId,
    (notification.actionUser.displayName
      ? notification.actionUser.displayName
      : notification.actionUser.userID) +
      ' ' +
      notification.content,
    notification.category
  )
}

// Create Notification when user loves journal
// -----------------------------------------------------------------------------
export async function createNotificationFromLovedJournal(
  userLovedJournalId,
  journalAuthorId,
  userData
) {
  if (userData.id === journalAuthorId) return
  const notification = {
    content: `liked your journal`,
    category: 'love journal',
    link: `/public-journal/${userLovedJournalId}`,
    read: false,
    actionUser: {
      userID: userData.id,
      displayName: userData.displayName,
      photoURL: userData.profileImage,
    },
    notificationUserId: journalAuthorId,
  }
  const exist = await checkExistNotification(notification, userData.id, false)
  if (exist) return
  await API.graphql(
    graphqlOperation(mutations.createNotification, { input: notification })
  )
  sendPushNotification(
    notification.notificationUserId,
    (notification.actionUser.displayName
      ? notification.actionUser.displayName
      : notification.actionUser.userID) +
      ' ' +
      notification.content,
    notification.category
  )
}

// Create Notification when user loves comment
// -----------------------------------------------------------------------------
export async function createNotificationFromLovedComment(
  comment,
  journalID,
  userData
) {
  if (comment.author.id === userData.id) return
  const notification = {
    content: `liked your comment`,
    category: 'love comment',
    link: `/public-journal/${journalID}#${comment.id}`,
    read: false,
    actionUser: {
      userID: userData.id,
      displayName: userData.displayName,
      photoURL: userData.profileImage,
    },
    notificationUserId: comment.author.id,
  }
  const exist = await checkExistNotification(notification, userData.id, false)
  if (exist) return
  await API.graphql(
    graphqlOperation(mutations.createNotification, { input: notification })
  )
  sendPushNotification(
    notification.notificationUserId,
    (notification.actionUser.displayName
      ? notification.actionUser.displayName
      : notification.actionUser.userID) +
      ' ' +
      notification.content,
    notification.category
  )
}

// Create Notification when user follows author
// -----------------------------------------------------------------------------
export async function createNotificationFromFollow(targetId, userData) {
  if (targetId === userData.id) return
  const notification = {
    content: `is following you`,
    category: 'follow',
    link: `/author/${encodeURIComponent(userData.id)}`,
    read: false,
    actionUser: {
      userID: userData.id,
      displayName: userData.displayName,
      photoURL: userData.profileImage,
    },
    notificationUserId: targetId,
  }
  const exist = await checkExistNotification(notification, targetId, true)
  if (exist) return
  await API.graphql(
    graphqlOperation(mutations.createNotification, { input: notification })
  )
  sendPushNotification(
    notification.notificationUserId,
    (notification.actionUser.displayName
      ? notification.actionUser.displayName
      : notification.actionUser.userID) +
      ' ' +
      notification.content,
    notification.category
  )

  // TODO: check 'custom:emailNotification' and don't even call the function if it's set to "off"
  sendEmailNotification(
    notification.notificationUserId,
    (notification.actionUser.displayName
      ? notification.actionUser.displayName
      : notification.actionUser.userID) +
      ' ' +
      notification.content,
    notification.category
  )
}

// Create Notification form 30 journal Avocado
// -----------------------------------------------------------------------------
export async function createNotificationThirtyJournal(event, userId) {
  const notification = {
    content: `You earned an avocado`,
    category: 'reward',
    link: `/my-account`,
    read: false,
    actionUser: {
      userID: 'goodnightjournal',
      displayName: `You wrote ${event} journals!`,
      photoURL: `${avocado}`,
    },
    notificationUserId: userId,
  }
  await API.graphql(
    graphqlOperation(mutations.createNotification, { input: notification })
  )
  sendPushNotification(
    notification.notificationUserId,
    notification.content,
    notification.category
  )
}

// Weekly challenge completion notification
// TODO: implement this on when journal is published.
// -----------------------------------------------------------------------------
export async function createNotificationWeeklyChallengeCompletion(
  event,
  userId
) {
  const notification = {
    content: `You completed weekly challenge`,
    category: 'challenge',
    link: `/challenge`,
    read: false,
    actionUser: {
      userID: 'goodnightjournal',
      displayName: `Week of ${event}!`,
      photoURL: `${watermelon}`,
    },
    notificationUserId: userId,
  }
  await API.graphql(
    graphqlOperation(mutations.createNotification, { input: notification })
  )
  sendPushNotification(
    notification.notificationUserId,
    notification.content,
    notification.category
  )
}

// Send notification on creating chat room
// -----------------------------------------------------------------------------
export async function createChatRoomCreatedNotification(
  chatRoomUserUserId,
  chatRoomUserChatRoomId,
  userData
) {
  const notification = {
    content: `invited you to a chat`,
    category: 'chat',
    link: `/chat/${chatRoomUserChatRoomId}`,
    read: false,
    actionUser: {
      userID: userData.id,
      displayName: userData.displayName,
      photoURL: userData.profileImage,
    },
    notificationUserId: chatRoomUserUserId,
  }

  await API.graphql(
    graphqlOperation(mutations.createNotification, { input: notification })
  )
  sendPushNotification(
    notification.notificationUserId,
    (notification.actionUser.displayName
      ? notification.actionUser.displayName
      : notification.actionUser.userID) +
      ' ' +
      notification.content,
    notification.category
  )
  sendEmailNotification(
    notification.notificationUserId,
    (notification.actionUser.displayName
      ? notification.actionUser.displayName
      : notification.actionUser.userID) +
      ' ' +
      notification.content,
    notification.category
  )
}

// TODO: this check existing notification function should be improved as listNotification won't get exact data that needs to be checked.
async function checkExistNotification(notification, userID, isFollow) {
  const checkData = await API.graphql(
    graphqlOperation(queries.listNotifications, {
      filter: {
        content: { eq: notification.content },
        link: { eq: notification.link },
      },
    })
  )
  const notifications = checkData.data.listNotifications.items
  if (notifications.length === 0) return false
  if (isFollow)
    return notifications.filter((item) => item.user.id === userID).length > 0
  return (
    notifications.filter((item) => item.actionUser.userID === userID).length > 0
  )
}

async function sendPushNotification(sendTo, title, body) {
  await API.graphql(
    graphqlOperation(mutations.gnjAmplifyExpoSendNotification, {
      sendTo,
      title,
      body,
    })
  )
}

async function sendEmailNotification(sendTo, title, body) {
  const emailBody =
    "<p>You have a new notification at Goodnight Journal!</p><p><a href='https://app.goodnightjournal.com/notification?utm_source=email&utm_medium=notification&utm_campaign=notification'>Click here to see the notification.</a></p><p>&nbsp;</p><p><a href='https://www.goodnightjournal.com/?utm_source=app-notification-email&utm_content=view-my-notification'><img alt='Goodnight Journal' src='https://www.goodnightjournal.com/logo-512-transparant.png' style='height: 60px; width: 60px' width='60'></a></p><p><small>Goodnight Journal. <a href='https://app.goodnightjournal.com/my-account/setting'>Unsubscribe</a> notification email.</small></p>"
  await API.graphql(
    graphqlOperation(mutations.gnjAmplifySendEmailNotification, {
      sendTo,
      title,
      body: emailBody,
    })
  )
}
