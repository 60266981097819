import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import LovedUsersListItem from "../components/LovedUsersListItem";
import {
  getLovedUsersFromComment,
  getLovedUsersFromJournal
} from "../actions/journal";
import LoadingCard from '../components/LoadingCard';
import loadingIcon from '../assets/images/loading-dark.svg'

class LovedUsers extends Component {
  constructor(props){
    super(props)
    this.state = {
      showLoadingIcon: false,
    }
    this._loadMore = this._loadMore.bind(this)
    this._loadLovedUser = this._loadLovedUser.bind(this)
  }

  componentDidMount() {
    this._loadLovedUser(null)
  }

  async _loadMore() {
    const { nextToken } = this.props.lover;
    if (nextToken) {
      this.setState({ showLoadingIcon: true })
      await this._loadLovedUser(nextToken)
      this.setState({ showLoadingIcon: false })
    }
  }

  async _loadLovedUser(nextToken) {
    const id = this.props.match.params.journalOrComment_uid;
    const category = this.props.match.params.category;
    try {
      if (category === 'journal')
        await this.props.getLovedUsersFromJournal(id, nextToken)
      else
        await this.props.getLovedUsersFromComment(id, nextToken)
    } catch(err) {
      console.log(err)
    }
  }

  render() {
    const {loverLoading, lovedUsers, nextToken} = this.props.lover
    return (
      <div className="loved-users">
        <ul className="sub-nav sub-nav-back">
          <li>
            <span className="link-text trackit" onClick={() => this.props.history.goBack()} id="follower_subNav_go_back">
              <ion-icon name="arrow-round-back"></ion-icon> Back
            </span>
          </li>
          <li>
          </li>
          <li>
          </li>
        </ul>
        <div className="Container Container--md Container--side-padding">
          <h1>Loved Users</h1>
          <div className="user-stats loved-list">
            {loverLoading && <LoadingCard />}
            {!loverLoading && lovedUsers.length === 0 && (
              <p>There are no loved users</p>
            )}
            {!loverLoading && lovedUsers.length > 0 && (
              <LovedUsersListItem lovedUsers={lovedUsers} />
            )}
          </div>
          {lovedUsers[lovedUsers.length - 1] && nextToken ? 
            <div className="load-more">
              {this.state.showLoadingIcon
                ? <img alt="Loading loved users" className="loading-icon" src={loadingIcon} />
                : <button className="btn-secondary" onClick={this._loadMore}>Load more</button>}
            </div> 
            : ''
          }
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getLovedUsersFromComment,
    getLovedUsersFromJournal
  }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LovedUsers);
