import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import ChatRoomListItem from "../components/ChatRoomListItem"
import LoadingCard from '../components/LoadingCard';

import { 
  updateChatRoomRead, 
  getMoreChatRoomUsers
} from "../actions/chat"

import { getUserData } from "../actions/user";

function Chat(props) {
  const [currentUsername, setCurrentUsername] = useState(null)
  const [nextToken, setNextToken] = useState(null)

  useEffect(() => {
    setCurrentUsername(props.user.accountData.idToken.payload["cognito:username"])
    props.user.userData && setNextToken(props.user.userData.chatRoomUser.nextToken)
  })

  useEffect(() => {
    const getUserData = async () => {
      try {
        if (props.user.isAuthenticated)
          await props.getUserData(props.user.accountData.idToken.payload["cognito:username"], props.journal.myJournalLimit);
      } catch (err) {
        console.log(err);
      }
    }
    getUserData()
  }, [])

  if (props.user.userData === null) {
    return (
      <div className="chat Container">
        <div className="Container--side-padding header">
          <h1>Chat</h1>
        </div>  
        <LoadingCard />
      </div>
    )
  }
  
  const userHasSeenTheLastMessage = async (callback) => {
    await props.updateChatRoomRead(callback, true)
  }

  const loadMoreChatRooms = async () => {
    if(nextToken){
      await props.getMoreChatRoomUsers(currentUsername, nextToken)
    }
  }

  const renderChatList = props.user.userData.chatRoomUser.items.map( chat => {
    return <ChatRoomListItem key={chat.id} {...chat} currentUser={currentUsername} history={props.history} userHasSeenTheLastMessage={userHasSeenTheLastMessage} />
  })

  return (
    <div className="chat Container">
      <div className="Container--side-padding header">
        <h1>Chat</h1>
      </div>

        <div className="Container">
          {renderChatList.length > 0 ? 
            renderChatList
          : 
          <div className="chat Container">
            <div className="Container Container--sm sm-form no-chat-found">
            <h1><span role="img" aria-label="Sweat">😅</span><br />No chats yet</h1>
            <p><button className="btn-secondary" onClick={() => props.history.push("/following/" + currentUsername)}>Find someone to talk to </button></p>
            </div>
          </div>
          }
          {nextToken && <div className="load-more"><button className="btn-secondary" onClick={loadMoreChatRooms} id="chat_load_more_chat_rooms">Load more</button></div>}
        </div>

    </div>
  );
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateChatRoomRead,
      getMoreChatRoomUsers,
      getUserData
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Chat)
