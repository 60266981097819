import profilePic from '../assets/images/profile-pic.svg'
import loadingIcon from '../assets/images/loading.svg'

export default function ProfilePic(thisPropsUser) {
	const { currentUser, profileImage } = thisPropsUser

	let profileImageURL = loadingIcon
	if(currentUser){
		if (currentUser.attributes && currentUser.attributes.picture) {
			profileImageURL = currentUser.attributes.picture
		} else {
			// TODO: This needs to be improved
			profileImageURL = profilePic
		}
	} else if(profileImage){
		profileImageURL = profileImage
	} else {
		profileImageURL = profilePic
	}

	return profileImageURL
}