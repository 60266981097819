import React from 'react';
import moment from "moment";

function ReceiptURL(url) {
  if(!url) {
    return
  }

  return <div className="membership-receipt">- <a href={url} className="link-text" target="_black">See receipt</a></div>
}

export function BillingListItem({ billingList }) {
  const str = billingList.amount.toString()
  const amountCharged = str.slice(0, -2) + "."+ str.slice(-2)

  return (
    <div>
      <small>
        <b>{moment.unix(billingList.created).format('MMM DD, YYYY')}</b> ${amountCharged} on {billingList.source.brand} card ending with {billingList.source.last4} {ReceiptURL(billingList.receipt_url)}
      </small>
    </div>
  )
}