/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateJournal = /* GraphQL */ `
  subscription OnCreateJournal($owner: String) {
    onCreateJournal(owner: $owner) {
      id
      privacy
      draft
      content
      journalAuthorId
      markedAs
      lovedCount
      commentCount
      journalWordCount
      journalBookId
      createdAt
      updatedAt
      author {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        journals {
          nextToken
        }
        chatRoomUser {
          nextToken
        }
        chatMassages {
          nextToken
        }
        bookOrder
        createdAt
        updatedAt
        lovedJournals {
          nextToken
        }
        books {
          nextToken
        }
        publicJournals {
          nextToken
        }
        lovedComments {
          nextToken
        }
        comments {
          nextToken
        }
        notifications {
          nextToken
        }
      }
      owner
      book {
        id
        book
        bookUserId
        bookCoverImg
        bookJournalCount
        journals {
          nextToken
        }
        createdAt
        updatedAt
        user {
          id
          uuid
          displayName
          profileImage
          membership
          totalJournal
          followingCount
          followerCount
          bookOrder
          createdAt
          updatedAt
        }
        publicJournals {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateJournal = /* GraphQL */ `
  subscription OnUpdateJournal($owner: String) {
    onUpdateJournal(owner: $owner) {
      id
      privacy
      draft
      content
      journalAuthorId
      markedAs
      lovedCount
      commentCount
      journalWordCount
      journalBookId
      createdAt
      updatedAt
      author {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        journals {
          nextToken
        }
        chatRoomUser {
          nextToken
        }
        chatMassages {
          nextToken
        }
        bookOrder
        createdAt
        updatedAt
        lovedJournals {
          nextToken
        }
        books {
          nextToken
        }
        publicJournals {
          nextToken
        }
        lovedComments {
          nextToken
        }
        comments {
          nextToken
        }
        notifications {
          nextToken
        }
      }
      owner
      book {
        id
        book
        bookUserId
        bookCoverImg
        bookJournalCount
        journals {
          nextToken
        }
        createdAt
        updatedAt
        user {
          id
          uuid
          displayName
          profileImage
          membership
          totalJournal
          followingCount
          followerCount
          bookOrder
          createdAt
          updatedAt
        }
        publicJournals {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteJournal = /* GraphQL */ `
  subscription OnDeleteJournal($owner: String) {
    onDeleteJournal(owner: $owner) {
      id
      privacy
      draft
      content
      journalAuthorId
      markedAs
      lovedCount
      commentCount
      journalWordCount
      journalBookId
      createdAt
      updatedAt
      author {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        journals {
          nextToken
        }
        chatRoomUser {
          nextToken
        }
        chatMassages {
          nextToken
        }
        bookOrder
        createdAt
        updatedAt
        lovedJournals {
          nextToken
        }
        books {
          nextToken
        }
        publicJournals {
          nextToken
        }
        lovedComments {
          nextToken
        }
        comments {
          nextToken
        }
        notifications {
          nextToken
        }
      }
      owner
      book {
        id
        book
        bookUserId
        bookCoverImg
        bookJournalCount
        journals {
          nextToken
        }
        createdAt
        updatedAt
        user {
          id
          uuid
          displayName
          profileImage
          membership
          totalJournal
          followingCount
          followerCount
          bookOrder
          createdAt
          updatedAt
        }
        publicJournals {
          nextToken
        }
      }
    }
  }
`;
export const onCreateChatRoomUser = /* GraphQL */ `
  subscription OnCreateChatRoomUser {
    onCreateChatRoomUser {
      id
      chatRoomUserUserId
      chatRoomUserChatRoomId
      members
      chatRoom {
        id
        createdBy
        receiverHasRead
        chatRoomUsers {
          nextToken
        }
        messages {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      user {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        journals {
          nextToken
        }
        chatRoomUser {
          nextToken
        }
        chatMassages {
          nextToken
        }
        bookOrder
        createdAt
        updatedAt
        lovedJournals {
          nextToken
        }
        books {
          nextToken
        }
        publicJournals {
          nextToken
        }
        lovedComments {
          nextToken
        }
        comments {
          nextToken
        }
        notifications {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateChatRoomUser = /* GraphQL */ `
  subscription OnUpdateChatRoomUser {
    onUpdateChatRoomUser {
      id
      chatRoomUserUserId
      chatRoomUserChatRoomId
      members
      chatRoom {
        id
        createdBy
        receiverHasRead
        chatRoomUsers {
          nextToken
        }
        messages {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      user {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        journals {
          nextToken
        }
        chatRoomUser {
          nextToken
        }
        chatMassages {
          nextToken
        }
        bookOrder
        createdAt
        updatedAt
        lovedJournals {
          nextToken
        }
        books {
          nextToken
        }
        publicJournals {
          nextToken
        }
        lovedComments {
          nextToken
        }
        comments {
          nextToken
        }
        notifications {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteChatRoomUser = /* GraphQL */ `
  subscription OnDeleteChatRoomUser {
    onDeleteChatRoomUser {
      id
      chatRoomUserUserId
      chatRoomUserChatRoomId
      members
      chatRoom {
        id
        createdBy
        receiverHasRead
        chatRoomUsers {
          nextToken
        }
        messages {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      user {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        journals {
          nextToken
        }
        chatRoomUser {
          nextToken
        }
        chatMassages {
          nextToken
        }
        bookOrder
        createdAt
        updatedAt
        lovedJournals {
          nextToken
        }
        books {
          nextToken
        }
        publicJournals {
          nextToken
        }
        lovedComments {
          nextToken
        }
        comments {
          nextToken
        }
        notifications {
          nextToken
        }
      }
    }
  }
`;
export const onCreateChatRoom = /* GraphQL */ `
  subscription OnCreateChatRoom {
    onCreateChatRoom {
      id
      createdBy
      receiverHasRead
      chatRoomUsers {
        items {
          id
          chatRoomUserUserId
          chatRoomUserChatRoomId
          members
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          content
          messageChatRoomId
          messageUserId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateChatRoom = /* GraphQL */ `
  subscription OnUpdateChatRoom {
    onUpdateChatRoom {
      id
      createdBy
      receiverHasRead
      chatRoomUsers {
        items {
          id
          chatRoomUserUserId
          chatRoomUserChatRoomId
          members
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          content
          messageChatRoomId
          messageUserId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteChatRoom = /* GraphQL */ `
  subscription OnDeleteChatRoom {
    onDeleteChatRoom {
      id
      createdBy
      receiverHasRead
      chatRoomUsers {
        items {
          id
          chatRoomUserUserId
          chatRoomUserChatRoomId
          members
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          content
          messageChatRoomId
          messageUserId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateMessage = /* GraphQL */ `
  subscription OnCreateMessage {
    onCreateMessage {
      id
      content
      messageChatRoomId
      messageUserId
      chatRoom {
        id
        createdBy
        receiverHasRead
        chatRoomUsers {
          nextToken
        }
        messages {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      user {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        journals {
          nextToken
        }
        chatRoomUser {
          nextToken
        }
        chatMassages {
          nextToken
        }
        bookOrder
        createdAt
        updatedAt
        lovedJournals {
          nextToken
        }
        books {
          nextToken
        }
        publicJournals {
          nextToken
        }
        lovedComments {
          nextToken
        }
        comments {
          nextToken
        }
        notifications {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateMessage = /* GraphQL */ `
  subscription OnUpdateMessage {
    onUpdateMessage {
      id
      content
      messageChatRoomId
      messageUserId
      chatRoom {
        id
        createdBy
        receiverHasRead
        chatRoomUsers {
          nextToken
        }
        messages {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      user {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        journals {
          nextToken
        }
        chatRoomUser {
          nextToken
        }
        chatMassages {
          nextToken
        }
        bookOrder
        createdAt
        updatedAt
        lovedJournals {
          nextToken
        }
        books {
          nextToken
        }
        publicJournals {
          nextToken
        }
        lovedComments {
          nextToken
        }
        comments {
          nextToken
        }
        notifications {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteMessage = /* GraphQL */ `
  subscription OnDeleteMessage {
    onDeleteMessage {
      id
      content
      messageChatRoomId
      messageUserId
      chatRoom {
        id
        createdBy
        receiverHasRead
        chatRoomUsers {
          nextToken
        }
        messages {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      user {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        journals {
          nextToken
        }
        chatRoomUser {
          nextToken
        }
        chatMassages {
          nextToken
        }
        bookOrder
        createdAt
        updatedAt
        lovedJournals {
          nextToken
        }
        books {
          nextToken
        }
        publicJournals {
          nextToken
        }
        lovedComments {
          nextToken
        }
        comments {
          nextToken
        }
        notifications {
          nextToken
        }
      }
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser {
    onCreateUser {
      id
      uuid
      displayName
      profileImage
      membership
      totalJournal
      followingCount
      followerCount
      journals {
        items {
          id
          privacy
          draft
          content
          journalAuthorId
          markedAs
          lovedCount
          commentCount
          journalWordCount
          journalBookId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      chatRoomUser {
        items {
          id
          chatRoomUserUserId
          chatRoomUserChatRoomId
          members
          createdAt
          updatedAt
        }
        nextToken
      }
      chatMassages {
        items {
          id
          content
          messageChatRoomId
          messageUserId
          createdAt
          updatedAt
        }
        nextToken
      }
      bookOrder
      createdAt
      updatedAt
      lovedJournals {
        items {
          id
          userLovedJournalUserId
          userLovedJournalJournalId
          createdAt
          updatedAt
        }
        nextToken
      }
      books {
        items {
          id
          book
          bookUserId
          bookCoverImg
          bookJournalCount
          createdAt
          updatedAt
        }
        nextToken
      }
      publicJournals {
        items {
          id
          privacy
          content
          journalAuthorId
          markedAs
          lovedCount
          commentCount
          journalWordCount
          publicJournalBookId
          createdAt
          updatedAt
        }
        nextToken
      }
      lovedComments {
        items {
          id
          userLovedCommentUserId
          userLovedCommentCommentId
          createdAt
          updatedAt
        }
        nextToken
      }
      comments {
        items {
          id
          content
          commentLovedCount
          createdAt
          updatedAt
        }
        nextToken
      }
      notifications {
        items {
          id
          content
          category
          link
          read
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser {
    onUpdateUser {
      id
      uuid
      displayName
      profileImage
      membership
      totalJournal
      followingCount
      followerCount
      journals {
        items {
          id
          privacy
          draft
          content
          journalAuthorId
          markedAs
          lovedCount
          commentCount
          journalWordCount
          journalBookId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      chatRoomUser {
        items {
          id
          chatRoomUserUserId
          chatRoomUserChatRoomId
          members
          createdAt
          updatedAt
        }
        nextToken
      }
      chatMassages {
        items {
          id
          content
          messageChatRoomId
          messageUserId
          createdAt
          updatedAt
        }
        nextToken
      }
      bookOrder
      createdAt
      updatedAt
      lovedJournals {
        items {
          id
          userLovedJournalUserId
          userLovedJournalJournalId
          createdAt
          updatedAt
        }
        nextToken
      }
      books {
        items {
          id
          book
          bookUserId
          bookCoverImg
          bookJournalCount
          createdAt
          updatedAt
        }
        nextToken
      }
      publicJournals {
        items {
          id
          privacy
          content
          journalAuthorId
          markedAs
          lovedCount
          commentCount
          journalWordCount
          publicJournalBookId
          createdAt
          updatedAt
        }
        nextToken
      }
      lovedComments {
        items {
          id
          userLovedCommentUserId
          userLovedCommentCommentId
          createdAt
          updatedAt
        }
        nextToken
      }
      comments {
        items {
          id
          content
          commentLovedCount
          createdAt
          updatedAt
        }
        nextToken
      }
      notifications {
        items {
          id
          content
          category
          link
          read
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser {
    onDeleteUser {
      id
      uuid
      displayName
      profileImage
      membership
      totalJournal
      followingCount
      followerCount
      journals {
        items {
          id
          privacy
          draft
          content
          journalAuthorId
          markedAs
          lovedCount
          commentCount
          journalWordCount
          journalBookId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      chatRoomUser {
        items {
          id
          chatRoomUserUserId
          chatRoomUserChatRoomId
          members
          createdAt
          updatedAt
        }
        nextToken
      }
      chatMassages {
        items {
          id
          content
          messageChatRoomId
          messageUserId
          createdAt
          updatedAt
        }
        nextToken
      }
      bookOrder
      createdAt
      updatedAt
      lovedJournals {
        items {
          id
          userLovedJournalUserId
          userLovedJournalJournalId
          createdAt
          updatedAt
        }
        nextToken
      }
      books {
        items {
          id
          book
          bookUserId
          bookCoverImg
          bookJournalCount
          createdAt
          updatedAt
        }
        nextToken
      }
      publicJournals {
        items {
          id
          privacy
          content
          journalAuthorId
          markedAs
          lovedCount
          commentCount
          journalWordCount
          publicJournalBookId
          createdAt
          updatedAt
        }
        nextToken
      }
      lovedComments {
        items {
          id
          userLovedCommentUserId
          userLovedCommentCommentId
          createdAt
          updatedAt
        }
        nextToken
      }
      comments {
        items {
          id
          content
          commentLovedCount
          createdAt
          updatedAt
        }
        nextToken
      }
      notifications {
        items {
          id
          content
          category
          link
          read
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateFollowing = /* GraphQL */ `
  subscription OnCreateFollowing {
    onCreateFollowing {
      id
      user
      following
      followingProfile
      followerProfile
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateFollowing = /* GraphQL */ `
  subscription OnUpdateFollowing {
    onUpdateFollowing {
      id
      user
      following
      followingProfile
      followerProfile
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteFollowing = /* GraphQL */ `
  subscription OnDeleteFollowing {
    onDeleteFollowing {
      id
      user
      following
      followingProfile
      followerProfile
      createdAt
      updatedAt
    }
  }
`;
export const onCreateUserLovedJournal = /* GraphQL */ `
  subscription OnCreateUserLovedJournal {
    onCreateUserLovedJournal {
      id
      userLovedJournalUserId
      userLovedJournalJournalId
      createdAt
      updatedAt
      user {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        journals {
          nextToken
        }
        chatRoomUser {
          nextToken
        }
        chatMassages {
          nextToken
        }
        bookOrder
        createdAt
        updatedAt
        lovedJournals {
          nextToken
        }
        books {
          nextToken
        }
        publicJournals {
          nextToken
        }
        lovedComments {
          nextToken
        }
        comments {
          nextToken
        }
        notifications {
          nextToken
        }
      }
      journal {
        id
        privacy
        content
        journalAuthorId
        markedAs
        lovedCount
        commentCount
        journalWordCount
        publicJournalBookId
        createdAt
        updatedAt
        author {
          id
          uuid
          displayName
          profileImage
          membership
          totalJournal
          followingCount
          followerCount
          bookOrder
          createdAt
          updatedAt
        }
        lovedUsers {
          nextToken
        }
        book {
          id
          book
          bookUserId
          bookCoverImg
          bookJournalCount
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateUserLovedJournal = /* GraphQL */ `
  subscription OnUpdateUserLovedJournal {
    onUpdateUserLovedJournal {
      id
      userLovedJournalUserId
      userLovedJournalJournalId
      createdAt
      updatedAt
      user {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        journals {
          nextToken
        }
        chatRoomUser {
          nextToken
        }
        chatMassages {
          nextToken
        }
        bookOrder
        createdAt
        updatedAt
        lovedJournals {
          nextToken
        }
        books {
          nextToken
        }
        publicJournals {
          nextToken
        }
        lovedComments {
          nextToken
        }
        comments {
          nextToken
        }
        notifications {
          nextToken
        }
      }
      journal {
        id
        privacy
        content
        journalAuthorId
        markedAs
        lovedCount
        commentCount
        journalWordCount
        publicJournalBookId
        createdAt
        updatedAt
        author {
          id
          uuid
          displayName
          profileImage
          membership
          totalJournal
          followingCount
          followerCount
          bookOrder
          createdAt
          updatedAt
        }
        lovedUsers {
          nextToken
        }
        book {
          id
          book
          bookUserId
          bookCoverImg
          bookJournalCount
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteUserLovedJournal = /* GraphQL */ `
  subscription OnDeleteUserLovedJournal {
    onDeleteUserLovedJournal {
      id
      userLovedJournalUserId
      userLovedJournalJournalId
      createdAt
      updatedAt
      user {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        journals {
          nextToken
        }
        chatRoomUser {
          nextToken
        }
        chatMassages {
          nextToken
        }
        bookOrder
        createdAt
        updatedAt
        lovedJournals {
          nextToken
        }
        books {
          nextToken
        }
        publicJournals {
          nextToken
        }
        lovedComments {
          nextToken
        }
        comments {
          nextToken
        }
        notifications {
          nextToken
        }
      }
      journal {
        id
        privacy
        content
        journalAuthorId
        markedAs
        lovedCount
        commentCount
        journalWordCount
        publicJournalBookId
        createdAt
        updatedAt
        author {
          id
          uuid
          displayName
          profileImage
          membership
          totalJournal
          followingCount
          followerCount
          bookOrder
          createdAt
          updatedAt
        }
        lovedUsers {
          nextToken
        }
        book {
          id
          book
          bookUserId
          bookCoverImg
          bookJournalCount
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
      }
    }
  }
`;
export const onCreateBook = /* GraphQL */ `
  subscription OnCreateBook {
    onCreateBook {
      id
      book
      bookUserId
      bookCoverImg
      bookJournalCount
      journals {
        items {
          id
          privacy
          draft
          content
          journalAuthorId
          markedAs
          lovedCount
          commentCount
          journalWordCount
          journalBookId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      user {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        journals {
          nextToken
        }
        chatRoomUser {
          nextToken
        }
        chatMassages {
          nextToken
        }
        bookOrder
        createdAt
        updatedAt
        lovedJournals {
          nextToken
        }
        books {
          nextToken
        }
        publicJournals {
          nextToken
        }
        lovedComments {
          nextToken
        }
        comments {
          nextToken
        }
        notifications {
          nextToken
        }
      }
      publicJournals {
        items {
          id
          privacy
          content
          journalAuthorId
          markedAs
          lovedCount
          commentCount
          journalWordCount
          publicJournalBookId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateBook = /* GraphQL */ `
  subscription OnUpdateBook {
    onUpdateBook {
      id
      book
      bookUserId
      bookCoverImg
      bookJournalCount
      journals {
        items {
          id
          privacy
          draft
          content
          journalAuthorId
          markedAs
          lovedCount
          commentCount
          journalWordCount
          journalBookId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      user {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        journals {
          nextToken
        }
        chatRoomUser {
          nextToken
        }
        chatMassages {
          nextToken
        }
        bookOrder
        createdAt
        updatedAt
        lovedJournals {
          nextToken
        }
        books {
          nextToken
        }
        publicJournals {
          nextToken
        }
        lovedComments {
          nextToken
        }
        comments {
          nextToken
        }
        notifications {
          nextToken
        }
      }
      publicJournals {
        items {
          id
          privacy
          content
          journalAuthorId
          markedAs
          lovedCount
          commentCount
          journalWordCount
          publicJournalBookId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteBook = /* GraphQL */ `
  subscription OnDeleteBook {
    onDeleteBook {
      id
      book
      bookUserId
      bookCoverImg
      bookJournalCount
      journals {
        items {
          id
          privacy
          draft
          content
          journalAuthorId
          markedAs
          lovedCount
          commentCount
          journalWordCount
          journalBookId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      user {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        journals {
          nextToken
        }
        chatRoomUser {
          nextToken
        }
        chatMassages {
          nextToken
        }
        bookOrder
        createdAt
        updatedAt
        lovedJournals {
          nextToken
        }
        books {
          nextToken
        }
        publicJournals {
          nextToken
        }
        lovedComments {
          nextToken
        }
        comments {
          nextToken
        }
        notifications {
          nextToken
        }
      }
      publicJournals {
        items {
          id
          privacy
          content
          journalAuthorId
          markedAs
          lovedCount
          commentCount
          journalWordCount
          publicJournalBookId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreatePublicJournal = /* GraphQL */ `
  subscription OnCreatePublicJournal {
    onCreatePublicJournal {
      id
      privacy
      content
      journalAuthorId
      markedAs
      lovedCount
      commentCount
      journalWordCount
      publicJournalBookId
      createdAt
      updatedAt
      author {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        journals {
          nextToken
        }
        chatRoomUser {
          nextToken
        }
        chatMassages {
          nextToken
        }
        bookOrder
        createdAt
        updatedAt
        lovedJournals {
          nextToken
        }
        books {
          nextToken
        }
        publicJournals {
          nextToken
        }
        lovedComments {
          nextToken
        }
        comments {
          nextToken
        }
        notifications {
          nextToken
        }
      }
      lovedUsers {
        items {
          id
          userLovedJournalUserId
          userLovedJournalJournalId
          createdAt
          updatedAt
        }
        nextToken
      }
      book {
        id
        book
        bookUserId
        bookCoverImg
        bookJournalCount
        journals {
          nextToken
        }
        createdAt
        updatedAt
        user {
          id
          uuid
          displayName
          profileImage
          membership
          totalJournal
          followingCount
          followerCount
          bookOrder
          createdAt
          updatedAt
        }
        publicJournals {
          nextToken
        }
      }
      comments {
        items {
          id
          content
          commentLovedCount
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdatePublicJournal = /* GraphQL */ `
  subscription OnUpdatePublicJournal {
    onUpdatePublicJournal {
      id
      privacy
      content
      journalAuthorId
      markedAs
      lovedCount
      commentCount
      journalWordCount
      publicJournalBookId
      createdAt
      updatedAt
      author {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        journals {
          nextToken
        }
        chatRoomUser {
          nextToken
        }
        chatMassages {
          nextToken
        }
        bookOrder
        createdAt
        updatedAt
        lovedJournals {
          nextToken
        }
        books {
          nextToken
        }
        publicJournals {
          nextToken
        }
        lovedComments {
          nextToken
        }
        comments {
          nextToken
        }
        notifications {
          nextToken
        }
      }
      lovedUsers {
        items {
          id
          userLovedJournalUserId
          userLovedJournalJournalId
          createdAt
          updatedAt
        }
        nextToken
      }
      book {
        id
        book
        bookUserId
        bookCoverImg
        bookJournalCount
        journals {
          nextToken
        }
        createdAt
        updatedAt
        user {
          id
          uuid
          displayName
          profileImage
          membership
          totalJournal
          followingCount
          followerCount
          bookOrder
          createdAt
          updatedAt
        }
        publicJournals {
          nextToken
        }
      }
      comments {
        items {
          id
          content
          commentLovedCount
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeletePublicJournal = /* GraphQL */ `
  subscription OnDeletePublicJournal {
    onDeletePublicJournal {
      id
      privacy
      content
      journalAuthorId
      markedAs
      lovedCount
      commentCount
      journalWordCount
      publicJournalBookId
      createdAt
      updatedAt
      author {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        journals {
          nextToken
        }
        chatRoomUser {
          nextToken
        }
        chatMassages {
          nextToken
        }
        bookOrder
        createdAt
        updatedAt
        lovedJournals {
          nextToken
        }
        books {
          nextToken
        }
        publicJournals {
          nextToken
        }
        lovedComments {
          nextToken
        }
        comments {
          nextToken
        }
        notifications {
          nextToken
        }
      }
      lovedUsers {
        items {
          id
          userLovedJournalUserId
          userLovedJournalJournalId
          createdAt
          updatedAt
        }
        nextToken
      }
      book {
        id
        book
        bookUserId
        bookCoverImg
        bookJournalCount
        journals {
          nextToken
        }
        createdAt
        updatedAt
        user {
          id
          uuid
          displayName
          profileImage
          membership
          totalJournal
          followingCount
          followerCount
          bookOrder
          createdAt
          updatedAt
        }
        publicJournals {
          nextToken
        }
      }
      comments {
        items {
          id
          content
          commentLovedCount
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateChallenge = /* GraphQL */ `
  subscription OnCreateChallenge {
    onCreateChallenge {
      id
      yearMonth
      user
      userDisplayName
      userProfileImage
      journalsPerMonth
      journalWordCountPerMonth
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateChallenge = /* GraphQL */ `
  subscription OnUpdateChallenge {
    onUpdateChallenge {
      id
      yearMonth
      user
      userDisplayName
      userProfileImage
      journalsPerMonth
      journalWordCountPerMonth
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteChallenge = /* GraphQL */ `
  subscription OnDeleteChallenge {
    onDeleteChallenge {
      id
      yearMonth
      user
      userDisplayName
      userProfileImage
      journalsPerMonth
      journalWordCountPerMonth
      createdAt
      updatedAt
    }
  }
`;
export const onCreateUserLovedComment = /* GraphQL */ `
  subscription OnCreateUserLovedComment {
    onCreateUserLovedComment {
      id
      userLovedCommentUserId
      userLovedCommentCommentId
      createdAt
      updatedAt
      user {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        journals {
          nextToken
        }
        chatRoomUser {
          nextToken
        }
        chatMassages {
          nextToken
        }
        bookOrder
        createdAt
        updatedAt
        lovedJournals {
          nextToken
        }
        books {
          nextToken
        }
        publicJournals {
          nextToken
        }
        lovedComments {
          nextToken
        }
        comments {
          nextToken
        }
        notifications {
          nextToken
        }
      }
      comment {
        id
        content
        commentLovedCount
        createdAt
        updatedAt
        author {
          id
          uuid
          displayName
          profileImage
          membership
          totalJournal
          followingCount
          followerCount
          bookOrder
          createdAt
          updatedAt
        }
        journal {
          id
          privacy
          content
          journalAuthorId
          markedAs
          lovedCount
          commentCount
          journalWordCount
          publicJournalBookId
          createdAt
          updatedAt
        }
        lovedUsers {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateUserLovedComment = /* GraphQL */ `
  subscription OnUpdateUserLovedComment {
    onUpdateUserLovedComment {
      id
      userLovedCommentUserId
      userLovedCommentCommentId
      createdAt
      updatedAt
      user {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        journals {
          nextToken
        }
        chatRoomUser {
          nextToken
        }
        chatMassages {
          nextToken
        }
        bookOrder
        createdAt
        updatedAt
        lovedJournals {
          nextToken
        }
        books {
          nextToken
        }
        publicJournals {
          nextToken
        }
        lovedComments {
          nextToken
        }
        comments {
          nextToken
        }
        notifications {
          nextToken
        }
      }
      comment {
        id
        content
        commentLovedCount
        createdAt
        updatedAt
        author {
          id
          uuid
          displayName
          profileImage
          membership
          totalJournal
          followingCount
          followerCount
          bookOrder
          createdAt
          updatedAt
        }
        journal {
          id
          privacy
          content
          journalAuthorId
          markedAs
          lovedCount
          commentCount
          journalWordCount
          publicJournalBookId
          createdAt
          updatedAt
        }
        lovedUsers {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteUserLovedComment = /* GraphQL */ `
  subscription OnDeleteUserLovedComment {
    onDeleteUserLovedComment {
      id
      userLovedCommentUserId
      userLovedCommentCommentId
      createdAt
      updatedAt
      user {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        journals {
          nextToken
        }
        chatRoomUser {
          nextToken
        }
        chatMassages {
          nextToken
        }
        bookOrder
        createdAt
        updatedAt
        lovedJournals {
          nextToken
        }
        books {
          nextToken
        }
        publicJournals {
          nextToken
        }
        lovedComments {
          nextToken
        }
        comments {
          nextToken
        }
        notifications {
          nextToken
        }
      }
      comment {
        id
        content
        commentLovedCount
        createdAt
        updatedAt
        author {
          id
          uuid
          displayName
          profileImage
          membership
          totalJournal
          followingCount
          followerCount
          bookOrder
          createdAt
          updatedAt
        }
        journal {
          id
          privacy
          content
          journalAuthorId
          markedAs
          lovedCount
          commentCount
          journalWordCount
          publicJournalBookId
          createdAt
          updatedAt
        }
        lovedUsers {
          nextToken
        }
      }
    }
  }
`;
export const onCreateComment = /* GraphQL */ `
  subscription OnCreateComment {
    onCreateComment {
      id
      content
      commentLovedCount
      createdAt
      updatedAt
      author {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        journals {
          nextToken
        }
        chatRoomUser {
          nextToken
        }
        chatMassages {
          nextToken
        }
        bookOrder
        createdAt
        updatedAt
        lovedJournals {
          nextToken
        }
        books {
          nextToken
        }
        publicJournals {
          nextToken
        }
        lovedComments {
          nextToken
        }
        comments {
          nextToken
        }
        notifications {
          nextToken
        }
      }
      journal {
        id
        privacy
        content
        journalAuthorId
        markedAs
        lovedCount
        commentCount
        journalWordCount
        publicJournalBookId
        createdAt
        updatedAt
        author {
          id
          uuid
          displayName
          profileImage
          membership
          totalJournal
          followingCount
          followerCount
          bookOrder
          createdAt
          updatedAt
        }
        lovedUsers {
          nextToken
        }
        book {
          id
          book
          bookUserId
          bookCoverImg
          bookJournalCount
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
      }
      lovedUsers {
        items {
          id
          userLovedCommentUserId
          userLovedCommentCommentId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateComment = /* GraphQL */ `
  subscription OnUpdateComment {
    onUpdateComment {
      id
      content
      commentLovedCount
      createdAt
      updatedAt
      author {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        journals {
          nextToken
        }
        chatRoomUser {
          nextToken
        }
        chatMassages {
          nextToken
        }
        bookOrder
        createdAt
        updatedAt
        lovedJournals {
          nextToken
        }
        books {
          nextToken
        }
        publicJournals {
          nextToken
        }
        lovedComments {
          nextToken
        }
        comments {
          nextToken
        }
        notifications {
          nextToken
        }
      }
      journal {
        id
        privacy
        content
        journalAuthorId
        markedAs
        lovedCount
        commentCount
        journalWordCount
        publicJournalBookId
        createdAt
        updatedAt
        author {
          id
          uuid
          displayName
          profileImage
          membership
          totalJournal
          followingCount
          followerCount
          bookOrder
          createdAt
          updatedAt
        }
        lovedUsers {
          nextToken
        }
        book {
          id
          book
          bookUserId
          bookCoverImg
          bookJournalCount
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
      }
      lovedUsers {
        items {
          id
          userLovedCommentUserId
          userLovedCommentCommentId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteComment = /* GraphQL */ `
  subscription OnDeleteComment {
    onDeleteComment {
      id
      content
      commentLovedCount
      createdAt
      updatedAt
      author {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        journals {
          nextToken
        }
        chatRoomUser {
          nextToken
        }
        chatMassages {
          nextToken
        }
        bookOrder
        createdAt
        updatedAt
        lovedJournals {
          nextToken
        }
        books {
          nextToken
        }
        publicJournals {
          nextToken
        }
        lovedComments {
          nextToken
        }
        comments {
          nextToken
        }
        notifications {
          nextToken
        }
      }
      journal {
        id
        privacy
        content
        journalAuthorId
        markedAs
        lovedCount
        commentCount
        journalWordCount
        publicJournalBookId
        createdAt
        updatedAt
        author {
          id
          uuid
          displayName
          profileImage
          membership
          totalJournal
          followingCount
          followerCount
          bookOrder
          createdAt
          updatedAt
        }
        lovedUsers {
          nextToken
        }
        book {
          id
          book
          bookUserId
          bookCoverImg
          bookJournalCount
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
      }
      lovedUsers {
        items {
          id
          userLovedCommentUserId
          userLovedCommentCommentId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateNotification = /* GraphQL */ `
  subscription OnCreateNotification {
    onCreateNotification {
      id
      content
      category
      link
      read
      actionUser {
        userID
        displayName
        photoURL
      }
      createdAt
      updatedAt
      user {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        journals {
          nextToken
        }
        chatRoomUser {
          nextToken
        }
        chatMassages {
          nextToken
        }
        bookOrder
        createdAt
        updatedAt
        lovedJournals {
          nextToken
        }
        books {
          nextToken
        }
        publicJournals {
          nextToken
        }
        lovedComments {
          nextToken
        }
        comments {
          nextToken
        }
        notifications {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateNotification = /* GraphQL */ `
  subscription OnUpdateNotification {
    onUpdateNotification {
      id
      content
      category
      link
      read
      actionUser {
        userID
        displayName
        photoURL
      }
      createdAt
      updatedAt
      user {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        journals {
          nextToken
        }
        chatRoomUser {
          nextToken
        }
        chatMassages {
          nextToken
        }
        bookOrder
        createdAt
        updatedAt
        lovedJournals {
          nextToken
        }
        books {
          nextToken
        }
        publicJournals {
          nextToken
        }
        lovedComments {
          nextToken
        }
        comments {
          nextToken
        }
        notifications {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteNotification = /* GraphQL */ `
  subscription OnDeleteNotification {
    onDeleteNotification {
      id
      content
      category
      link
      read
      actionUser {
        userID
        displayName
        photoURL
      }
      createdAt
      updatedAt
      user {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        journals {
          nextToken
        }
        chatRoomUser {
          nextToken
        }
        chatMassages {
          nextToken
        }
        bookOrder
        createdAt
        updatedAt
        lovedJournals {
          nextToken
        }
        books {
          nextToken
        }
        publicJournals {
          nextToken
        }
        lovedComments {
          nextToken
        }
        comments {
          nextToken
        }
        notifications {
          nextToken
        }
      }
    }
  }
`;
