import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import QuillEditor from '../components/QuillEditor'
import UpgradePopup from '../components/UpgradePopup'
import {
  getJournal,
  updateJournal,
  adjustBookJournalsCount,
} from '../actions/journal'
import LoadingCard from '../components/LoadingCard'
import DateTimePicker from '../utils/DateTimePicker'
import isPaidUser from '../utils/isPaidUser'

class EditJournal extends Component {
  _isMounted = false

  constructor(props) {
    super(props)
    const { currentJournal } = props.journal
    this.state = {
      privacy: currentJournal ? currentJournal.privacy : 'PRIVATE',
      markedAs: currentJournal ? currentJournal.markedAs : null,
      journalUid: props.match.params.journal_uid,
      journalAuthorId:
        props.user.accountData.idToken.payload['cognito:username'],
      journalContent: currentJournal ? currentJournal.content : '',
      journalWordCount: currentJournal ? currentJournal.journalWordCount : 0,
      journalLovedCount: currentJournal ? currentJournal.lovedCount : 0,
      journalCommentCount: currentJournal ? currentJournal.commentCount : 0,
      journalWordCountBeforeEditing: currentJournal
        ? currentJournal.journalWordCount
        : 0,
      showUpgrade: false,
      journalBookId: currentJournal
        ? currentJournal.journalBookId || currentJournal.publicJournalBookId
        : '',
      bookChangedFromId: currentJournal
        ? currentJournal.journalBookId || currentJournal.publicJournalBookId
        : '',
      journalCreatedAt: currentJournal
        ? new Date(currentJournal.createdAt)
        : '',
      upgradePopUpMessage: {
        featureName: 'Publishing a public journal',
        ctaText: 'Publish it as a private journal',
      },
      isSavingJournal: false,
    }
  }

  async componentDidMount() {
    this._isMounted = true
    // TODO: check logged-in userID and journal author ID are match
    // if not this.props.history.goBack()

    // set focus to the bottom of the screen
    window.scrollBy(0, window.innerHeight + 100)

    const { journalUid } = this.state
    const { currentJournal } = this.props.journal
    if (currentJournal) return
    try {
      const journlReturned = await this.props.getJournal(journalUid)
      if (!journlReturned) {
        this.props.history.goBack()
      }
      if (!this._isMounted) return
      this.setState({
        privacy: journlReturned ? journlReturned.privacy : 'PRIVATE',
        markedAs: journlReturned ? journlReturned.markedAs : '',
        journalContent: journlReturned ? journlReturned.content : '',
        journalLovedCount: journlReturned ? journlReturned.lovedCount : 0,
        journalCommentCount: journlReturned ? journlReturned.commentCount : 0,
        journalWordCount: journlReturned ? journlReturned.journalWordCount : 0,
        journalWordCountBeforeEditing: journlReturned
          ? journlReturned.journalWordCount
          : 0,
        journalBookId: journlReturned
          ? journlReturned.journalBookId || journlReturned.publicJournalBookId
          : '',
        bookChangedFromId: journlReturned
          ? journlReturned.journalBookId || journlReturned.publicJournalBookId
          : '',
        journalCreatedAt: journlReturned
          ? new Date(journlReturned.createdAt)
          : '',
      })
    } catch (err) {
      console.log(err)
    }
  }

  _selectPrivacy = async (e) => {
    this.setState({
      privacy: e.target.value,
    })
  }

  _popupControl = async (callback) => {
    this.setState({
      showUpgrade: callback,
    })
  }

  _handleChange = async (textPassed, wordCount) => {
    const { currentJournal } = this.props.journal
    if (currentJournal !== textPassed) {
      this.setState({
        journalContent: textPassed,
        journalWordCount: wordCount,
      })

      const {
        privacy,
        journalUid,
        journalContent,
        journalAuthorId,
        journalWordCount,
        journalBookId,
      } = this.state
      const { user } = this.props
      let journalPrivacy = privacy
      if (!isPaidUser(user.currentUser.attributes['custom:membership'])) {
        journalPrivacy = 'PRIVATE'
      }

      if (journalContent.length > 4) {
        // if non-paid user, disable autosave and shows the warning message for the first time only
        // TODO: remove auto save warning message on next major update.
        if (!isPaidUser(user.currentUser.attributes['custom:membership'])) {
          if (localStorage.getItem('auto-save-for-paid-users') === 'true') {
            return
          } else {
            this.setState({
              showUpgrade: true,
              upgradePopUpMessage: {
                featureName: 'Autosave',
                ctaText: 'Close',
              },
            })
            localStorage.setItem('auto-save-for-paid-users', 'true')
            return
          }
        }
        try {
          await this.props.updateJournal({
            id: journalUid,
            journalAuthorId: journalAuthorId,
            privacy: journalPrivacy,
            draft: true,
            content: journalContent,
            journalWordCount: journalWordCount,
            journalBookId: journalBookId,
          })
        } catch (err) {
          console.log(err)
        }
      }
    }
  }

  _publishJournal = async () => {
    const { privacy } = this.state
    const { user } = this.props
    if (
      privacy === 'PUBLIC' &&
      !isPaidUser(user.currentUser.attributes['custom:membership'])
    ) {
      this.setState({
        showUpgrade: true,
        upgradePopUpMessage: {
          featureName: 'Publishing a public journal',
          ctaText: 'Publish it as a private journal',
        },
      })
    } else {
      this._updateJournal(privacy)
    }
  }

  _updateJournal = async (privacy) => {
    const {
      journalUid,
      journalAuthorId,
      markedAs,
      journalContent,
      journalLovedCount,
      journalCommentCount,
      journalWordCount,
      journalWordCountBeforeEditing,
      journalBookId,
      bookChangedFromId,
      journalCreatedAt,
    } = this.state
    this.setState({ isSavingJournal: true })
    if (journalUid) {
      try {
        await this.props.updateJournal({
          id: journalUid,
          privacy: privacy,
          journalAuthorId: journalAuthorId,
          draft: false,
          content: journalContent,
          markedAs: markedAs,
          lovedCount: journalLovedCount,
          commentCount: journalCommentCount,
          journalWordCount: journalWordCount,
          journalWordCountBeforeEditing: journalWordCountBeforeEditing,
          journalBookId: journalBookId,
          createdAt: journalCreatedAt.toISOString(),
        })
        // if user changes book from one to another, subtract total number of journals in the original book
        // or no book to book
        if (journalBookId !== bookChangedFromId) {
          bookChangedFromId &&
            (await this.props.adjustBookJournalsCount(bookChangedFromId, -1))
          journalBookId &&
            (await this.props.adjustBookJournalsCount(journalBookId, 1))
        }

        this.props.history.push({
          pathname:
            (privacy === 'PUBLIC' ? '/public-journal/' : '/journal/') +
            journalUid,
          state: { prevPath: '/edit' },
        })
      } catch (err) {
        console.log(err)
        this.setState({ isSavingJournal: false })
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  _selectBook = async (e) => {
    this.setState({
      journalBookId: e.target.value,
    })
  }

  _newJournalCreatedAt = (date) => {
    const { journalCreatedAt } = this.state
    const { user } = this.props
    if (!isPaidUser(user.currentUser.attributes['custom:membership'])) {
      this.setState({
        showUpgrade: true,
        journalCreatedAt: journalCreatedAt,
        upgradePopUpMessage: {
          featureName: 'Changing date and time',
          ctaText: 'Publish it with existing date and time',
        },
      })
    } else {
      this.setState({
        journalCreatedAt: date,
      })
    }
  }

  render() {
    const {
      privacy,
      showUpgrade,
      journalBookId,
      upgradePopUpMessage,
      journalCreatedAt,
      isSavingJournal,
    } = this.state
    const { currentJournal } = this.props.journal
    const { journal, user } = this.props

    const editJoural = !currentJournal ? (
      <LoadingCard />
    ) : (
      <QuillEditor
        readOnly={false}
        value={currentJournal.content}
        textCallback={this._handleChange}
      />
    )

    const bookList =
      user && user.userBooks.length > 0 ? (
        <select
          className="select-new book-select"
          onChange={this._selectBook}
          defaultValue={journalBookId ? journalBookId : ''}
          id="editJournal_book_select"
        >
          <option value="">No book</option>
          {user.userBooks.map((book) => {
            return (
              <option key={book.id} value={book.id}>
                {book.book}
              </option>
            )
          })}
        </select>
      ) : (
        <select className="select-new book-select">
          <option value="">No book</option>
        </select>
      )

    return (
      <div className="write-journal">
        <ul className="sub-nav">
          <li>
            <button
              onClick={() => this.props.history.goBack()}
              className="link-btn"
              id="editJournal_cancel_edit"
            >
              <ion-icon name="close"></ion-icon> Cancel
            </button>
          </li>
          <li>{bookList}</li>
          <li>
            {currentJournal ? (
              <button
                onClick={this._publishJournal}
                id="editJournal_update_button"
                className="link-btn"
                disabled={
                  journal.pendingCreate ||
                  journal.pendingUpdate ||
                  isSavingJournal
                    ? true
                    : false
                }
              >
                {journal.pendingCreate ||
                journal.pendingUpdate ||
                isSavingJournal
                  ? 'Updating...'
                  : 'Update'}
              </button>
            ) : (
              ''
            )}
          </li>
        </ul>
        <div className="journal-option">
          <DateTimePicker
            setDate={journalCreatedAt}
            newDate={this._newJournalCreatedAt}
          />
          {currentJournal ? (
            <select
              className="select-new"
              onChange={this._selectPrivacy}
              value={privacy}
              id="editJournal_privacy_select"
            >
              <option
                value="PRIVATE"
                defaultValue={privacy === 'PRIVATE' ? 'selected' : ''}
              >
                Private
              </option>
              <option
                value="PUBLIC"
                defaultValue={privacy === 'PUBLIC' ? 'selected' : ''}
              >
                Public
              </option>
            </select>
          ) : (
            ''
          )}
        </div>
        <div className="Container write-editor">{editJoural}</div>
        <UpgradePopup
          featureName={upgradePopUpMessage.featureName}
          ctaText={upgradePopUpMessage.ctaText}
          showUpgrade={showUpgrade}
          popupControl={this._popupControl}
          publishPrivate={this._updateJournal}
        />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return state
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateJournal,
      getJournal,
      adjustBookJournalsCount,
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(EditJournal)
