import React, { Component } from 'react';
import loadingIcon from '../assets/images/loading-dark.svg'

class CommentWrite extends Component{
  _commentSubmit = (e) => {
    e.preventDefault();
    const { commentCallback, commentValue, onChangeCommend } = this.props
    
    if(commentValue.length > 1){
      commentCallback(commentValue)
      onChangeCommend({target: {value: commentValue}})
    }else{
      console.log("Comment needs to be more than one character")
    }
  }

  render() {
    const { showLoadingIcon, commentValue, onChangeCommend } = this.props
    return(
      <div className="journal-comment-write" id="comment_write_form">
        <h2>Write a comment</h2>
        <form onSubmit={this._commentSubmit}>
          <textarea 
            placeholder="Write something here..."
            value={commentValue}
            onChange={onChangeCommend}>
          </textarea>
          <button className="btn-secondary" disabled={showLoadingIcon}>{showLoadingIcon ? <img alt="Loading..." className="loading-icon" src={loadingIcon} /> : "Submit"}</button>
        </form>
      </div>
    )
  }
}

export default CommentWrite
