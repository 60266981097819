import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import NotificationListItem from "../components/NotificationListItem";
import { readNotification, getMoreNotifications } from "../actions/notification";
import LoadingCard from '../components/LoadingCard';

import loadingIcon from '../assets/images/loading-dark.svg'
import noNotifications from '../assets/images/notification-loved.svg'

class Notification extends Component {
  constructor(props){
    super(props)
    this.state = {
      username: props.user.accountData.idToken.payload["cognito:username"],
      showLoadingIcon: false,
    }
    this._loadMore = this._loadMore.bind(this)
    this._renderNotificationList = this._renderNotificationList.bind(this)
  }

  async _loadMore() {
    const { notification_nextToken } = this.props.user;
    const { username } = this.state
    this.setState({ showLoadingIcon: true })
    try {
      await this.props.getMoreNotifications(username, notification_nextToken)
      this.setState({ showLoadingIcon: false })
    } catch (err) {
      console.log(err)
      this.setState({ showLoadingIcon: false })
    }
  }

  _renderNotificationList() {
    const { notifications, notification_nextToken } = this.props.user;
    return (
      <React.Fragment>
        {notifications.map(notification => (
            <NotificationListItem
              key={notification.id}
              notification={notification}
              readNotification={this.props.readNotification}
              history={this.props.history}
            />
          ))}
        {notifications[notifications.length - 1] && notification_nextToken ? 
          <div className="load-more">
            {this.state.showLoadingIcon
              ? <img alt="Loading journals" className="loading-icon" src={loadingIcon} />
              : <button className="btn-secondary" onClick={this._loadMore} id="notification_load_more">Load more</button>}
          </div> 
          : ''
        }
      </React.Fragment>
    )
  }

  render() {
    const { notifications, isFetchUserData } = this.props.user;

    return (
      <div className="notification-page Container">
        <div className="Container--side-padding">
          <h1>Notification</h1>
        </div>
        {isFetchUserData && <LoadingCard />}
        {!isFetchUserData && notifications.length === 0 && 
          <div className="Container Container--sm sm-form">
            <img src={noNotifications} alt="No notifications" />
            <h2>No notifications yet</h2>
          </div>
        }
        {!isFetchUserData && notifications.length > 0 &&
          this._renderNotificationList()
        }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ readNotification, getMoreNotifications }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Notification));
