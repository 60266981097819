import React, { forwardRef } from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { useHistory } from 'react-router-dom'

export function Book(props) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props.id,
    disabled: props.disabled,
  })

  const style = {
    opacity: isDragging ? 0.4 : 1,
    userSelect: isDragging ? 'none' : 'inherit',
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <BookGrid
      ref={setNodeRef}
      style={style}
      {...props}
      {...attributes}
      {...listeners}
    />
  )
}

export const BookGrid = forwardRef((props, ref) => {
  const { bookCoverImg, id, book, bookJournalCount } = props.book
  const history = useHistory()

  return (
    <div
      ref={ref}
      className={bookCoverImg ? 'selected' : 'default-book'}
      {...props}
      style={{
        ...props.style,
        backgroundImage: `url(${bookCoverImg})`,
      }}
      onClick={() => history.push('/book/' + id)}
      id={'book_clicked_' + id}
    >
      <span>
        {book} ({bookJournalCount ? bookJournalCount : 0})
      </span>
    </div>
  )
})
