import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import profilePic from '../assets/images/profile-pic.svg'
import { getUserFollowers, getAuthorData } from '../actions/user'
import loadingIcon from '../assets/images/loading-dark.svg'
import LoadingCard from '../components/LoadingCard';

import moment from 'moment';

class Follower extends Component {
  _isMounted = false
  constructor(props) {
    super(props);
    this.state = {
      loadingFollowerData: false,
      loadingMore: false,
      followers: [],
      followersCount: 0,
      nextFollowerToken: null,
      authorData: null
    };
  }

  async componentDidMount() {
    this._isMounted = true
    const { author_uid } = this.props.match.params
    const decodedAuthor_uid = decodeURIComponent(author_uid);

    this.setState({ loadingFollowerData: true })
    try {
      let authorData = await this.props.getAuthorData(decodedAuthor_uid);
      let followersObj = await this.props.getUserFollowers(decodedAuthor_uid);
      if(!this._isMounted) return
      this.setState({
        authorData: authorData,
        followers: followersObj.items,
        nextFollowerToken: followersObj.nextToken,
        loadingFollowerData: false
      });
    } catch (err) {
      console.log(err);
      this.setState({ loadingFollowerData: false })
    }
  }

  getFollowerData = (followers) => {
    let divs = []
    for (let i = 0; i < followers.length; i++) {
      let follower = JSON.parse(followers[i].followerProfile);
      divs.push(
        <div key={follower.id}>
          <Link to={`/author/` + encodeURIComponent(follower.id)}>
            <div className="author">
              <img
                src={follower.profileImage || profilePic}
                alt="Profile pic"
                className="profile-pic"
              />{" "}
              <b>{follower.displayName || follower.id}</b>
              <br />
              {moment(followers[i].createdAt).fromNow()}
            </div>
          </Link>
        </div>
      );
    }
    return divs;
  }

  _loadMore = async () => {
    const { followers } = this.state
    const { author_uid } = this.props.match.params
    const decodedAuthor_uid = decodeURIComponent(author_uid);
    if(!this.state.nextFollowerToken) return
    this.setState({ loadingMore: true })
    try {
      let followersObj = await this.props.getUserFollowers(decodedAuthor_uid, this.state.nextFollowerToken);
      this.setState({
        followers: [...followers, ...followersObj.items],
        nextFollowerToken: followersObj.nextToken
      });
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({
        loadingMore: false,
      })
    }

  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { followers, authorData, loadingFollowerData, nextFollowerToken, loadingMore } = this.state;

    const followerContent = !loadingFollowerData && authorData ?
      <React.Fragment>
        <h1>Followed by {authorData.followerCount || 0} user{authorData.followerCount > 1 ? 's' : ''} </h1>
        <div className="user-stats follow-list">
          {this.getFollowerData(followers)}
          {nextFollowerToken && (
            loadingMore 
            ? <div className="load-more"><img alt="Loading followers" className="loading-icon" src={loadingIcon} /></div>
            : <div className="load-more"><button className="btn-secondary" onClick={() => this._loadMore()}>Load More</button></div>
          )}
        </div>
      </React.Fragment>
    :
      <LoadingCard />
    
    return (
      <div className="follower-page">
        <ul className="sub-nav sub-nav-back">
          <li>
            <span className="link-text trackit" onClick={() => this.props.history.goBack()} id="follower_subNav_go_back">
              <ion-icon name="arrow-round-back"></ion-icon> Back
            </span>
          </li>
          <li>
          </li>
          <li>
          </li>
        </ul>
        <div className="Container Container--md Container--side-padding">
          {followerContent}
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return state
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getUserFollowers,
    getAuthorData
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Follower)
