const initialState = {
  lovedUsers: [],
  loverLoading: false,
  nextToken: null,
}

function lover(state = initialState, action) {
  switch (action.type) {
    case "SET_LOVERLOADING_STATUS":
      return {
        ...state,
        loverLoading: action.loverLoading,
      };
    case "SET_LOVED_USERS":
      return {
        ...state,
        lovedUsers: action.lovedUsers.items,
        nextToken: action.lovedUsers.nextToken
      };
    case "ADD_LOVED_USERS":
      return {
        ...state,
        lovedUsers: [...state.lovedUsers, ...action.lovedUsers.items],
        nextToken: action.lovedUsers.nextToken
      };
    default:
      return state;
  }
}

export default lover;
