import React, { Component } from "react";
import { Link } from "react-router-dom";
import QuillEditor from "./QuillEditor";

import moment from "moment";

import ProfilePic from "../utils/ProfilePic";

class JournalEntryContent extends Component {

  render() {
    const { journalEntry } = this.props;

    if (!journalEntry) return null

    return (
      <div className="journal-readonly animate-fade-in-up">
        <div>
          <div className="author">
            <Link
              to={"/author/" + encodeURIComponent(journalEntry.author.id)}
              id={"journalEntry_author_id_" + journalEntry.author.id}
            >
              <img
                src={ProfilePic(journalEntry.author)}
                className="profile-pic"
                alt="User profile pic"
              />
            </Link>
            <time>
              <span style={{ textTransform: "initial" }}>
                <Link
                  to={"/author/" + encodeURIComponent(journalEntry.author.id)}
                  id={"journalEntry_author_id_" + journalEntry.author.id}
                >
                  <b>
                    {journalEntry.author.displayName || journalEntry.author.id}
                  </b>
                </Link>
              </span>
              <br />
              {moment(journalEntry.createdAt).format("llll")}
            </time>
          </div>
          <p className="journal-entry-privacy">
            {journalEntry.book && 
              <Link
                to={"/book/" + journalEntry.book.id}
                id={"journalEntry_book_id_" + journalEntry.book.id}
                className="book-tag"
              >
                <span className="badge"><ion-icon name="book"></ion-icon> {journalEntry.book.book}</span>
              </Link>
            }{" "}
            {journalEntry.draft && <span className="badge badge--grey">Draft</span>}{" "}
            {journalEntry.privacy === "PRIVATE" && <span className="badge badge--grey">Private</span>}
          </p>
        </div>
        <QuillEditor readOnly={true} value={journalEntry.content} />
      </div>
    );
  }
}

export default JournalEntryContent;
