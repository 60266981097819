import React from 'react';
import moment from 'moment';

import profilePlaceholder from '../assets/images/profile-pic.svg'

function ChatRoomListItem(props) {

  const enterChatRoom = () => {
    props.history.push('/chat/' + props.chatRoom.id)

    if(!props.chatRoom.receiverHasRead && !currentUserSentLastMessage){
      props.userHasSeenTheLastMessage(props.chatRoom.id)
    }
  }

  const chatUsers = props.chatRoom.chatRoomUsers.items.map( user => {
    return user.user.id !== props.currentUser ? user.user : null
  }).filter(x => x)

  const chatUserNames = chatUsers.map( u => {
    return u.displayName || u.id
  }).join(", ")

  const currentUserSentLastMessage = props.chatRoom.messages.items[0] ? props.chatRoom.messages.items[0].messageUserId === props.currentUser : false
  const renderLastMessage = props.chatRoom.messages.items[0] ? props.chatRoom.messages.items[0].content : ""
  const renderLastMessageTime = props.chatRoom.messages.items[0] ? moment(props.chatRoom.messages.items[0].createdAt).fromNow() : ""

  return(
    <div className="chat-list-item" onClick={enterChatRoom}>
      <div className="author">
        <img src={chatUsers.length > 0 ? (chatUsers[0].profileImage ? chatUsers[0].profileImage : profilePlaceholder) : profilePlaceholder} className="profile-pic" alt="Chat profile icon" />
      </div>
      <div>
        <code><b>{chatUsers.length > 0 ? (chatUserNames.length > 24 ? chatUserNames.slice(0, 24) + "..." : chatUserNames) : "User left a room"}</b></code> {!props.chatRoom.receiverHasRead && !currentUserSentLastMessage ? <span className="badge">New</span> : ""}<br />
        <span style={!props.chatRoom.receiverHasRead && !currentUserSentLastMessage ? { fontWeight: "bold" } : {}}>{renderLastMessage.length > 24 ? renderLastMessage.slice(0, 24) + "..." : renderLastMessage} &bull; <time>{renderLastMessageTime}</time></span>
      </div>
    </div>
  )
}

export default ChatRoomListItem
