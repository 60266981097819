const initialUserData = {
  isFetchingCurrentUserSession: false,
  isAuthenticated: null,
  isFetchUserData: false,
  isLoginSuccess: false,
  loginError: null,
  loginLoading: false,
  accountData: null,
  userData: null,
  currentUser: null,
  userJournal: [],
  notifications: [],
  userBooks: [],
  notification_nextToken: null,
  userBook_nextToken: null,
  iPhoneExtensions: ["HEIC", "Heic", "heic", "HEIF", "Heif", "heif"]
};

const user = (state = initialUserData, action) => {
  switch (action.type) {
    case "SET_CURRENT_USER_SESSION":
      return {
        ...state,
        isFetchingCurrentUserSession: action.isFetchingCurrentUserSession,
        isAuthenticated: action.isAuthenticated
      };
    case "SET_LOGIN_SUCCESS":
      return {
        ...state,
        isLoginSuccess: action.isLoginSuccess,
        accountData: action.accountData
      };
    case "SET_LOGIN_STATUS":
      return {
        ...state,
        loginError: action.loginError,
        loginLoading: action.loginLoading
      };
    case "RESET_LOAGIN_ERROR":
      return {
        ...state,
        loginError: null
      };
    case "GET_USER_DATA":
      return {
        ...state,
        userData: action.userData,
        userJournal: [action.userData.journals],
        notifications: action.userData.notifications.items,
        userBooks: action.userData.books.items,
        notification_nextToken: action.userData.notifications.nextToken,
        userBook_nextToken: action.userData.books.nextToken
      };
    case "CREATE_USER_BOOK":
      return {
        ...state,
        userBooks: [action.userBooks, ...state.userBooks]
      };
    case "ADD_USER_BOOK_DATA":
      return {
        ...state,
        userBooks: [...state.userBooks, ...action.newBookData.items],
        userBook_nextToken: action.newBookData.nextToken
      };
    case "REMOVE_A_BOOK":
      return {
        ...state,
        userBooks: state.userBooks.filter(book => {
          return book.id !== action.deletedBook.id;
        })
      };
    case "UPDATE_A_BOOK":
      return {
        ...state,
        userBooks: state.userBooks.map(book => {
          const item = action.updatedBook.find(({ id }) => id === book.id);
          return item ? item : book;
        })
      };
    case "GET_MORE_USER_SEARCH_DATA":
      return {
        ...state,
        userJournal: [...state.userJournal, action.userData.journals]
      };
    case 'SET_FETCH_USER_DATA_SESSION':
      return Object.assign({}, state, {
        isFetchUserData: action.isFetchUserData
      });
    case "GET_CURRENT_USER":
      return {
        ...state,
        currentUser: action.currentUser
      };
    case "ADD_USER_JOURNAL_DATA":
      return {
        ...state,
        userJournal: [...state.userJournal, action.moreUserJournals]
      };
    case "CREATE_NOTIFICATION_SUBSCRIPTION":
      return {
        ...state,
        notifications: [action.notification, ...state.notifications]
      };
    case "ADD_NOTIFICATION_DATA":
      return {
        ...state,
        notifications: [
          ...state.notifications,
          ...action.newNotificationData.items
        ],
        notification_nextToken: action.newNotificationData.nextToken
      };
    case "UPDATE_NOTIFICATION":
      for (let i = 0; i < state.notifications.length; i++) {
        if (state.notifications[i].id === action.notifId) {
          state.notifications[i].read = true;
        }
      }
      return {
        ...state,
        notifications: [...state.notifications]
      };
    case "ADJUST_TOTAL_JOURNAL":
      state.userData.totalJournal = action.total
      return {
        ...state
      };
    case "USER_LOGOUT":
      return {
        userJournal: [],
        currentUser: null,
        userData: null,
        accountData: null,
        notifications: [],
        userBooks: [],
        isFetchingCurrentUserSession: false
      };
    default:
      return state;
  }
};

export default user;
