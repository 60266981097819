const initialState = {
  journalLoading: false,
  pendingCreate: false,
  pendingDelete: false,
  pendingUpdate: false,
  currentJournal: null,
  myJournalLimit: 60,
  communityLimit: 30,
  challengeLimit: 30
}

function journal(state = initialState, action) {
  switch (action.type) {
    case "JOURNAL_LOADING_STATUS":
      return {
        ...state,
        journalLoading: action.loading,
      };
    case "JOURNAL_TEMP_SET":
      return {
        ...state,
        currentJournal: action.journal,
      };
    case "JOURNAL_AVAILABLE":
      return {
        ...state,
        currentJournal: action.currentJournal,
        journalLoading: false,
      };
    case "REQUEST_CREATE_JOURNAL":
      return {
        ...state,
        pendingCreate: true,
      };
    case "REQUEST_CREATE_JOURNAL_SUCCESS":
      return {
        ...state,
        currentJournal: action.journalCreated,
        pendingCreate: false
      };
    case "REQUEST_CREATE_JOURNAL_FAILED":
      return {
        ...state,
        pendingCreate: false,
      };    
    case "REQUEST_UPDATE_JOURNAL":
      return {
        ...state,
        pendingUpdate: true,
      };
    case "REQUEST_UPDATE_JOURNAL_SUCCESS":
      return {
        ...state,
        currentJournal: action.journalUpdated,
        pendingUpdate: false
      };
    case "REQUEST_UPDATE_JOURNAL_FAILED":
      return {
        ...state,
        pendingUpdate: false,
      };    
    case "REQUEST_DELETE_JOURNAL":
      return {
        ...state,
        pendingDelete: true,
      };    
    case "REQUEST_DELETE_JOURNAL_SUCCESS":
      return {
        ...state,
        pendingDelete: false
      }
    case "REQUEST_DELETE_JOURNAL_FAILED":
      return {
        ...state,
        pendingDelete: false,
      };
    case "SET_MY_JOURNAL_LIMIT":
      return {
        ...state,
        myJournalLimit: action.limit
      }
    case "SET_COMMUNITY_LIMIT":
      return {
        ...state,
        communityLimit: action.limit
      }
    case "SET_CHALLENGE_LIMIT":
      return {
        ...state,
        challengeLimit: action.limit
      }
    default:
      return state;
  }
}

export default journal;
