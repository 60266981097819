import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import profilePic from '../assets/images/profile-pic.svg'
import { getUserFollowings, getAuthorData } from '../actions/user'
import loadingIcon from '../assets/images/loading-dark.svg'
import LoadingCard from '../components/LoadingCard';
import moment from 'moment';

class Following extends Component {
  _isMounted = false
  constructor(props) {
    super(props);
    this.state = {
      loadingFollowingData: false,
      loadingMore: false,
      followings: [],
      followingCount: 0,
      nextFollowingToken: null,
      authorData: null
    };
  }

  async componentDidMount() {
    this._isMounted = true
    const { author_uid } = this.props.match.params
    const decodedAuthor_uid = decodeURIComponent(author_uid);
    this.setState({ loadingFollowingData: true })
    try {
      let authorData = await this.props.getAuthorData(decodedAuthor_uid);
      let followingsObj = await this.props.getUserFollowings(decodedAuthor_uid);
      if (!this._isMounted) return
      this.setState({
        authorData: authorData,
        followings: followingsObj.items,
        nextFollowingToken: followingsObj.nextToken,
        loadingFollowingData: false
      })
    } catch (err) {
      console.log(err);
      this.setState({ loadingFollowingData: false })
    }
  }

  getFollowingData = (followings) => {
    let divs = []
    for (let i = 0; i < followings.length; i++) {
      let following = JSON.parse(followings[i].followingProfile);
      divs.push(
        <div key={following.id}>
          <Link to={`/author/` + encodeURIComponent(following.id)}>
            <div className="author">
              <img
                src={following.profileImage || profilePic}
                alt="Profile pic"
                className="profile-pic"
              />{" "}
              <b>{following.displayName || following.id}</b>
              <br />
              {moment(followings[i].createdAt).fromNow()}
            </div>
          </Link>
        </div>
      );
    }
    return divs;
  }

  _loadMore = async () => {
    const { followings } = this.state
    const { author_uid } = this.props.match.params
    const decodedAuthor_uid = decodeURIComponent(author_uid);

    if(!this.state.nextFollowingToken) return
    this.setState({ loadingMore: true })
    try {
      let followingsObj = await this.props.getUserFollowings(decodedAuthor_uid, this.state.nextFollowingToken);
      this.setState({
        followings: [...followings, ...followingsObj.items],
        nextFollowingToken: followingsObj.nextToken
      });
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({
        loadingMore: false,
      })
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { followings, authorData, loadingFollowingData, nextFollowingToken, loadingMore } = this.state;

    const followingContent = !loadingFollowingData && authorData ?
      <React.Fragment>
        <h1>Following {authorData.followingCount || 0} user{authorData.followingCount > 1 ? 's' : ''} </h1>
        <div className="user-stats follow-list">
          {this.getFollowingData(followings)}
          {nextFollowingToken && (
            loadingMore 
            ? <div className="load-more"><img alt="Loading followings" className="loading-icon" src={loadingIcon} /></div>
            : <div className="load-more"><button className="btn-secondary" onClick={() => this._loadMore()}>Load More</button></div>
          )}
        </div>
      </React.Fragment>
    :
      <LoadingCard />
    
    return (
      <div className="following-page">
        <ul className="sub-nav sub-nav-back">
          <li>
            <span className="link-text trackit" onClick={() => this.props.history.goBack()} id="following_subNav_go_back">
              <ion-icon name="arrow-round-back"></ion-icon> Back
            </span>
          </li>
          <li>
          </li>
          <li>
          </li>
        </ul>
        <div className="Container Container--md Container--side-padding">
          {authorData && (authorData.followingCount === 0 || authorData.followingCount ? 
          followingContent
          :
            <React.Fragment>
              {followingContent}
              <div className="Container Container--sm sm-form no-chat-found">
                <h1><span role="img" aria-label="Smiling">🥰</span><br />Meet amazing people on Goodnight Journal</h1>
                <p><Link to="/community">Community</Link> and <Link to="/challenge">Callenge</Link> are great places to meet them!</p>
              </div>  
            </React.Fragment>
          )}
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return state
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getUserFollowings,
    getAuthorData
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Following)
