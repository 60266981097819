import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getJournal, deleteJournal } from "../actions/journal";
import { getUserData } from "../actions/user";
import LoadingCard from "../components/LoadingCard";
import JournalEntryContent from "../components/JournalEntryContent";
import NoMatch from "../components/NoMatch";

class JournalEntry extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      username: props.user.accountData.idToken.payload["cognito:username"],
      stateUpdating: false,
      attemptJournal: false
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    const { journal_uid } = this.props.match.params;
    const userJorunal = await this.props.getJournal(journal_uid)
    if(!userJorunal){
      this.setState({ attemptJournal: true })
    }
  }

  _deleteJournal = async () => {
    const { journal_uid } = this.props.match.params;
    const ask = window.confirm(
      "Do you want to permanently delete this journal?"
    );
    if (ask) {
      try {
        const deletedJournal = await this.props.deleteJournal(journal_uid);
        await this.props.getUserData(this.props.user.userData.id);
        if (deletedJournal.id === journal_uid) {
          this.props.history.push("/");
        } else {
          alert(
            "Error deleting the journal. Please reload the page and try again."
          );
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  _goBackDestination = async () => {
    const { location } = this.props;

    location.state &&
    (location.state.prevPath === "/write" ||
      location.state.prevPath === "/edit")
      ? this.props.history.push("/")
      : this.props.history.goBack();
  };

  _renderSubNavUI = () => {
    const { journal, user } = this.props;
    const { journal_uid } = this.props.match.params;
    if (
      journal.currentJournal &&
      journal.currentJournal.author.id ===
        user.accountData.idToken.payload["cognito:username"]
    ) {
      return (
        <ul className="sub-nav">
          <li>
            <span className="link-text trackit" onClick={this._goBackDestination} id="journalEntry_subNav_go_back">
              <ion-icon name="arrow-round-back"></ion-icon> Back
            </span>
          </li>
          <li
            className="link-text trackit"
            id="journalEntry_subNav_edit"
            onClick={() => this.props.history.push("/edit/" + journal_uid)}
          >
            <ion-icon name="create"></ion-icon>
          </li>
          <li className="link-text trackit" onClick={this._deleteJournal} id="journalEntry_subNav_delete">
            <ion-icon name="trash"></ion-icon>
          </li>
        </ul>
      );
    }
    return (
      <ul className="sub-nav">
        <li>
          <span className="link-text trackit" onClick={this._goBackDestination} id="journalEntry_subNav_go_back">
            <ion-icon name="arrow-round-back"></ion-icon> Back
          </span>
        </li>
      </ul>
    );
  };

  componentWillUnmount() {
    this._isMounted = false;
  }
  
  render() {
    const { stateUpdating, attemptJournal } = this.state;
    const { journal, user } = this.props;
    
    if (!journal.currentJournal && attemptJournal) {
      return <NoMatch />;
    }

    const loadJournalEntry = journal.journalLoading ? (
      <LoadingCard />
    ) : (
      <JournalEntryContent
        journalEntry={journal.currentJournal}
        userUid={user.accountData.idToken.payload["cognito:username"]}
        stateUpdating={stateUpdating}
      />
    );

    return (
      <div className="journal-entry">
        {this._renderSubNavUI()}
        <div className="journal-entry Container Container--side-padding">
          {loadJournalEntry}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getJournal,
      deleteJournal,
      getUserData
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JournalEntry);
