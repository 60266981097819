import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  getJournalsData,
  getMoreUserJournal,
  getUserData,
  setMyJournalLimit,
} from '../actions/user'
import MyJournalList from '../components/MyJournalList'
import BooksMyJournal from './BooksMyJournal'
import moment from 'moment'
import loadingIcon from '../assets/images/loading-dark.svg'

class MyJournal extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      username: props.user.accountData.idToken.payload['cognito:username'],
      showSearch: false,
      showLoadingIcon: false,
      searchingJournals: false,
      searched: false,
      searchText: '',
    }

    this.showSearchUI = this.showSearchUI.bind(this)
    this.loadMore = this.loadMore.bind(this)
    this.searchJournals = this.searchJournals.bind(this)
  }

  async componentDidMount() {
    const { journal, user } = this.props
    const { username } = this.state
    try {
      if (user.isAuthenticated)
        await this.props.getUserData(username, journal.myJournalLimit)
    } catch (err) {
      console.log(err)
    }
  }

  showSearchUI() {
    this.setState({ showSearch: !this.state.showSearch })
  }

  async searchJournals(event) {
    const { journal } = this.props
    if (event.key === 'Enter' || event.target.value === '') {
      const { username } = this.state
      this.setState({
        searchingJournals: true,
        searchText: event.target.value,
      })
      if (event.target.value !== '') {
        try {
          await this.props.getJournalsData(username, event.target.value)
          this.setState({
            searchingJournals: false,
            searched: true,
          })
        } catch (err) {
          console.log(err)
          this.setState({ searchingJournals: false })
        }
      } else {
        try {
          await this.props.getUserData(username, journal.myJournalLimit)
          this.setState({
            searchingJournals: false,
            searched: false,
          })
        } catch (err) {
          console.log(err)
          this.setState({ searchingJournals: false })
        }
      }
    }
  }

  async loadMore(totalJournals) {
    const { username, showSearch, searchText } = this.state
    const { userJournal } = this.props.user
    this.setState({ showLoadingIcon: true })
    try {
      if (showSearch && searchText) {
        await this.props.getJournalsData(
          username,
          searchText,
          userJournal[userJournal.length - 1].nextToken
        )
      } else {
        await this.props.getMoreUserJournal(
          username,
          userJournal[userJournal.length - 1].nextToken
        )
        this.props.setMyJournalLimit(totalJournals + 60)
      }
      this.setState({ showLoadingIcon: false })
    } catch (err) {
      console.log(err)
      this.setState({ showLoadingIcon: false })
    }
  }

  render() {
    const { showSearch, showLoadingIcon, searchingJournals, searched } =
      this.state
    const { userJournal, userData, userBooks } = this.props.user

    // Re-organize user journals by month
    let newJournalList = {}

    for (let i = 0; i < userJournal.length; i++) {
      for (let j = 0; j < userJournal[i].items.length; j++) {
        let month = userJournal[i].items[j].month
        if (!newJournalList[month]) newJournalList[month] = []
        newJournalList[month] = [
          ...newJournalList[month],
          ...userJournal[i].items[j].data,
        ]
      }
    }

    const totalLoadedMonthNumber = Object.keys(newJournalList).length

    let totalJournals = 0
    Object.keys(newJournalList).map(function (key, index) {
      return (totalJournals = totalJournals + newJournalList[key].length)
    })

    return (
      <div className="my-journal">
        <div className="write-journal-cta">
          <div className="Container">
            <Link to="/write" id="myJournal_write">
              <time>{moment().format('llll')}</time>
              <div className="write-input">Write something today...</div>
            </Link>
            <span
              className="journal-prompt-btn"
              onClick={() =>
                (window.location =
                  'https://happy.goodnightjournal.com/journal-prompt')
              }
              role="img"
              aria-label="Thinking Face"
            >
              🤔
            </span>
          </div>
        </div>

        {userData ? (
          <BooksMyJournal userBooks={userBooks.slice(0, 6)} />
        ) : (
          <BooksMyJournal userBooks={{ items: 'loading' }} />
        )}

        <div className="Container">
          {Object.keys(newJournalList).length >= 1 ? (
            <div className="journal-list-title">
              {totalJournals} journal{totalJournals !== 1 && 's'} loaded from
              last {totalLoadedMonthNumber} month
              {totalLoadedMonthNumber === 1 ? '' : 's'}
              <ion-icon
                class="trackit"
                id="myJournal_search"
                name={showSearch ? 'close' : 'search'}
                onClick={this.showSearchUI}
              ></ion-icon>
            </div>
          ) : searched ? (
            <div className="journal-list-title">
              0 journal loaded
              <ion-icon
                class="trackit"
                id="myJournal_search"
                name={showSearch ? 'close' : 'search'}
                onClick={this.showSearchUI}
              ></ion-icon>
            </div>
          ) : (
            ''
          )}

          <div className="journal-list">
            <div className={`search-journal ${showSearch && 'show'}`}>
              <input
                type="text"
                placeholder="Search my journal"
                autoCapitalize="none"
                onKeyUp={(event) => this.searchJournals(event)}
              />
            </div>

            <MyJournalList
              journals={newJournalList}
              userData={userData}
              searched={searched}
              searchingJournals={searchingJournals}
            />

            {userJournal[userJournal.length - 1] &&
              userJournal[userJournal.length - 1].items.length > 0 &&
              userJournal[userJournal.length - 1].nextToken && (
                <div className="load-more">
                  {showLoadingIcon ? (
                    <img
                      alt="Loading journals"
                      className="loading-icon"
                      src={loadingIcon}
                    />
                  ) : (
                    <button
                      className="btn-secondary"
                      onClick={() => this.loadMore(totalJournals)}
                    >
                      Load more
                    </button>
                  )}
                </div>
              )}
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return state
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getJournalsData,
      getMoreUserJournal,
      getUserData,
      setMyJournalLimit,
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(MyJournal)
