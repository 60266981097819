import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class Footer extends Component {
  render() {
    const currentYear = new Date().getFullYear()
    return (
      <div className="footer">
        <p>
          <Link to="/contact" id="footer_contact">
            Contact
          </Link>
          &nbsp;
          <Link to="/feedback" id="footer_feedback">
            Feedback
          </Link>
          &nbsp;
          <a
            href="https://goodnightjournal.com/privacy"
            target="_blank"
            rel="noopener noreferrer"
            id="footer_privacy"
          >
            Privacy
          </a>
        </p>
        <p>&copy; Goodnight Journal by Allspectives LLC {currentYear}.</p>
      </div>
    )
  }
}

export default Footer
