import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import PublicJournalListItem from "../components/PublicJournalListItem";
import ProfilePic from "../utils/ProfilePic";
import heic2any from "heic2any";
import Masonry from 'masonry-layout';
import imageCompression from "browser-image-compression";
import LoadingCard from "../components/LoadingCard";
import {
  updateProfilePic,
  getAuthorPublicJournalData,
  getAuthorData
} from "../actions/user";
import moment from "moment";
import abbrNum from "../utils/AbbrNum"
import uploadIcon from "../assets/images/upload-img-icon.svg";
import loadingIcon from "../assets/images/loading-dark.svg";
import community from "../assets/images/community.svg";
import avocado from "../assets/images/avocado.svg";

class MyAccount extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      username: props.user.accountData.idToken.payload["cognito:username"],
      showProfilePicLoadingIcon: false,
      showLoadingIcon: false,
      publicJournals: {
        items: []
      },
      authorData: "",
      loadingAuthorData: false
    };
    this.convertHEICToAny = this.convertHEICToAny.bind(this);
    this.getCompressedImage = this.getCompressedImage.bind(this);
  }

  async componentDidMount() {
    const { username } = this.state;
    this._isMounted = true;
    this.setState({ loadingAuthorData: true });
    
    try {
      const authData = await this.props.getAuthorData(username)
      const journals = await this.props.getAuthorPublicJournalData(username);
      if (!this._isMounted) return
      this.setState({
        authorData: authData,
        publicJournals: journals,
        loadingAuthorData: false
      });
    } catch (err) {
      console.log(err);
      this.setState({ loadingAuthorData: false })
    }
  }

  invokeMasonry() {
    let elem = document.querySelector('.masonry')
    new Masonry( elem, {
      itemSelector: '.journal-card',
      percentPosition: true
    });
  }

  invokeMasonryAgain = () => {
    this.invokeMasonry() 
  }

  async convertHEICToAny(file) {
    try {
      console.log("HEIC image is being converted...");
      await heic2any({
        blob: file,
        toType: "image/jpeg",
        quality: 0.8
      }).then(result => (file = result));
    } catch (error) {
      console.warn(error);
    }
    if (/^image\//.test(file.type)) {
      try {
        return file;
      } catch (err) {
        console.log(err);
      }
    } else {
      console.warn("Could not convert image at the moment, please try later.");
    }
  }

  async getCompressedImage(imageFile) {
    var options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 2000,
      useWebWorker: false
    };
    try {
      console.log("Image is being compressed...");
      let compressedFile = "";
      await imageCompression(imageFile, options).then(
        result => (compressedFile = result)
      );
      compressedFile.name = "converted.jpeg";
      return compressedFile;
    } catch (error) {
      console.warn("Image could not be compressed: ", error);
      return imageFile;
    }
  }

  _selectLocalImage = async () => {
    let temp = document.getElementById("journal-list");
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.style.cursor = "pointer";
    input.style.opacity = 0;
    input.style.position = "absolute";
    input.style.width = "1px";
    input.style.height = "1px";
    input.style.accept="image/*";
    temp.appendChild(input);
    input.click();

    // Listen upload local image and save to server
    input.addEventListener("change", async () => {
      let file = input.files[0];
      let iPhoneExtensions = this.props.user.iPhoneExtensions;
      this.setState({ showLoadingIcon: true });

      // file type is only image.
      if (iPhoneExtensions.includes(file.name.split(".")[1])) {
        try {
          await this.convertHEICToAny(file).then(result => (file = result));
        } catch {
          console.log("Converter not working.");
        }
      }
      if (/^image\//.test(file.type)) {
        try {
          await this.getCompressedImage(file).then(result => (file = result));
          console.log("Image is being uploaded...");
          this.setState({ showProfilePicLoadingIcon: true });
          this.props
            .updateProfilePic(this.props.user.userData.id, file)
            .then(data => {
              if (data === "SUCCESS") {
                this.setState({ showProfilePicLoadingIcon: false });
              }
            });
        } catch (err) {
          console.log(err);
          this.setState({ showProfilePicLoadingIcon: false });
        }
      } else {
        console.warn("You could only upload images.");
        this.setState({ showLoadingIcon: false });
      }
    });
  };

  _loadMore = async () => {
    const { publicJournals, username } = this.state;

    this.setState({ showLoadingIcon: true });
    try {
      const data = await this.props.getAuthorPublicJournalData(
        username,
        publicJournals.nextToken
      );
      this.setState({
        publicJournals: {
          items: [...publicJournals.items, ...data.items],
          nextToken: data.nextToken
        },
        showLoadingIcon: false
      });
      this.invokeMasonry();
    } catch (err) {
      console.log(err);
      this.setState({ showLoadingIcon: false });
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const {
      showProfilePicLoadingIcon,
      showLoadingIcon,
      publicJournals,
      authorData,
      loadingAuthorData
    } = this.state;
    const { userData, currentUser } = this.props.user;

    if (loadingAuthorData) {
      return (
        <div className="Container Container--side-padding">
          <h1>My account</h1>
          <LoadingCard />
        </div>
      );
    }

    return (
      <div className="my-account">
        <div className="Container Container--side-padding">
          <h1>My account</h1>
          <div className="user-profile">
            <div className="user-profile-pic">
              <img
                src={ProfilePic(this.props.user)}
                className="profile-pic profile-pic--large"
                alt="user profile pic"
              />
              <div
                className={
                  showProfilePicLoadingIcon
                    ? "upload-image-icon show"
                    : "upload-image-icon"
                }
                onClick={() =>
                  showProfilePicLoadingIcon
                    ? alert("Image uploading...")
                    : this._selectLocalImage()
                }
              >
                {showProfilePicLoadingIcon ? (
                  <img
                    alt="Uploading profile"
                    className="loading-icon"
                    src={loadingIcon}
                  />
                ) : (
                  <img src={uploadIcon} alt="Upload user profile icon" />
                )}
              </div>
            </div>
            <div>
              <h3>
                {currentUser &&
                  (currentUser.attributes.preferred_username ||
                    currentUser.username)}{" "}

                {currentUser &&
                  (currentUser.attributes["custom:membership"] === "annual" || currentUser.attributes["custom:membership"] === "canceled" ? <span className="badge">Member</span> :<span className="badge badge--grey">Free</span>)}
                
              </h3>
              <time>
                Member since{" "}
                {userData && moment(userData.createdAt).format("MMM DD, YYYY")}
              </time>
            </div>
            <div className="user-stats">
              <div>
                <img
                  src={avocado}
                  style={{ width: 28, verticalAlign: 'middle' }}
                  alt="Avocado"
                /> {authorData && abbrNum(Math.floor(authorData.totalJournal/30), 2)} Avocado{authorData && Math.floor(authorData.totalJournal/30) > 1 ? "s" : ""}
              </div>
              <div>
                <ion-icon name="bookmarks"></ion-icon>{" "}
                {(authorData && abbrNum(authorData.totalJournal, 2)) || 0}{" "}
                Journal{authorData && authorData.totalJournal > 1 ? "s" : ""}
              </div>
              <div>
                <ion-icon name="contacts"></ion-icon>{" "}
                <Link to={`/follower/` + (authorData && encodeURIComponent(authorData.id))} id="author_follower">{" "}
                  {(authorData && abbrNum(authorData.followerCount, 2)) || 0}{" "}
                  Follower{authorData && authorData.followerCount > 1 ? "s" : ""}
                </Link>
              </div>
              <div>
                <ion-icon name="contacts"></ion-icon>{" "}
                <Link to={`/following/` + (authorData && encodeURIComponent(authorData.id))} id="author_following">{" "}
                  {(authorData && abbrNum(authorData.followingCount, 2)) || 0}{" "}
                  Following{authorData && authorData.followingCount > 1 ? "s" : ""}
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div id="journal-list" className="journal-list my-public-journals animate-fade-in-up">
          <div className="Container">
            <div className="Container Container--side-padding">
              <h2>My public journals</h2>
            </div>
            {publicJournals.items.length === 0 && (
              <div
                className="Container Container--sm"
                style={{ textAlign: "center", paddingTop: 60 }}
              >
                <img
                  src={community}
                  alt="No public journals from this author"
                />
                <h2>No public journals yet</h2>
              </div>
            )}
            
            {publicJournals.items.length > 0 &&
              <div className="masonry"  onLoad={this.invokeMasonry}>
                {publicJournals.items.map(journal => (
                  <PublicJournalListItem key={journal.id} journal={journal} imageLoaded={this.invokeMasonryAgain} />
                ))}
              </div>
            }

            {publicJournals.nextToken && (
              <div className="load-more">
                {showLoadingIcon ? (
                  <img
                    alt="Loading journals"
                    className="loading-icon"
                    src={loadingIcon}
                  />
                ) : (
                  <button className="btn-secondary" onClick={this._loadMore} id="myAccount_load_more">
                    Load more
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateProfilePic,
      getAuthorPublicJournalData,
      getAuthorData
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyAccount);
