export const getUserFollowings = `query GetUserFollowings($user: String!, $nextToken: String) {
    listFollowings(filter: { user: { eq: $user }}, nextToken: $nextToken) {
      items {
        id
        user
        following
        createdAt
        followingProfile
        followerProfile
      }
      nextToken
    }
  }
  `

export const getUserFollowingsFilteredByTarget = `query GetUserFollowingsFiltered($user: String!, $following: String!) {
    listFollowings(filter: { and: { user: { eq: $user}, following: { eq: $following}}}) {
      items {
        id
      }
      nextToken
    }
  }
  `

export const isUserFollowingTheAuthor = `query IsUserFollowingTheAuthor(
    $following: String!
    $user: String!
    $limit: Int
    $nextToken: String
    $sortDirection: DirectionEnum
  ) {
    isUserFollowingTheAuthor(
      following: $following
      user: $user
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        user
        following
        followingProfile
        followerProfile
        createdAt
        updatedAt
      }
      nextToken
    }
  }
  `

export const getUserFollowers = `query GetUserFollowers($following: String!, $nextToken: String) {
  listFollowings(filter: { following: { eq: $following }}, nextToken: $nextToken) {
      items {
        id
        user
        following
        createdAt
        followingProfile
        followerProfile
      }
      nextToken
  }
}
`

export const getUserFollowersFilteredByTarget = `query GetUserFollowingsFiltered($id: ID!, $target: String!) {
  getUser(id: $id) {
    follower( filter: { target: { eq: $target }} ) {
      items {
        id
        target
        createdAt
      }
      nextToken
    }
  }
}
`

export const userFollowingAsKey = `query UserFollowingAsKey(
  $user: String
  $nextToken: String
  $limit: Int
) {
  userFollowingAsKey(
    user: $user
    nextToken: $nextToken
    sortDirection: DESC
    limit: $limit
  ) {
    items {
      id
      user
      following
      followingProfile
      followerProfile
      createdAt
      updatedAt
    }
    nextToken
  }
}
`
export const userFollowerAsKey = `query UserFollowerAsKey(
  $following: String
  $nextToken: String
  $limit: Int
) {
  userFollowerAsKey(
    following: $following
    nextToken: $nextToken
    sortDirection: DESC
    limit: $limit
  ) {
    items {
      id
      user
      following
      followingProfile
      followerProfile
      createdAt
      updatedAt
    }
    nextToken
  }
}
`

export const getUserJournalOfMonth = `query GetUser(
  $id: ID!
  $yearMonth: String
) {
  getUser(id: $id) {
    numberOfJournal(yearMonth: $yearMonth)
  }
}
`

export const listUsers = `query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
  $yearMonth: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      uuid
      displayName
      profileImage
      membership
      totalJournal
      numberOfJournal(yearMonth: $yearMonth)
      followingCount
      followerCount
      journals {
        nextToken
      }
      comments {
        nextToken
      }
      lovedJournals {
        nextToken
      }
      lovedComments {
        nextToken
      }
      notifications {
        nextToken
      }
      createdAt
      updatedAt
    }
    nextToken
  }
}
`
export const getUser = `query GetUser(
  $id: ID!
  $nextTokenJournal: String
  $nextTokenNotification: String
  $nextTokenPublicJournal: String
  $nextTokenBook: String
  $nextTokenChatRoomUser: String
  $limit: Int
) {
  getUser(id: $id) {
    id
    uuid
    displayName
    profileImage
    membership
    totalJournal
    followingCount
    followerCount
    bookOrder
    journals(limit: $limit, sortDirection: DESC, nextToken: $nextTokenJournal) {
      items {
        id
        privacy
        draft
        lovedCount
        commentCount
        content
        journalWordCount
        createdAt
        updatedAt
        markedAs
        journalBookId
      }
      nextToken
    }
    publicJournals(limit: 30, sortDirection: DESC, nextToken: $nextTokenPublicJournal) {
      items {
        id
        privacy
        content
        journalAuthorId
        markedAs
        journalWordCount
        lovedCount
        commentCount
        createdAt
        updatedAt
      }
      nextToken
    }
    comments {
      items {
        id
        content
        createdAt
        updatedAt
      }
      nextToken
    }
    lovedJournals {
      items {
        id
        createdAt
      }
      nextToken
    }
    lovedComments {
      items {
        id
        createdAt
      }
      nextToken
    }
    notifications(limit: 30, sortDirection: DESC, nextToken: $nextTokenNotification) {
      items {
        id
        content
        category
        link
        createdAt
        read
        actionUser{
          userID
          displayName
          photoURL
        }
        user {
          id
        }
      }
      nextToken
    }
    books(limit: 30, sortDirection: DESC, nextToken: $nextTokenBook) {
      items {
        id
        book
        bookUserId
        bookCoverImg
        bookJournalCount
        createdAt
        updatedAt
      }
      nextToken
    }
    chatRoomUser(limit: 30, sortDirection: DESC, nextToken: $nextTokenChatRoomUser) {
      items {
        id
        createdAt
        updatedAt
        members
        chatRoom {
          id
          receiverHasRead
          createdBy
          messages(sortDirection: DESC) {
            items {
              content
              messageUserId
              createdAt
              user {
                displayName
                id
                profileImage
              }
            }
          }
          chatRoomUsers {
            items {
              user {
                displayName
                id
                profileImage
              }
            }
          }
        }
      }
      nextToken
    }
    createdAt
    updatedAt
  }
}
`
export const getAuthor = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      uuid
      displayName
      profileImage
      membership
      totalJournal
      followingCount
      followerCount
      bookOrder
      journals {
        items {
          id
          privacy
          draft
          content
          journalAuthorId
          markedAs
          lovedCount
          commentCount
          journalWordCount
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      lovedJournals {
        items {
          id
          userLovedJournalUserId
          userLovedJournalJournalId
          createdAt
        }
        nextToken
      }
      publicJournals {
        items {
          id
          privacy
          content
          journalAuthorId
          markedAs
          lovedCount
          commentCount
          journalWordCount
          createdAt
          updatedAt
        }
        nextToken
      }
      lovedComments {
        items {
          id
          userLovedCommentUserId
          userLovedCommentCommentId
          createdAt
        }
        nextToken
      }
      comments {
        items {
          id
          content
          createdAt
          updatedAt
        }
        nextToken
      }
      notifications {
        items {
          id
          content
          category
          link
          read
          createdAt
        }
        nextToken
      }
      books(limit: 30, sortDirection: DESC) {
        items {
          id
          book
          bookUserId
          bookCoverImg
          bookJournalCount
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`
export const getJournals = /* GraphQL */ `
  query GetUser(
    $id: ID!
    $content: String
    $nextTokenJournal: String
    $nextTokenNotification: String
  ) {
    getUser(id: $id) {
      id
      uuid
      displayName
      profileImage
      membership
      totalJournal
      followingCount
      followerCount
      journals(
        limit: 365
        filter: { content: { contains: $content } }
        sortDirection: DESC
        nextToken: $nextTokenJournal
      ) {
        items {
          id
          privacy
          draft
          content
          journalAuthorId
          markedAs
          lovedCount
          commentCount
          journalWordCount
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      lovedJournals {
        items {
          id
          userLovedJournalUserId
          userLovedJournalJournalId
          createdAt
        }
        nextToken
      }
      publicJournals {
        items {
          id
          privacy
          content
          journalAuthorId
          markedAs
          lovedCount
          commentCount
          journalWordCount
          createdAt
          updatedAt
        }
        nextToken
      }
      lovedComments {
        items {
          id
          userLovedCommentUserId
          userLovedCommentCommentId
          createdAt
        }
        nextToken
      }
      comments {
        items {
          id
          content
          createdAt
          updatedAt
        }
        nextToken
      }
      notifications(
        limit: 30
        sortDirection: DESC
        nextToken: $nextTokenNotification
      ) {
        items {
          id
          content
          category
          link
          read
          createdAt
        }
        nextToken
      }
      books(limit: 30, sortDirection: DESC) {
        items {
          id
          book
          bookCoverImg
          bookJournalCount
          createdAt
          updatedAt
        }
        nextToken
      }
      chatRoomUser(limit: 30, sortDirection: DESC) {
        items {
          id
          createdAt
          updatedAt
          members
          chatRoom {
            id
            receiverHasRead
            createdBy
            messages(sortDirection: DESC) {
              items {
                content
                messageUserId
                createdAt
                user {
                  displayName
                  id
                  profileImage
                }
              }
            }
            chatRoomUsers {
              items {
                user {
                  displayName
                  id
                  profileImage
                }
              }
            }
          }
        }
        nextToken
      }
    }
  }
`
export const getJournal = /* GraphQL */ `
  query GetJournal($id: ID!) {
    getJournal(id: $id) {
      id
      privacy
      draft
      content
      journalAuthorId
      markedAs
      lovedCount
      commentCount
      journalWordCount
      journalBookId
      createdAt
      updatedAt
      author {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        journals {
          nextToken
        }
        createdAt
        updatedAt
        lovedJournals {
          nextToken
        }
        publicJournals {
          nextToken
        }
        lovedComments {
          nextToken
        }
        comments {
          nextToken
        }
        notifications {
          nextToken
        }
      }
      owner
      book {
        id
        book
        bookUserId
        bookCoverImg
        bookJournalCount
        journals {
          nextToken
        }
        createdAt
        updatedAt
        user {
          id
          uuid
          displayName
          profileImage
          membership
          totalJournal
          followingCount
          followerCount
          createdAt
          updatedAt
        }
        publicJournals {
          nextToken
        }
      }
    }
  }
`
export const getComment = `query GetComment($id: ID!) {
  getComment(id: $id) {
    id
    content
    author {
      id
      uuid
      displayName
      journals {
        nextToken
      }
      comments {
        nextToken
      }
      lovedJournals {
        nextToken
      }
      lovedComments {
        nextToken
      }
      notifications {
        nextToken
      }
      createdAt
      updatedAt
    }
    journal {
      id
      privacy
      draft
      content
      markedAs
      author {
        id
        uuid
        displayName
        createdAt
        updatedAt
      }
      lovedUsers {
        nextToken
      }
      comments {
        nextToken
      }
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
  }
}
`

export const getLoveUserComment = `query GetComment(
  $id: ID!
  $lovedNextToken: String) {
  getComment(id: $id) {
    id
    content
    lovedUsers(limit: 30, nextToken: $lovedNextToken) {
      items {
        id
        user{
          id
          displayName
          profileImage
        }
        createdAt
      }
      nextToken
    }
    createdAt
    updatedAt
  }
}
`
export const listComments = `query ListComments(
  $filter: ModelCommentFilterInput
  $nextToken: String
) {
  listComments(filter: $filter, limit: 30, nextToken: $nextToken) {
    items {
      id
      content
      author {
        id
        uuid
        displayName
        createdAt
        updatedAt
      }
      journal {
        id
        privacy
        draft
        content
        markedAs
        createdAt
        updatedAt
      }
      lovedUsers {
        nextToken
      }
      createdAt
      updatedAt
    }
    nextToken
  }
}
`
export const listPublicJournals = /* GraphQL */ `
  query ListPublicJournals(
    $filter: ModelPublicJournalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPublicJournals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        privacy
        content
        journalAuthorId
        markedAs
        lovedCount
        commentCount
        journalWordCount
        createdAt
        updatedAt
        author {
          id
          uuid
          displayName
          profileImage
          membership
          totalJournal
          followingCount
          followerCount
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`
export const getLoveUserJournal = `query getPublicJournal(
  $id: ID!
  $lovedNextToken: String
) {
  getPublicJournal(id: $id) {
    id
    content
    lovedUsers(limit: 30, nextToken: $lovedNextToken) {
      items {
        id
        createdAt
        user{
          id
          displayName
          profileImage
        }
      }
      nextToken
    }
    createdAt
    updatedAt
  }
}
`
export const journalAuthorIdMarkedAsKey = `query journalAuthorIdMarkedAsKey(
  $journalAuthorId: String
) {
  journalAuthorIdMarkedAsKey(journalAuthorId: $journalAuthorId, markedAs: { eq: "LATEST" }) {
    items{
      id
      markedAs
    }
    nextToken
  }
}
`
export const getUserLatestPublicJournal = `query JournalAuthorIdPrivacyAsKey(
  $journalAuthorId: String
) {
  journalAuthorIdPrivacyAsKey(
    journalAuthorId: $journalAuthorId
    privacy: { eq: "PUBLIC" }
    sortDirection: DESC
    limit: 1
  ) {
    items {
      id
    }
  }
}
`
export const gnjAmplifyStripeGetCustomerInfo = `query gnjAmplifyStripeGetCustomerInfo($stripeCustomerId: String!) {
  gnjAmplifyStripeGetCustomerInfo(stripeCustomerId: $stripeCustomerId)
}
`
export const yearMonthUserAsKey = `query YearMonthUserAsKey(
  $yearMonth: String
  $user: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelChallengeFilterInput
  $limit: Int
  $nextToken: String
) {
  yearMonthUserAsKey(
    yearMonth: $yearMonth
    user: $user
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      yearMonth
      user
      userDisplayName
      userProfileImage
      journalsPerMonth
      journalWordCountPerMonth
      createdAt
      updatedAt
    }
    nextToken
  }
}
`
export const yearMonthJournalsPerMonthAsKey = `query YearMonthJournalsPerMonthAsKey(
  $yearMonth: String
  $journalsPerMonth: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelChallengeFilterInput
  $limit: Int
  $nextToken: String
) {
  yearMonthJournalsPerMonthAsKey(
    yearMonth: $yearMonth
    journalsPerMonth: $journalsPerMonth
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      yearMonth
      user
      userDisplayName
      userProfileImage
      journalsPerMonth
      journalWordCountPerMonth
      createdAt
      updatedAt
    }
    nextToken
  }
}
`
export const getPublicJournal = /* GraphQL */ `
  query GetPublicJournal($id: ID!, $nextTokenComment: String) {
    getPublicJournal(id: $id) {
      id
      privacy
      content
      journalAuthorId
      markedAs
      lovedCount
      commentCount
      journalWordCount
      publicJournalBookId
      createdAt
      updatedAt
      author {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        createdAt
        updatedAt
        lovedJournals {
          nextToken
        }
        publicJournals {
          nextToken
        }
        lovedComments {
          nextToken
        }
        comments {
          nextToken
        }
        notifications {
          nextToken
        }
      }
      lovedUsers {
        items {
          id
          userLovedJournalUserId
          userLovedJournalJournalId
          createdAt
        }
        nextToken
      }
      book {
        id
        book
        bookUserId
        bookCoverImg
        bookJournalCount
        createdAt
        updatedAt
        user {
          id
          uuid
          displayName
          profileImage
          membership
          totalJournal
          followingCount
          followerCount
          createdAt
          updatedAt
        }
        publicJournals {
          nextToken
        }
      }
      comments(limit: 10, sortDirection: ASC, nextToken: $nextTokenComment) {
        items {
          id
          content
          commentLovedCount
          createdAt
          updatedAt
          author {
            id
            uuid
            displayName
            profileImage
          }
          lovedUsers {
            items {
              id
              createdAt
              user {
                id
                displayName
                profileImage
              }
            }
            nextToken
          }
        }
        nextToken
      }
    }
  }
`
export const markedAsAuthorAsKey = /* GraphQL */ `
  query MarkedAsAuthorAsKey(
    $markedAs: String
    $journalAuthorId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPublicJournalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    markedAsAuthorAsKey(
      markedAs: $markedAs
      journalAuthorId: $journalAuthorId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        privacy
        content
        journalAuthorId
        markedAs
        lovedCount
        commentCount
        createdAt
        updatedAt
        author {
          id
          uuid
          displayName
          profileImage
          membership
          totalJournal
          followingCount
          followerCount
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`
export const journalAuthorLatestAsKey = /* GraphQL */ `
  query JournalAuthorLatestAsKey(
    $journalAuthorId: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPublicJournalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    journalAuthorLatestAsKey(
      journalAuthorId: $journalAuthorId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        privacy
        content
        journalAuthorId
        markedAs
        lovedCount
        commentCount
        journalWordCount
        publicJournalBookId
        createdAt
        updatedAt
        author {
          id
          uuid
          displayName
          profileImage
          membership
          totalJournal
          followingCount
          followerCount
          createdAt
          updatedAt
        }
        book {
          id
          book
          bookUserId
          bookCoverImg
          bookJournalCount
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`
export const publicJournalMarkedAsKey = /* GraphQL */ `
  query PublicJournalMarkedAsKey(
    $markedAs: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPublicJournalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    publicJournalMarkedAsKey(
      markedAs: $markedAs
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        privacy
        content
        journalAuthorId
        markedAs
        lovedCount
        commentCount
        journalWordCount
        publicJournalBookId
        createdAt
        updatedAt
        author {
          id
          uuid
          displayName
          profileImage
          membership
          totalJournal
          followingCount
          followerCount
          createdAt
          updatedAt
        }
        book {
          id
          book
          bookUserId
          bookCoverImg
          bookJournalCount
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`
export const isUserLovedJournalAsKey = /* GraphQL */ `
  query IsUserLovedJournalAsKey(
    $userLovedJournalUserId: String
    $userLovedJournalJournalId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserLovedJournalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    isUserLovedJournalAsKey(
      userLovedJournalUserId: $userLovedJournalUserId
      userLovedJournalJournalId: $userLovedJournalJournalId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userLovedJournalUserId
        userLovedJournalJournalId
        createdAt
        user {
          id
          uuid
          displayName
          profileImage
          membership
          totalJournal
          followingCount
          followerCount
          createdAt
          updatedAt
        }
        journal {
          id
          privacy
          content
          journalAuthorId
          markedAs
          lovedCount
          commentCount
          journalWordCount
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`
export const isUserLovedCommentAsKey = /* GraphQL */ `
  query IsUserLovedCommentAsKey(
    $userLovedCommentUserId: String
    $userLovedCommentCommentId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserLovedCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    isUserLovedCommentAsKey(
      userLovedCommentUserId: $userLovedCommentUserId
      userLovedCommentCommentId: $userLovedCommentCommentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userLovedCommentUserId
        userLovedCommentCommentId
        createdAt
        user {
          id
          uuid
          displayName
          profileImage
          membership
          totalJournal
          followingCount
          followerCount
          createdAt
          updatedAt
        }
        comment {
          id
          content
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`
export const gnjAmplifyuserBackUpJournals = /* GraphQL */ `
  query GnjAmplifyuserBackUpJournals(
    $username: String!
    $deleteBackup: String
  ) {
    gnjAmplifyuserBackUpJournals(
      username: $username
      deleteBackup: $deleteBackup
    )
  }
`
export const getBook = /* GraphQL */ `
  query GetBook(
    $id: ID!
    $nextTokenJournal: String
    $nextTokenPublicJournal: String
  ) {
    getBook(id: $id) {
      id
      book
      bookUserId
      bookCoverImg
      bookJournalCount
      journals(limit: 30, sortDirection: DESC, nextToken: $nextTokenJournal) {
        items {
          id
          privacy
          draft
          content
          journalAuthorId
          markedAs
          lovedCount
          commentCount
          journalWordCount
          journalBookId
          createdAt
          updatedAt
          owner
          author {
            id
            uuid
            displayName
            profileImage
          }
        }
        nextToken
      }
      createdAt
      updatedAt
      user {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        journals {
          nextToken
        }
        createdAt
        updatedAt
        lovedJournals {
          nextToken
        }
        books {
          nextToken
        }
        publicJournals {
          nextToken
        }
        lovedComments {
          nextToken
        }
        comments {
          nextToken
        }
        notifications {
          nextToken
        }
      }
      publicJournals(
        limit: 30
        sortDirection: DESC
        nextToken: $nextTokenPublicJournal
      ) {
        items {
          id
          privacy
          content
          journalAuthorId
          markedAs
          lovedCount
          commentCount
          journalWordCount
          publicJournalBookId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`
export const isUserHasABookAsKey = /* GraphQL */ `
  query IsUserHasABookAsKey(
    $bookUserId: String
    $book: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBookFilterInput
    $limit: Int
    $nextToken: String
  ) {
    isUserHasABookAsKey(
      bookUserId: $bookUserId
      book: $book
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        book
        bookUserId
        bookCoverImg
        bookJournalCount
        journals {
          nextToken
        }
        createdAt
        updatedAt
        user {
          id
          uuid
          displayName
          profileImage
          membership
          totalJournal
          followingCount
          followerCount
          createdAt
          updatedAt
        }
        publicJournals {
          nextToken
        }
      }
      nextToken
    }
  }
`
export const doesChatRoomExist = /* GraphQL */ `
  query DoesChatRoomExist(
    $chatRoomUserUserId: String
    $members: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelChatRoomUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    doesChatRoomExist(
      chatRoomUserUserId: $chatRoomUserUserId
      members: $members
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        chatRoomUserUserId
        chatRoomUserChatRoomId
        members
        chatRoom {
          id
          createdBy
          receiverHasRead
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        user {
          id
          uuid
          displayName
          profileImage
          membership
          totalJournal
          followingCount
          followerCount
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`
export const getChatRoom = /* GraphQL */ `
  query GetChatRoom($id: ID!, $limit: Int, $nextTokenMessage: String) {
    getChatRoom(id: $id) {
      id
      createdBy
      receiverHasRead
      chatRoomUsers {
        items {
          user {
            id
            displayName
          }
          id
          chatRoomUserUserId
          chatRoomUserChatRoomId
          members
          createdAt
          updatedAt
        }
        nextToken
      }
      messages(
        limit: $limit
        sortDirection: DESC
        nextToken: $nextTokenMessage
      ) {
        items {
          user {
            id
            displayName
          }
          id
          content
          messageChatRoomId
          messageUserId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`
export const chatRoomUserChatRoomIdAsKey = /* GraphQL */ `
  query ChatRoomUserChatRoomIdAsKey(
    $chatRoomUserChatRoomId: String
    $chatRoomUserUserId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelChatRoomUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    chatRoomUserChatRoomIdAsKey(
      chatRoomUserChatRoomId: $chatRoomUserChatRoomId
      chatRoomUserUserId: $chatRoomUserUserId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        chatRoomUserUserId
        chatRoomUserChatRoomId
        members
        chatRoom {
          id
          createdBy
          receiverHasRead
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        user {
          id
          uuid
          displayName
          profileImage
          membership
          totalJournal
          followingCount
          followerCount
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`
