import React from 'react'

const LoadingCard = () => (
  <React.Fragment>
    <div className="journalCardLoading">
      <div className="animated-background">
        <div className="background-masker header-top"></div>
        <div className="background-masker header-top-right"></div>
        <div className="background-masker header-middle"></div>
        <div className="background-masker header-bottom"></div>
        <div className="background-masker header-bottom-right"></div>
      </div>
    </div>
    <div className="journalCardLoading">
      <div className="animated-background">
        <div className="background-masker header-top"></div>
        <div className="background-masker header-top-right"></div>
        <div className="background-masker header-middle"></div>
        <div className="background-masker header-bottom"></div>
        <div className="background-masker header-bottom-right"></div>
      </div>
    </div>
  </React.Fragment>
)

export default LoadingCard;