import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import moment from 'moment'

import ProfilePic from '../utils/ProfilePic'
import decodeHtml from '../utils/DecodeHtml'

class PublicJournalListItem extends Component {
  render() {
    const { journal, imageLoaded } = this.props
    let journalImage

    if (journal.content) {
      let tmp = document.createElement('div')
      tmp.innerHTML = journal.content
      journalImage =
        tmp.querySelector('img') &&
        !tmp
          .querySelector('img')
          .getAttribute('src')
          .includes('placeholder.gif') && // remove loading placeholder gif image
        tmp.querySelector('img').getAttribute('src')
    }

    return (
      <div className="journal-list-item journal-card">
        <div className="author">
          <Link
            to={'/author/' + encodeURIComponent(journal.author.id)}
            id={'community_list_author_id_' + journal.author.id}
          >
            <img
              src={ProfilePic(journal.author)}
              className="profile-pic"
              alt="User profile pic"
            />
          </Link>{' '}
          <time>
            <span style={{ textTransform: 'initial' }}>
              <Link
                to={'/author/' + encodeURIComponent(journal.author.id)}
                id={'community_list_author_id_' + journal.author.id}
              >
                <b>
                  {journal.author.displayName
                    ? journal.author.displayName
                    : journal.author.id}
                </b>
              </Link>
            </span>
            <br />
            {moment(journal.createdAt).format('llll')}
          </time>
        </div>
        <Link
          to={'/public-journal/' + journal.id}
          id={'community_public_journal_' + journal.id}
        >
          {journalImage && (
            <div className="journal-featured-image">
              <img
                src={journalImage}
                alt={journal.content.replace(/<(.|\n)*?>/g, '').slice(0, 60)}
                onLoad={imageLoaded}
              />
            </div>
          )}
          <p>
            {journal.publicJournalBookId && (
              <span
                style={{
                  paddingRight: 4,
                  color: '#3bc183',
                  verticalAlign: 'middle',
                }}
              >
                <ion-icon name="book"></ion-icon>
              </span>
            )}
            {decodeHtml(
              journal.content.replace(/<(.|\n)*?>/g, ' ').slice(0, 80)
            )}
            {journal.content.replace(/<(.|\n)*?>/g, '').length > 80 && '...'}{' '}
            {journal.journalWordCount && (
              <i className="word-count"> - {journal.journalWordCount} words</i>
            )}
          </p>
          <div className="journal-list-item-footer">
            <div>
              <ion-icon name="heart"></ion-icon>{' '}
              {journal.lovedCount ? journal.lovedCount : 0}
            </div>
            <div>
              <ion-icon name="ios-chatbubbles"></ion-icon>{' '}
              {journal.commentCount ? journal.commentCount : 0}
            </div>
          </div>
        </Link>
      </div>
    )
  }
}

export default PublicJournalListItem
