import React, { Component } from 'react';
import loadingIcon from "../assets/images/loading-dark.svg";

class CouponForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      coupon: ""
    }
  }

  _getCouponCode = async (e) => {
    this.setState({ coupon: e.target.value })
  }

  _applyCouponCode = async (e) => {
    e.preventDefault();
    const { coupon } = this.state
    this.props.applyCouponCode(coupon)
  }

  render() {
    const { couponMsg, isCouponLoading } = this.props
    const { coupon } = this.state
    return (
      <div>
        <form onSubmit={this._applyCouponCode} className="coupon-code-form">
            <input
              type="text"
              name="couponCode"
              value={coupon}
              placeholder="Coupon code"
              onChange={this._getCouponCode}
              autoCorrect="off"
              autoCapitalize="none"
              required
            />
          <button className="btn-secondary" disabled={isCouponLoading} id="billing_apply_coupon_button">
            {isCouponLoading ? 
            <img
              alt="Loading..."
              className="loading-icon"
              src={loadingIcon}
            /> 
            : 
            "Apply coupon"}
          </button>
        </form>
        {couponMsg ? <p className={couponMsg.includes("successfully") ? "success-msg" : "error-msg"}>{couponMsg}</p> : ''}
      </div>
    )
  }
}

export default CouponForm