import React, { Component } from "react";
import ReactModal from "react-modal";
import loadingIcon from "../assets/images/loading.svg";

class AddBookPopup extends Component {
  _closeModal = () => {
    const { bookPopupControl } = this.props;
    bookPopupControl(false);
  }

  _createABook = async (e) => {
    e.preventDefault()
    await this.props.createABook(this.state)
  }

  _onChange = async e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  _coverImageSelect = async e => {
    this.setState({ bookCoverImg: e.target.files[0] })
  }
  
  render() {
    const { showAddaBook, book, error, isCreatingABook } = this.props

    return (
      <div>
        <ReactModal
          isOpen={showAddaBook}
          contentLabel="Create a book"
          onRequestClose={this._closeModal}
          style={{ overlay: { background: "rgba(0, 0, 0, 0.7" } }}
          className="modal-box book-add-modal"
        >

          <form onSubmit={this._createABook}>
            <h1>Create a book</h1>

            <div className="form-field">
              <label>
                <code>
                  <small>Name of book</small>
                </code>
              </label>
              <input
                type="text"
                name="book"
                value={book}
                placeholder="Name of book"
                onChange={this._onChange}
                autoCorrect="off"
                autoCapitalize="none"
                maxLength="40"
                required
              />
            </div>

            <div className="form-field">
              <label htmlFor="book_cover_imgae">
                <code>
                  <small>Cover image(optional)</small>
                </code>
              </label>
              <input type="file" name="bookCoverImg" id="book_cover_imgae" onChange={this._coverImageSelect} />
            </div>

            {error && <p className="error-msg">{error}</p>}
            <button id="Create a book on popup" disabled={isCreatingABook}>
              {isCreatingABook ? 
                <img
                  alt="Loading..."
                  className="loading-icon"
                  src={loadingIcon}
                /> 
              : "Create"}
            </button>
          </form>

        </ReactModal>
      </div>
    );
  }
}

export default AddBookPopup;
