import Amplify, { API, graphqlOperation, Storage } from 'aws-amplify'
import aws_exports from '../aws-exports'
import {
  createNotificationFromComment,
  createNotificationFromLovedJournal,
  createNotificationFromLovedComment,
} from './notification'
import { adjustUserJournalCount } from './user'
import * as custom_queries from '../customgraphql/queries'
import * as custom_mutations from '../customgraphql/mutations'

Amplify.configure(aws_exports)
const { aws_user_files_s3_bucket: bucket } = aws_exports

// Create a Journal
// -----------------------------------------------------------------------------
export function saveJournal(journal) {
  return async (dispatch) => {
    dispatch({ type: 'REQUEST_CREATE_JOURNAL' })
    try {
      const data = await API.graphql(
        graphqlOperation(custom_mutations.createJournal, { input: journal })
      )
      dispatch({
        type: 'REQUEST_CREATE_JOURNAL_SUCCESS',
        journalCreated: data.data.createJournal,
      })
      dispatch(adjustUserJournalCount(1))
      dispatch(
        checkIfUserHasJournalAlready({
          userUid: journal.journalAuthorId,
          journalWordCountPerMonth: 0,
          request: 'WRITE',
        })
      )
    } catch (err) {
      console.log('error creating journal: ', err)
      dispatch({ type: 'REQUEST_CREATE_JOURNAL_FAILED' })
    }
  }
}

// Update Journal
// -----------------------------------------------------------------------------
export function updateJournal(journal) {
  return async (dispatch) => {
    dispatch({ type: 'REQUEST_UPDATE_JOURNAL' })
    let result
    try {
      if (journal.draft === false) {
        result = await API.graphql(
          graphqlOperation(custom_mutations.updateJournal, {
            input: {
              id: journal.id,
              content: journal.content,
              privacy: journal.privacy,
              journalAuthorId: journal.journalAuthorId,
              draft: journal.draft,
              journalWordCount: journal.journalWordCount,
              journalBookId: journal.journalBookId
                ? journal.journalBookId
                : null,
              createdAt: journal.createdAt,
            },
          })
        )
        dispatch(
          checkIfUserHasJournalAlready({
            userUid: journal.journalAuthorId,
            journalWordCountBeforeEditing:
              journal.journalWordCountBeforeEditing,
            journalWordCountPerMonth: journal.journalWordCount,
            request: 'UPDATE',
          })
        )
        // PublicJournal logics
        if (journal.privacy === 'PUBLIC') {
          const checkPublicJournal = await API.graphql(
            graphqlOperation(custom_queries.getPublicJournal, {
              id: journal.id,
            })
          )
          if (checkPublicJournal.data.getPublicJournal) {
            // Is user editing the exisitng PUBLIC journal -> updatePublicJournal
            await API.graphql(
              graphqlOperation(custom_mutations.updatePublicJournal, {
                input: {
                  id: journal.id,
                  content: journal.content,
                  privacy: 'PUBLIC',
                  journalAuthorId: journal.journalAuthorId,
                  journalWordCount: journal.journalWordCount,
                  publicJournalBookId: journal.journalBookId
                    ? journal.journalBookId
                    : null,
                  createdAt: journal.createdAt,
                },
              })
            )
          } else {
            // Remove all LATEST journals by author before assigning new LATEST
            const getLatestJournals = await API.graphql(
              graphqlOperation(custom_queries.markedAsAuthorAsKey, {
                markedAs: 'LATEST',
                journalAuthorId: { eq: journal.journalAuthorId },
                limit: 3,
              })
            )
            if (getLatestJournals.data.markedAsAuthorAsKey.items.length > 0) {
              for (const publicJournalLatest of getLatestJournals.data
                .markedAsAuthorAsKey.items) {
                await API.graphql(
                  graphqlOperation(custom_mutations.updatePublicJournal, {
                    input: {
                      id: publicJournalLatest.id,
                      markedAs: null,
                    },
                  })
                )
              }
            }
            // Is user creating a new PUBLIC journal -> createPublicJournal
            // Is user changing PRIVATE journal to PUBLIC journal -> createPublicJournal
            let publicJournalData = {
              id: journal.id,
              content: journal.content,
              privacy: 'PUBLIC',
              journalAuthorId: journal.journalAuthorId,
              markedAs: 'LATEST',
              lovedCount: journal.lovedCount,
              commentCount: journal.commentCount,
              journalWordCount: journal.journalWordCount,
              createdAt: journal.createdAt,
            }
            if (journal.journalBookId)
              publicJournalData.publicJournalBookId = journal.journalBookId

            await API.graphql(
              graphqlOperation(custom_mutations.createPublicJournal, {
                input: publicJournalData,
              })
            )
          }
        } else {
          // Is user changing PUBLIC to PRIVATE journal -> deletePublicJournal
          const checkPublicJournal = await API.graphql(
            graphqlOperation(custom_queries.getPublicJournal, {
              id: journal.id,
            })
          )
          if (checkPublicJournal.data.getPublicJournal) {
            const publicLatestJournal = await API.graphql(
              graphqlOperation(custom_mutations.deletePublicJournal, {
                input: { id: journal.id },
              })
            )
            // if deleted journal is LATEST public journal, find the next latest public journal and assign LATEST
            if (
              publicLatestJournal.data.deletePublicJournal.markedAs === 'LATEST'
            ) {
              const latestJournalData = await API.graphql(
                graphqlOperation(custom_queries.journalAuthorLatestAsKey, {
                  journalAuthorId:
                    publicLatestJournal.data.deletePublicJournal
                      .journalAuthorId,
                  sortDirection: 'DESC',
                })
              )
              if (
                latestJournalData.data.journalAuthorLatestAsKey.items.length > 0
              ) {
                await API.graphql(
                  graphqlOperation(custom_mutations.updatePublicJournal, {
                    input: {
                      id: latestJournalData.data.journalAuthorLatestAsKey
                        .items[0].id,
                      markedAs: 'LATEST',
                    },
                  })
                )
              }
            }
          }
        }
        dispatch({
          type: 'REQUEST_UPDATE_JOURNAL_SUCCESS',
          journalUpdated:
            journal.privacy === 'PUBLIC'
              ? result.data.updatePublicJournal
              : result.data.updateJournal,
        })
      } else {
        let resetData = {
          id: journal.id,
          content: journal.content,
          privacy: journal.privacy,
          journalAuthorId: journal.journalAuthorId,
          journalWordCount: journal.journalWordCount,
          draft: journal.draft,
          journalBookId: journal.journalBookId ? journal.journalBookId : null,
          createdAt: journal.createdAt,
        }
        result = await API.graphql(
          graphqlOperation(custom_mutations.updateJournal, { input: resetData })
        )
        dispatch({
          type: 'REQUEST_UPDATE_JOURNAL_SUCCESS',
          journalUpdated: result.data.updateJournal,
        })
      }
    } catch (err) {
      console.log('error updating journal: ', err)
      dispatch({ type: 'REQUEST_UPDATE_JOURNAL_FAILED' })
    }
  }
}

// Get Journal
// -----------------------------------------------------------------------------
export function getJournal(journalUid) {
  return async (dispatch) => {
    dispatch({ type: 'JOURNAL_LOADING_STATUS', loading: true })
    try {
      const data = await API.graphql(
        graphqlOperation(custom_queries.getJournal, { id: journalUid })
      )
      dispatch({
        type: 'JOURNAL_AVAILABLE',
        currentJournal: data.data.getJournal,
      })
      return data.data.getJournal
    } catch (err) {
      console.log('error getting journal: ', err)
    }
  }
}

// Delete Journal
// -----------------------------------------------------------------------------
export function deleteJournal(journalUid) {
  return async (dispatch) => {
    try {
      const data = await API.graphql(
        graphqlOperation(custom_mutations.deleteJournal, {
          input: { id: journalUid },
        })
      )
      // Remove public journal if it's a public journal
      if (data.data.deleteJournal.privacy === 'PUBLIC') {
        const publicLatestJournal = await API.graphql(
          graphqlOperation(custom_mutations.deletePublicJournal, {
            input: { id: journalUid },
          })
        )
        // if deleted journal is LATEST public journal, find the next latest public journal and assign LATEST
        if (
          publicLatestJournal.data.deletePublicJournal &&
          publicLatestJournal.data.deletePublicJournal.markedAs === 'LATEST'
        ) {
          const latestJournalData = await API.graphql(
            graphqlOperation(custom_queries.journalAuthorLatestAsKey, {
              journalAuthorId:
                publicLatestJournal.data.deletePublicJournal.journalAuthorId,
              sortDirection: 'DESC',
            })
          )
          if (
            latestJournalData.data.journalAuthorLatestAsKey.items.length > 0
          ) {
            await API.graphql(
              graphqlOperation(custom_mutations.updatePublicJournal, {
                input: {
                  id: latestJournalData.data.journalAuthorLatestAsKey.items[0]
                    .id,
                  markedAs: 'LATEST',
                },
              })
            )
          }
        }
      }
      dispatch(adjustUserJournalCount(-1))
      // Update book bookJournalCount count number with -1 if journalBookId exist
      if (
        data.data.deleteJournal.journalBookId &&
        data.data.deleteJournal.journalBookId !== null
      ) {
        dispatch(
          adjustBookJournalsCount(data.data.deleteJournal.journalBookId, -1)
        )
      }

      dispatch(
        checkIfUserHasJournalAlready({
          userUid: data.data.deleteJournal.author.id,
          journalWordCountPerMonth: data.data.deleteJournal.journalWordCount,
          request: 'DELETE',
        })
      )

      return data.data.deleteJournal
    } catch (err) {
      console.log('error deleteJournal: ', err)
    }
  }
}

// Set Journal
// -----------------------------------------------------------------------------
export function setJournal(journal) {
  return (dispatch) => {
    dispatch({ type: 'JOURNAL_TEMP_SET', journal })
  }
}

// Set current Public journal empty
// -----------------------------------------------------------------------------
export function setEmptyCurrentJournal() {
  return (dispatch) => {
    dispatch({ type: 'JOURNAL_AVAILABLE', currentJournal: null })
  }
}

// Create a Comment
// -----------------------------------------------------------------------------
export function createComment(comment, journalAuthorId) {
  return async (dispatch, getState) => {
    try {
      const data = await API.graphql(
        graphqlOperation(custom_mutations.createComment, { input: comment })
      )
      let newCommentCount = getState().journal.currentJournal.commentCount + 1

      dispatch(
        adjustJournalCommentCount(
          getState().journal.currentJournal.id,
          newCommentCount
        )
      )
      createNotificationFromComment(
        comment,
        journalAuthorId,
        getState().user.userData
      )

      getState().journal.currentJournal.comments.items = [
        ...getState().journal.currentJournal.comments.items,
        data.data.createComment,
      ]
      getState().journal.currentJournal.commentCount = newCommentCount
      dispatch({
        type: 'JOURNAL_AVAILABLE',
        currentJournal: getState().journal.currentJournal,
      })
    } catch (err) {
      console.log(err)
      if (err.errors[0].errorType === 'Unauthorized') {
        createNotificationFromComment(
          comment,
          journalAuthorId,
          getState().user.userData
        )
      } else {
        console.log('error commenting: ', err)
      }
    }
  }
}

// Update Comment
// -----------------------------------------------------------------------------
export function updateComment(comment, newComment) {
  return async (dispatch, getState) => {
    try {
      const data = await API.graphql(
        graphqlOperation(custom_mutations.updateComment, {
          input: { id: comment.id, content: newComment },
        })
      )
      let findUpdatedComment =
        getState().journal.currentJournal.comments.items.findIndex(
          (x) => x.id === data.data.updateComment.id
        )
      getState().journal.currentJournal.comments.items[findUpdatedComment] =
        data.data.updateComment
      getState().journal.currentJournal.comments.items[findUpdatedComment][
        'hasLoved'
      ] = comment.hasLoved
      dispatch({
        type: 'JOURNAL_AVAILABLE',
        currentJournal: getState().journal.currentJournal,
      })
    } catch (err) {
      console.log('error updating comment: ', err)
    }
  }
}

// Delete Comment
// -----------------------------------------------------------------------------
export function deleteComment(commentId) {
  return async (dispatch, getState) => {
    try {
      const data = await API.graphql(
        graphqlOperation(custom_mutations.deleteComment, { input: commentId })
      )
      let newCommentCount =
        getState().journal.currentJournal.commentCount > 0
          ? getState().journal.currentJournal.commentCount - 1
          : 0

      dispatch(
        adjustJournalCommentCount(
          getState().journal.currentJournal.id,
          newCommentCount
        )
      )

      getState().journal.currentJournal.comments.items =
        getState().journal.currentJournal.comments.items.filter(function (obj) {
          return obj.id !== data.data.deleteComment.id
        })
      getState().journal.currentJournal.commentCount = newCommentCount
      dispatch({
        type: 'JOURNAL_AVAILABLE',
        currentJournal: getState().journal.currentJournal,
      })
    } catch (err) {
      console.log('error deleteing comment: ', err)
    }
  }
}

// Adjust journal comment count
// -----------------------------------------------------------------------------
export function adjustJournalCommentCount(journalId, commentCount) {
  return async () => {
    let count = 0
    let maxTries = 3

    while (true) {
      try {
        await Promise.all([
          API.graphql(
            graphqlOperation(custom_mutations.gnjAmplifyJournalCommentCount, {
              journalId: journalId,
              commentCount: commentCount,
            })
          ),
          API.graphql(
            graphqlOperation(custom_mutations.updatePublicJournal, {
              input: {
                id: journalId,
                commentCount: commentCount,
              },
            })
          ),
        ])
        break
      } catch (err) {
        console.log('error adjustJournalCommentCount: ', err)
        if (++count === maxTries) throw err
      }
    }
  }
}

// Get more comment
// -----------------------------------------------------------------------------
export function getMoreComments(journalUid, nextToken) {
  return async (dispatch, getState) => {
    try {
      const data = await API.graphql({
        query: custom_queries.getPublicJournal,
        variables: {
          id: journalUid,
          nextTokenComment: nextToken,
        },
        authMode: 'AWS_IAM',
      })
      if (nextToken) {
        return data.data.getPublicJournal.comments
      }
      dispatch({
        type: 'JOURNAL_AVAILABLE',
        currentJournal: data.data.getPublicJournal,
      })
    } catch (err) {
      console.log('error getting comment: ', err)
    }
  }
}

// Get Public Journal List
// -----------------------------------------------------------------------------
export function getPublicJournals(limit, nextToken) {
  return async () => {
    try {
      const data = await API.graphql({
        query: custom_queries.publicJournalMarkedAsKey,
        variables: {
          markedAs: 'LATEST',
          limit: limit,
          sortDirection: 'DESC',
          nextToken,
        },
        authMode: 'AWS_IAM',
      })
      return data.data.publicJournalMarkedAsKey
    } catch (err) {
      console.log('error getting journal: ', err)
    }
  }
}

export function setCommunityLimit(limit) {
  return (dispatch) => {
    dispatch({ type: 'SET_COMMUNITY_LIMIT', limit })
  }
}

// Filter Public Journal List
// -----------------------------------------------------------------------------
export function searchPublicJournals(value, nextToken) {
  return async () => {
    try {
      // TODO: search needs to be improved because query and filter only covers the data that are queried.
      const data = await API.graphql(
        graphqlOperation(custom_queries.listPublicJournals, {
          filter: { content: { contains: value } },
          limit: 100,
          nextToken,
        })
      )
      return data.data.listPublicJournals
    } catch (err) {
      console.log('error getting journal: ', err)
    }
  }
}

// Get Public Journal Entry
// -----------------------------------------------------------------------------
export function getPublicJournalEntry(journalUid) {
  return async (dispatch) => {
    dispatch({ type: 'JOURNAL_LOADING_STATUS', loading: true })
    // dispatch({ type: "JOURNAL_AVAILABLE", currentJournal: null })
    try {
      const data = await API.graphql({
        query: custom_queries.getPublicJournal,
        variables: {
          id: journalUid,
        },
        authMode: 'AWS_IAM',
      })
      dispatch({
        type: 'JOURNAL_AVAILABLE',
        currentJournal: data.data.getPublicJournal,
      })
      return data.data.getPublicJournal
    } catch (err) {
      console.log('error getting journal: ', err)
    }
  }
}

// Create User Love Journal
// -----------------------------------------------------------------------------
export function createUserLovedJournal(
  userLovedJournalJournalId,
  userLovedJournalUserId,
  journalAuthorId
) {
  return async (dispatch, getState) => {
    try {
      const data = await API.graphql(
        graphqlOperation(custom_mutations.createUserLovedJournal, {
          input: { userLovedJournalJournalId, userLovedJournalUserId },
        })
      )

      await Promise.all([
        API.graphql(
          graphqlOperation(custom_mutations.gnjAmplifyJournalLovedCount, {
            journalId: getState().journal.currentJournal.id,
            lovedCount: getState().journal.currentJournal.lovedCount,
          })
        ),
        API.graphql(
          graphqlOperation(custom_mutations.updatePublicJournal, {
            input: {
              id: getState().journal.currentJournal.id,
              lovedCount: getState().journal.currentJournal.lovedCount,
            },
          })
        ),
      ])

      createNotificationFromLovedJournal(
        userLovedJournalJournalId,
        journalAuthorId,
        getState().user.userData
      )

      return data.data.createUserLovedJournal
    } catch (err) {
      if (err.errors[0].errorType === 'Unauthorized') {
        createNotificationFromLovedJournal(
          userLovedJournalJournalId,
          journalAuthorId,
          getState().user.userData
        )
      } else {
        console.log('error Create User Love Journal: ', err)
      }
    }
  }
}

// Delete User Love Journal
// -----------------------------------------------------------------------------
export function deleteUserLovedJournal(id) {
  return async (dispatch, getState) => {
    try {
      const data = await API.graphql(
        graphqlOperation(custom_mutations.deleteUserLovedJournal, {
          input: { id },
        })
      )

      await Promise.all([
        API.graphql(
          graphqlOperation(custom_mutations.gnjAmplifyJournalLovedCount, {
            journalId: getState().journal.currentJournal.id,
            lovedCount: getState().journal.currentJournal.lovedCount,
          })
        ),
        API.graphql(
          graphqlOperation(custom_mutations.updatePublicJournal, {
            input: {
              id: getState().journal.currentJournal.id,
              lovedCount: getState().journal.currentJournal.lovedCount,
            },
          })
        ),
      ])

      return data.data.deleteUserLovedJournal
    } catch (err) {
      console.log('error Delete User Love Journal: ', err)
    }
  }
}

// Create User Love Comment
// -----------------------------------------------------------------------------
export function createUserLovedComment(
  comment,
  userLovedCommentUserId,
  journalID
) {
  return async (dispatch, getState) => {
    try {
      const data = await API.graphql(
        graphqlOperation(custom_mutations.createUserLovedComment, {
          input: {
            userLovedCommentCommentId: comment.id,
            userLovedCommentUserId,
          },
        })
      )
      await API.graphql(
        graphqlOperation(custom_mutations.updateComment, {
          input: {
            id: comment.id,
            commentLovedCount: comment.commentLovedCount,
          },
        })
      )
      createNotificationFromLovedComment(
        comment,
        journalID,
        getState().user.userData
      )
      return data.data.createUserLovedComment
    } catch (err) {
      if (err.errors[0].errorType === 'Unauthorized') {
        createNotificationFromLovedComment(
          comment,
          journalID,
          getState().user.userData
        )
      } else {
        console.log('errer creating user loved comment: ', err)
      }
    }
  }
}

// Delete User Love Comment
// -----------------------------------------------------------------------------
export function deleteUserLovedComment(comment) {
  return async (dispatch) => {
    try {
      const commentLovedID = comment.hasLoved

      await Promise.all([
        API.graphql(
          graphqlOperation(custom_mutations.deleteUserLovedComment, {
            input: { id: commentLovedID },
          })
        ),
        API.graphql(
          graphqlOperation(custom_mutations.updateComment, {
            input: {
              id: comment.id,
              commentLovedCount: comment.commentLovedCount,
            },
          })
        ),
      ])
    } catch (err) {
      console.log('error deleting user loved comment: ', err)
    }
  }
}

// Get Loved users from Public Journal Entry
// -----------------------------------------------------------------------------
export function getLovedUsersFromJournal(journalUid, nextToken) {
  return async (dispatch) => {
    if (!nextToken)
      dispatch({ type: 'SET_LOVERLOADING_STATUS', loverLoading: true })
    try {
      const data = await API.graphql(
        graphqlOperation(custom_queries.getLoveUserJournal, {
          id: journalUid,
          lovedNextToken: nextToken,
        })
      )
      if (nextToken)
        dispatch({
          type: 'ADD_LOVED_USERS',
          lovedUsers: data.data.getPublicJournal.lovedUsers,
        })
      else
        dispatch({
          type: 'SET_LOVED_USERS',
          lovedUsers: data.data.getPublicJournal.lovedUsers,
        })
      dispatch({ type: 'SET_LOVERLOADING_STATUS', loverLoading: false })
    } catch (err) {
      console.log('error getting loved user from journal: ', err)
      dispatch({ type: 'SET_LOVERLOADING_STATUS', loverLoading: false })
    }
  }
}

// Get Loved users from Comment
// -----------------------------------------------------------------------------
export function getLovedUsersFromComment(commendUid, nextToken) {
  return async (dispatch) => {
    if (!nextToken)
      dispatch({ type: 'SET_LOVERLOADING_STATUS', loverLoading: true })
    try {
      const data = await API.graphql(
        graphqlOperation(custom_queries.getLoveUserComment, {
          id: commendUid,
          lovedNextToken: nextToken,
        })
      )
      if (nextToken)
        dispatch({
          type: 'ADD_LOVED_USERS',
          lovedUsers: data.data.getComment.lovedUsers,
        })
      else
        dispatch({
          type: 'SET_LOVED_USERS',
          lovedUsers: data.data.getComment.lovedUsers,
        })
      dispatch({ type: 'SET_LOVERLOADING_STATUS', loverLoading: false })
    } catch (err) {
      console.log('error getting loved user from comment ', err)
      dispatch({ type: 'SET_LOVERLOADING_STATUS', loverLoading: false })
    }
  }
}

// Check Challenge table with username and yearMonth then excute functions accordingly
// -----------------------------------------------------------------------------
export function checkIfUserHasJournalAlready(challengeData) {
  return async (dispatch, getState) => {
    try {
      const currentYear = new Date().getFullYear()
      const currentMonth = new Date().getMonth()
      const yearMonth = currentYear + '-' + ('0' + (currentMonth + 1)).slice(-2)
      const data = await API.graphql(
        graphqlOperation(custom_queries.yearMonthUserAsKey, {
          yearMonth: yearMonth,
          user: { eq: challengeData.userUid },
        })
      )
      //Excute functions accordingly
      if (challengeData.request === 'WRITE') {
        if (data.data.yearMonthUserAsKey.items.length === 0) {
          dispatch(
            createChallenge(challengeData, yearMonth, getState().user.userData)
          )
        } else {
          dispatch(
            updateChallenge(
              data.data.yearMonthUserAsKey.items[0].id,
              data.data.yearMonthUserAsKey.items[0].journalsPerMonth + 1,
              getState().user.userData,
              data.data.yearMonthUserAsKey.items[0].journalWordCountPerMonth +
                challengeData.journalWordCountPerMonth
            )
          )
        }
      } else if (challengeData.request === 'UPDATE') {
        const countJournalWordCountPerMonth =
          challengeData.journalWordCountBeforeEditing
            ? data.data.yearMonthUserAsKey.items[0].journalWordCountPerMonth +
              (challengeData.journalWordCountPerMonth -
                challengeData.journalWordCountBeforeEditing)
            : data.data.yearMonthUserAsKey.items[0].journalWordCountPerMonth +
              challengeData.journalWordCountPerMonth

        dispatch(
          updateChallenge(
            data.data.yearMonthUserAsKey.items[0].id,
            data.data.yearMonthUserAsKey.items[0].journalsPerMonth,
            getState().user.userData,
            countJournalWordCountPerMonth
          )
        )
      } else {
        if (data.data.yearMonthUserAsKey.items[0].journalsPerMonth <= 1) {
          dispatch(deleteChallenge(data.data.yearMonthUserAsKey.items[0].id))
        } else {
          dispatch(
            updateChallenge(
              data.data.yearMonthUserAsKey.items[0].id,
              data.data.yearMonthUserAsKey.items[0].journalsPerMonth - 1,
              getState().user.userData,
              data.data.yearMonthUserAsKey.items[0].journalWordCountPerMonth -
                challengeData.journalWordCountPerMonth
            )
          )
        }
      }
    } catch (e) {
      console.log('checkIfUserHasJournalAlready ', e)
    }
  }
}

// Add item to Challenge table
// -----------------------------------------------------------------------------
export function createChallenge(challengeData, yearMonth, userData) {
  return async () => {
    try {
      await API.graphql(
        graphqlOperation(custom_mutations.createChallenge, {
          input: {
            yearMonth: yearMonth,
            user: challengeData.userUid,
            journalsPerMonth: 1,
            journalWordCountPerMonth: challengeData.journalWordCountPerMonth,
            userProfileImage: userData.profileImage
              ? userData.profileImage
              : null,
            userDisplayName: userData.displayName ? userData.displayName : null,
          },
        })
      )
    } catch (err) {
      console.log('createChallenge ', err)
    }
  }
}

// Remove item on Challenge table
// -----------------------------------------------------------------------------
export function deleteChallenge(challengeUid) {
  return async () => {
    try {
      await API.graphql(
        graphqlOperation(custom_mutations.deleteChallenge, {
          input: {
            id: challengeUid,
          },
        })
      )
    } catch (err) {
      console.log('deleteChallenge ', err)
    }
  }
}

// Update Challenge table
// -----------------------------------------------------------------------------
export function updateChallenge(
  challengeUid,
  newNumberOfJournal,
  userData,
  journalWordCountPerMonth
) {
  return async () => {
    try {
      await API.graphql(
        graphqlOperation(custom_mutations.updateChallenge, {
          input: {
            id: challengeUid,
            journalsPerMonth: newNumberOfJournal,
            journalWordCountPerMonth:
              journalWordCountPerMonth < 0 ? 0 : journalWordCountPerMonth,
            userProfileImage: userData.profileImage
              ? userData.profileImage
              : null,
            userDisplayName: userData.displayName ? userData.displayName : null,
          },
        })
      )
    } catch (err) {
      console.log('updateChallenge ', err)
    }
  }
}

// Check is current user loved journal or not
// -----------------------------------------------------------------------------
export function isUserLovedJournal(user, journal) {
  return async () => {
    try {
      const data = await API.graphql(
        graphqlOperation(custom_queries.isUserLovedJournalAsKey, {
          userLovedJournalUserId: user,
          userLovedJournalJournalId: { eq: journal },
        })
      )
      return data.data.isUserLovedJournalAsKey
    } catch (err) {
      console.log('isUserLovedJournal ', err)
    }
  }
}

// Check is current user loved comment or not
// -----------------------------------------------------------------------------
export function isUserLovedComment(user, comment) {
  return async () => {
    try {
      const data = await API.graphql(
        graphqlOperation(custom_queries.isUserLovedCommentAsKey, {
          userLovedCommentUserId: user,
          userLovedCommentCommentId: { eq: comment },
        })
      )
      return data.data.isUserLovedCommentAsKey
    } catch (err) {
      // console.log('isUserLovedComment ', err)
    }
  }
}

// Check if user has the book with the same name already
// -----------------------------------------------------------------------------
export function isUserHasABook(user, bookName) {
  return async () => {
    try {
      const data = await API.graphql(
        graphqlOperation(custom_queries.isUserHasABookAsKey, {
          bookUserId: user,
          book: { eq: bookName },
        })
      )
      return data.data.isUserHasABookAsKey
    } catch (err) {
      console.log('isUserHasABook ', err)
    }
  }
}

// Create a book
// -----------------------------------------------------------------------------
export function createABook(bookData) {
  return async (dispatch, getState) => {
    const username = getState().user.userData.id
    const numberOfBooks = getState().user.userData.books.items.length

    // Create up to 30 books
    if (numberOfBooks >= 30) {
      return { error: 'You have reached the maximum number(30) of books.' }
    }

    // check if user has the book with the same name already
    const checkedBookName = await dispatch(
      isUserHasABook(username, bookData.book)
    )
    if (checkedBookName.items.length > 0) {
      return { error: 'Book name exists already' }
    } else {
      // Call this with or without cover image
      async function createABookReqeust(coverImage) {
        const data = await API.graphql(
          graphqlOperation(custom_mutations.createBook, {
            input: {
              book: bookData.book,
              bookUserId: username,
              bookCoverImg: coverImage,
            },
          })
        )

        dispatch({ type: 'CREATE_USER_BOOK', userBooks: data.data.createBook })
        // return data.data.createBook
      }

      try {
        if (bookData.bookCoverImg) {
          const coverImage = await dispatch(
            uploadBookCoverImage(username, bookData.bookCoverImg)
          )
          createABookReqeust(coverImage)
        } else {
          createABookReqeust()
        }
      } catch (err) {
        console.log('createABook ', err)
      }
    }
  }
}

// Upload book cover image to S3
// -----------------------------------------------------------------------------
export function uploadBookCoverImage(username, file) {
  return async () => {
    const extension = /^.+\.([^.]+)$/.exec(file.name)[1]
    const { type: mimeType } = file
    const dataToURL = new Date()
      .toJSON()
      .slice(0, 23)
      .replace(/[^0-9-]/g, '-')
    const filePath = `book/${username}-book-${dataToURL}.${extension}`

    try {
      // Uploading to S3 bucket
      var options = {
        ACL: 'public-read',
        contentType: mimeType,
        level: 'public',
      }
      await Storage.put(filePath, file, options)
      return `https://${bucket}.s3.amazonaws.com/public/book/${encodeURIComponent(
        username
      )}-book-${dataToURL}.${extension}`
    } catch (err) {
      console.log('error uploadBookCoverImage: ', err)
    }
  }
}

// Get More User Books
// -----------------------------------------------------------------------------
export function getMoreUserBooks(username, nextToken) {
  return async (dispatch) => {
    try {
      const data = await API.graphql(
        graphqlOperation(custom_queries.getUser, {
          id: username,
          nextTokenBook: nextToken,
        })
      )
      const newBookData = {
        items: data.data.getUser.books.items,
        nextToken: data.data.getUser.books.nextToken,
      }
      dispatch({ type: 'ADD_USER_BOOK_DATA', newBookData })
    } catch (err) {
      console.log('error getMoreUserBooks: ', err)
    }
  }
}

// Get a Book with journals
// -----------------------------------------------------------------------------
export function getABookWithJournals(
  bookID,
  nextTokenJournal,
  nextTokenPublicJournal
) {
  return async (dispatch) => {
    try {
      const data = await API.graphql(
        graphqlOperation(custom_queries.getBook, {
          id: bookID,
          nextTokenJournal: nextTokenJournal,
          nextTokenPublicJournal: nextTokenPublicJournal,
        })
      )
      return data.data.getBook
    } catch (err) {
      console.log('error getABookWithJournals: ', err)
    }
  }
}

// Delete a book
// -----------------------------------------------------------------------------
export function deleteABook(bookID) {
  return async (dispatch) => {
    try {
      const data = await API.graphql(
        graphqlOperation(custom_mutations.deleteBook, { input: { id: bookID } })
      )
      dispatch({ type: 'REMOVE_A_BOOK', deletedBook: data.data.deleteBook })
      // return data.data.deleteBook
    } catch (err) {
      console.log('error deleteABook: ', err)
    }
  }
}

// Update a book
// -----------------------------------------------------------------------------
export function updateABook(bookData) {
  return async (dispatch, getState) => {
    const username = getState().user.userData.id

    try {
      let coverImage = bookData.bookCoverImg
      if (bookData.newImage) {
        coverImage = await dispatch(
          uploadBookCoverImage(username, bookData.bookCoverImg)
        )
      }

      const data = await API.graphql(
        graphqlOperation(custom_mutations.updateBook, {
          input: {
            id: bookData.id,
            book: bookData.book,
            bookCoverImg: coverImage,
            bookJournalCount: bookData.bookJournalCount,
          },
        })
      )

      dispatch({ type: 'UPDATE_A_BOOK', updatedBook: [data.data.updateBook] })
      return data.data.updateBook
    } catch (err) {
      console.log('error updateABook: ', err)
    }
  }
}

// Update book journal number count
// -----------------------------------------------------------------------------
export function adjustBookJournalsCount(bookId, diff) {
  return async (dispatch, getState) => {
    let count = 0
    let maxTries = 3

    while (true) {
      try {
        const getTotalJournalsOfTheBook = getState().user.userBooks.filter(
          (book) => {
            return book.id === bookId
          }
        )
        let total =
          getTotalJournalsOfTheBook[0] &&
          getTotalJournalsOfTheBook[0].bookJournalCount
            ? getTotalJournalsOfTheBook[0].bookJournalCount + diff
            : diff
        if (total < 0) total = 0
        const data = await API.graphql(
          graphqlOperation(custom_mutations.updateBook, {
            input: {
              id: bookId,
              bookJournalCount: total,
            },
          })
        )

        dispatch({ type: 'UPDATE_A_BOOK', updatedBook: [data.data.updateBook] })
        break
      } catch (err) {
        console.log('error adjustBookJournalsCount: ', err)
        if (++count === maxTries) throw err
      }
    }
  }
}
