import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";

import { injectStripe } from "react-stripe-elements";
import { getCurrentUser } from "../actions/user";

import {
  stripeCreateMember,
  stripeGetCustomerInfo,
  updateStripeCustomerInfo,
  stripeGetCouponCode,
  stripeCancelMembership
} from "../actions/membership";

import LoadingCard from "../components/LoadingCard";
import Footer from "../components/Footer";
import BillingSubscriptionInfo from "../components/BillingSubscriptionInfo";
import { BillingListItem } from "../components/BillingListItem";

class Billing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      isLoading: false,
      stripeData: {},
      showUpdateCreditCardValue: false,
      couponMsg: "",
      couponDiscount: "",
      couponCode: "",
      isCouponLoading: false,
      membershipPlanSelected: "annual"
    };
  }

  async componentDidMount() {
    if (!this.props.user.currentUser) {
      await this.props.getCurrentUser();
    }

    const stripeCustomerId = this.props.user.currentUser.attributes["custom:stripeCustomerId"];

    if (stripeCustomerId) {
      try {
        const stripeData = await this.props.stripeGetCustomerInfo(
          stripeCustomerId
        );
        if(stripeData.raw){
          window.confirm(stripeData.raw.message + " Please reload the page.");
        } else {
          this.setState({ stripeData });
        }
      } catch (err) {
        console.log(err);
      }
    }
  }

  _applyCouponcode = async (couponCodePassedForSubmit) => {
    this.setState({ isCouponLoading: true })
    const couponInfo = await this.props.stripeGetCouponCode(couponCodePassedForSubmit)
    if(couponInfo.id){
      this.setState({
        couponCode: couponInfo.id,
        couponMsg: couponInfo.name + " applied successfully!",
        couponDiscount: couponInfo.percent_off,
        isCouponLoading: false
      })
    } else {
      this.setState({
        couponMsg: couponInfo.raw.message,
        couponDiscount: "",
        couponCode: "",
        isCouponLoading: false
      })
    }
  }

  _showUpdateCreditCard = async e => {
    this.setState({
      showUpdateCreditCardValue: !this.state.showUpdateCreditCardValue
    });
  };

  _creditCardSubmit = async e => {
    e.preventDefault();
    const { couponCode, membershipPlanSelected } = this.state
    const username = this.props.user.accountData.idToken.payload[
      "cognito:username"
    ];
    const email = this.props.user.currentUser.attributes.email;
    const uuid = this.props.user.accountData.idToken.payload.sub;
    const stripeCustomerId = this.props.user.currentUser.attributes["custom:stripeCustomerId"];
    this.setState({ error: "", isLoading: true });

    const token = await this.props.stripe.createToken();
    if (token.error) {
      return this.setState({
        error: token.error.message,
        isLoading: false
      });
    } else {
      const userSubscribe = await this.props.stripeCreateMember({
        stripeToken: token.token.id,
        username: username,
        email: email,
        uuid: uuid,
        stripeCouponId: couponCode,
        stripeCustomerId: stripeCustomerId,
        membershipPlanSelected: membershipPlanSelected
      });
      if (userSubscribe.subscription) {
        await this.props.getCurrentUser();
        this.setState({
          error: "",
          isLoading: false,
          showUpdateCreditCardValue: false
        });
        // TODO: maybe use stripeCreateMember function's return data instead of call Stripe again
        const stripeData = await this.props.stripeGetCustomerInfo(userSubscribe.customer.id);
        if(stripeData.raw){
          window.confirm(stripeData.raw.message + " Please reload the page.");
        } else {
          this.setState({ stripeData });
        }
      } else {
        this.setState({ error: userSubscribe.raw.message, isLoading: false });
      }
    }
  };

  _creaditCardUpdate = async e => {
    e.preventDefault();
    const { stripeData } = this.state;
    const stripeCustomerId = this.props.user.currentUser.attributes["custom:stripeCustomerId"];
    this.setState({ error: "", isLoading: true });
    const token = await this.props.stripe.createToken();
    if (token.error) {
      return this.setState({
        error: token.error.message,
        isLoading: false
      });
    } else {
      const updatedCreditCard = await this.props.updateStripeCustomerInfo({
        stripeCustomerId: stripeCustomerId, 
        stripeToken: token.token.id 
      });
      if(updatedCreditCard.raw){
        window.confirm(stripeData.raw.message + " Please try again.");
      } else {
        this.setState({
          stripeData: {
            customer: updatedCreditCard,
            charges: stripeData.charges
          },
          error: "",
          isLoading: false,
          showUpdateCreditCardValue: false
        });
      }
    }
  };

  _reactivateMembershipRequest = async () => {
    const { currentUser } = this.props.user
    const { stripeData } = this.state
    this.setState({ error: "", isLoading: true });
    const reactivateMembership  = await this.props.stripeCancelMembership(
      currentUser.attributes["custom:stripeSubscriptionId"],
      currentUser.username,
      false,
      stripeData.customer.subscriptions.data[0].plan.nickname.toLowerCase()
    )
    
    if(reactivateMembership.subscription){
      await this.props.getCurrentUser();
      this.setState({
        error: "",
        isLoading: false
      });
      // TODO: maybe use stripeCancelMembership function's return data instead of call Stripe again
      const stripeData = await this.props.stripeGetCustomerInfo(
        this.props.user.currentUser.attributes["custom:stripeCustomerId"]
      );
      if(stripeData.raw){
        window.confirm(stripeData.raw.message + " Please reload the page.");
      } else {
        this.setState({ stripeData });
      }
    } else {
      this.setState({ error: "Error occurred. Please try again.", isLoading: false });
    }
  }

  _selectedMembershipPlan = async (plan) => {
    this.setState({ membershipPlanSelected: plan });
  }

  render() {
    const {
      error,
      isLoading,
      stripeData,
      showUpdateCreditCardValue,
      couponMsg,
      couponDiscount,
      isCouponLoading
    } = this.state;

    const { currentUser } = this.props.user;
    
    if (!currentUser) return <div className="Billing Container Container--md Container--side-padding"><LoadingCard /></div>;

    const stripeCustomerId = currentUser.attributes["custom:stripeCustomerId"];

    const membership = currentUser.attributes["custom:membership"];
    const billingList = stripeData.charges ? (
      // Only show successful payment history
      stripeData.charges.data.filter((charge) => charge.status === "succeeded").map(billing => (
        <BillingListItem key={billing.id} billingList={billing} />
      ))
    ) : (
      <LoadingCard />
    );

    return (
      <div className="Billing Container Container--md Container--side-padding">
        <h1>Billing</h1>
        <div>
          <h3>My membership</h3>
          <BillingSubscriptionInfo
            stripeCustomer={stripeData}
            stripeCustomerId={stripeCustomerId}
            membership={membership}
            isLoading={isLoading}
            error={error}
            creditCardSubmit={this._creditCardSubmit}
            creaditCardUpdate={this._creaditCardUpdate}
            showUpdateCreditCard={this._showUpdateCreditCard}
            showUpdateCreditCardValue={showUpdateCreditCardValue}
            applyCouponCode={this._applyCouponcode}
            couponMsg={couponMsg}
            couponDiscount={couponDiscount}
            isCouponLoading={isCouponLoading}
            reactivateMembership={this._reactivateMembershipRequest}
            selectedMembershipPlan={this._selectedMembershipPlan}
          />
        </div>

        {stripeCustomerId && 
        <div className="user-stats billing-list">
          <h3>Billing history</h3>
          {billingList}
        </div>}


        {(membership === "annual" || membership === "monthly") && 
          <div>
            <h3>Cancel membership</h3>
            <div>
              <p>
                By cancelling membership, you will lose access to all member's only features at the end of your current membership cycle.
              </p>
              <Link to="/my-account/cancel/" className="cancel-text" id="billing_cancel_membership_button">
                Cancel my membership
              </Link>
            </div>
          </div>
        }

        <div>
          <Footer />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      stripeCreateMember,
      getCurrentUser,
      stripeGetCustomerInfo,
      updateStripeCustomerInfo,
      stripeGetCouponCode,
      stripeCancelMembership
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectStripe(Billing));
