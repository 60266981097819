import React, { Component } from 'react';
import Footer from "./Footer";

class Contact extends Component{
  render() {
    return(
      <div className="contact">
        <div className="Container Container--md Container--side-padding">
          <h1>Contact</h1>
          <p>Please fill out the form below.</p>
          <script src="https://desk.zoho.com/portal/api/feedbackwidget/455054000000129600?orgId=700267853&displayType=iframe"></script>
          <iframe title="Customer service" id="zsfeedbackFrame" width="100%" height="570" name="zsfeedbackFrame" scrolling="no" allowtransparency="false" frameBorder="0" border="0" src="https://desk.zoho.com/support/fbw?formType=AdvancedWebForm&fbwId=e24de31aa9558ae15386cbc68981949af767d9f26ef49d34&xnQsjsdp=BSO-PdItRzSdRhQQlIwaCw$$&mode=showNewWidget&displayType=iframe"></iframe>

          <div>
            <Footer />
          </div>
        </div>
      </div>
    )
  }
}

export default Contact
