import React from 'react';
import { Link } from 'react-router-dom';

const NoMatch = ({ location }) => (
  <React.Fragment>
    <ul className="sub-nav sub-nav-back">
      <li>
        <span>
          <Link to="/" id="noMatch_subNav_go_home"><ion-icon name="arrow-round-back"></ion-icon> Back to home</Link>
        </span>
      </li>
      <li>
      </li>
      <li>
      </li>
    </ul>
    <div className="Container Container--side-padding page-not-found">
      <div>
        <ion-icon name="planet" style={{ fontSize: 120 }}></ion-icon>
        <h1>
          404 Page not found :(
        </h1>
        <p><code>{location ? location.pathname : ''}</code> page does not exist. Wanna go to the <Link to="/">homepage</Link>?</p>
      </div>
    </div>
  </React.Fragment>
);

export default NoMatch
