import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import Masonry from 'masonry-layout';
import PublicJournalListItem from "../components/PublicJournalListItem";
import ProfilePic from "../utils/ProfilePic";
import NoMatch from "../components/NoMatch";
import LoadingCard from "../components/LoadingCard";
import { getAuthorData, getAuthorPublicJournalData, followAuthor, unfollowAuthor, doesSourceFollowTarget, getCurrentUserSession, adjustCounts } from "../actions/user";
import { checkExistingChatRoom } from "../actions/chat"

import moment from "moment";
import abbrNum from "../utils/AbbrNum"
import loadingIcon from "../assets/images/loading-dark.svg";
import community from "../assets/images/community.svg";
import avocado from "../assets/images/avocado.svg";

class Author extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loadingAuthorData: true,
      showLoadingIcon: false,
      authorData: "",
      currentUserData: null,
      publicJournals: {
        items: []
      },
      showFollowBtn: true,
      followingInProgress: false,
      followingId: null,
      booksToRender: 6
    };
  }

  async componentDidMount() {
    const { author_uid } = this.props.match.params;
    const decodedAuthor_uid = decodeURIComponent(author_uid);
    let authenticatedUsername = this.props.user.accountData.idToken.payload[
      "cognito:username"
    ];
    this._isMounted = true;
    this.setState({ loadingAuthorData: true });
    let userData;

    try {
      const followData = await this.props.doesSourceFollowTarget(authenticatedUsername, decodedAuthor_uid)
      const authData = await this.props.getAuthorData(decodedAuthor_uid)
      if (decodedAuthor_uid !== authenticatedUsername) {
        userData = await this.props.getAuthorData(authenticatedUsername)
      }
      const journals = await this.props.getAuthorPublicJournalData(decodedAuthor_uid)
      if (!this._isMounted) return
      this.setState({
        showFollowBtn: !(followData.items.length > 0),
        followingId: followData.items[0] ? followData.items[0].id : null,
        authorData: authData,
        currentUserData: userData || authData,
        loadingAuthorData: false,
        publicJournals: journals
      });
    } catch (err) {
      console.log(err);
      this.setState({ loadingAuthorData: false });
    }
  }

  invokeMasonry() {
    let elem = document.querySelector('.masonry')
    new Masonry( elem, {
      itemSelector: '.journal-card',
      percentPosition: true
    });
  }

  invokeMasonryAgain = () => {
    this.invokeMasonry() 
  }

  _loadMore = async () => {
    const { author_uid } = this.props.match.params;
    const decodedAuthor_uid = decodeURIComponent(author_uid);
    const { publicJournals } = this.state;

    this.setState({ showLoadingIcon: true });
    try {
      const data = await this.props.getAuthorPublicJournalData(
        decodedAuthor_uid,
        publicJournals.nextToken
      );
      this.setState({
        publicJournals: {
          items: [...publicJournals.items, ...data.items],
          nextToken: data.nextToken
        },
        showLoadingIcon: false
      });
      this.invokeMasonry();
    } catch (err) {
      console.log(err);
      this.setState({ showLoadingIcon: false });
    }
  };

  _followAuthor = async () => {
    const { authorData, currentUserData, followingInProgress } = this.state;
    if (followingInProgress) return;

    const stAuthorData = Object.assign({}, authorData)
    authorData.followerCount++;
    this.setState({
      followingInProgress: true,
      showFollowBtn: false,
      authorData
    });
    try {
      let followingObj = await this.props.followAuthor(
        stAuthorData,
        currentUserData
      );
      let response = await this.props.adjustCounts(
        stAuthorData,
        currentUserData,
        1
      );
      this.setState({
        followingInProgress: false,
        followingId: followingObj.id,
        authorData: response.author,
        currentUserData: response.user
      });
      // increment the follower and following counts
    } catch (err) {
      console.log(err);
      this.setState({ followingInProgress: false });
    }
  };

  _unfollowAuthor = async () => {
    const { authorData, currentUserData, followingId, followingInProgress } = this.state;
    if (followingInProgress) return;

    const stAuthorData = Object.assign({}, authorData)
    authorData.followerCount = authorData.followerCount > 0 ? authorData.followerCount - 1 : 0
    this.setState({ 
      followingInProgress: true,
      showFollowBtn: true,
      authorData
    });
    try {
      await this.props.unfollowAuthor(followingId);
      let response = await this.props.adjustCounts(
        stAuthorData,
        currentUserData,
        -1
      );
      this.setState({
        followingInProgress: false,
        authorData: response.author,
        currentUserData: response.user
      });
    } catch (err) {
      console.log(err);
      this.setState({ followingInProgress: false });
    }
  };

  _renderFollowingBtn = () => {
    const { authorData, showFollowBtn, followingInProgress } = this.state;
    const { accountData } = this.props.user
    if (accountData && accountData.idToken.payload["cognito:username"] === authorData.id) return null
    if (showFollowBtn) {
      return (
        <button
          className="btn-secondary follow"
          onClick={this._followAuthor}
          disabled={followingInProgress}
          id="author_follow_button"
        >
          Follow
        </button>
      )
    }
    return (
      <button
        className="btn-secondary following"
        onClick={this._unfollowAuthor}
        disabled={followingInProgress}
        id="author_unfollow_button"
      >
        <div>
          <ion-icon name="checkmark"></ion-icon> Following
        </div>
    </button>
    )
  };

  checkExistingChatRooms = async (allUsersToChat) => {
    const currentUser = this.props.user.accountData.idToken.payload["cognito:username"]

    // TODO: Group Chat in the future //allUsersToChat can contain multiple usernames as String for group chat
    const sortedMembersToChat = (allUsersToChat.id + ", " + currentUser).split(", ").sort().join(", "); // members must be sorted alphabetically
    const sortedMembersDisplayNameToChat = ((allUsersToChat.displayName ? allUsersToChat.displayName : allUsersToChat.id) + ", " + currentUser).split(", ").sort().join(", ");
    try{
      const existingChatRooms = await this.props.checkExistingChatRoom(currentUser, sortedMembersToChat) 

      if(existingChatRooms.items.length === 0 ) {
        // console.log("Redirect to /chat/new")
        this.props.history.push({
          pathname: '/chat/new',
          state: { 
            chatWith: sortedMembersToChat,
            chatWithDisplayname: sortedMembersDisplayNameToChat
          }
        })
      } else {
        // console.log("Redirect to existing chat room", existingChatRooms.items[0].chatRoomUserChatRoomId)
        this.props.history.push("/chat/" + existingChatRooms.items[0].chatRoomUserChatRoomId)
      }
      
    } catch(err) {
      console.log(err)
    }
  }

  _renderMessageBtn = () => {
    const { authorData } = this.state;
    const { accountData } = this.props.user
    if (accountData && accountData.idToken.payload["cognito:username"] === authorData.id) return null
    // console.log(authorData)
    return (
      <button
        className="btn-secondary follow message"
        onClick={() => this.checkExistingChatRooms(authorData)}
        id="author_message_button"
      >
        <div>
            Message
        </div>
    </button>
    )
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  _loadMoreBook = async () => {
    const { authorData, booksToRender } = this.state
    const totalJournal = authorData && authorData.books.items.length
    if(booksToRender >= totalJournal){
      this.setState({ booksToRender: totalJournal })
    } else {
      this.setState({ booksToRender: booksToRender + 6 })
    }
  }

  render() {
    const { showLoadingIcon, authorData, loadingAuthorData, publicJournals, booksToRender } = this.state;
    
    if (!authorData && !loadingAuthorData) {
      return <NoMatch />;
    }

    const newArray = authorData && authorData.books.items.length > 0 && authorData.books.items.slice(0, booksToRender)

    const listOfBooks = authorData && authorData.books.items.length > 0 ? 
    newArray.map(book => {
    return <div className={book.bookCoverImg ? "" : "default-book"} key={book.id} style={{ backgroundImage: 'url(' + book.bookCoverImg + ')' }} onClick={() => this.props.history.push('/book/' + book.id)} id={"book_clicked_" + book.id}><span>{book.book} ({book.bookJournalCount ? book.bookJournalCount : 0})</span></div> 
    })
  : 
    <div className="outline-book"><span><ion-icon name="planet" style={{ fontSize: 32 }}></ion-icon></span><span>No book created yet</span></div>

    return (
      <div className="my-account author-page">
        <ul className="sub-nav sub-nav-back">
          <li>
            <span className="link-text trackit" onClick={() => this.props.history.goBack()} id="author_subNav_go_back">
              <ion-icon name="arrow-round-back"></ion-icon> Back
            </span>
          </li>
          <li>
          </li>
          <li>
          </li>
        </ul>

        {loadingAuthorData ?
          <div className="Container Container--side-padding">
            <LoadingCard />
          </div>
        :
          <React.Fragment>
            <div className="Container Container--side-padding">
              <div className="user-profile author-profile">
                <div className="user-profile-pic">
                  <img
                    src={ProfilePic(authorData)}
                    className="profile-pic profile-pic--large"
                    alt="user profile pic"
                  />
                </div>
                <div>
                  <h3>
                    {authorData.displayName || authorData.id}{" "}
                  </h3>
                  <time>
                    Member since{" "}
                    {authorData && moment(authorData.createdAt).format("MMM DD, YYYY")}
                  </time>
                  <div className="user-author-actions">
                    {this._renderFollowingBtn()} {this._renderMessageBtn()}
                  </div>
                </div>

                <div className="user-stats">
                  <div>
                    <img
                      src={avocado}
                      style={{ width: 28, verticalAlign: 'middle' }}
                      alt="Avocado"
                    /> {authorData && abbrNum(Math.floor(authorData.totalJournal/30), 2)} Avocado{authorData && Math.floor(authorData.totalJournal/30) > 1 ? "s" : ""}
                  </div>
                  <div>
                    <ion-icon name="bookmarks"></ion-icon>{" "}
                    {(authorData && abbrNum(authorData.totalJournal, 2)) || 0}{" "}
                    Journal{authorData && authorData.totalJournal > 1 ? "s" : ""}
                  </div>
                  <div>
                    <ion-icon name="contacts"></ion-icon>{" "}
                    <Link to={`/follower/` + (authorData && encodeURIComponent(authorData.id))} id="author_follower">{" "}
                      {(authorData && abbrNum(authorData.followerCount, 2)) || 0}{" "}
                      Follower{authorData.followerCount > 1 ? "s" : ""}
                    </Link>
                  </div>
                  <div>
                    <ion-icon name="contacts"></ion-icon>{" "}
                    <Link to={`/following/` + (authorData && encodeURIComponent(authorData.id))} id="author_following">{" "}
                      {(authorData && abbrNum(authorData.followingCount, 2)) || 0}{" "}
                      Following{authorData.followingCount > 1 ? "s" : ""}
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="Container">
              <h2 className="Container--side-padding">{authorData.displayName ? authorData.displayName : authorData.id}'s books</h2>
              <div className="book-list" style={{ marginTop: 16 }}>
                {listOfBooks}
                {authorData && authorData.books.items.length > booksToRender && <div className="outline-book" onClick={this._loadMoreBook}><span>Load more</span></div>}
              </div>
            </div>

            <div className="journal-list my-public-journals animate-fade-in-up">
              <div className="Container">
                <h2 className="Container Container--side-padding" style={{ marginBottom: 8 }}>{authorData.displayName ? authorData.displayName : authorData.id}'s public journals</h2>
                {publicJournals.items.length === 0 && (
                  <div className="Container Container--sm Container--side-padding" style={{ textAlign: 'center', paddingTop: 60 }}>
                    <img src={community} alt="No public journals from this author" />
                    <h2>No public journals yet</h2>
                  </div>
                )}

                {publicJournals.items.length > 0 &&
                  <div className="masonry" onLoad={this.invokeMasonry}>
                    {publicJournals.items.map(journal => (
                      <PublicJournalListItem key={journal.id} journal={journal} imageLoaded={this.invokeMasonryAgain} />
                    ))}
                  </div>
                }

                {publicJournals.nextToken && (
                  <div className="load-more">
                    {showLoadingIcon ? (
                      <img
                        alt="Loading journals"
                        className="loading-icon"
                        src={loadingIcon}
                      />
                    ) : (
                      <button className="btn-secondary" onClick={this._loadMore} id="author_load_more">
                        Load more
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
          </React.Fragment>
        }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getAuthorData,
      getAuthorPublicJournalData,
      getCurrentUserSession,
      followAuthor,
      unfollowAuthor,
      doesSourceFollowTarget,
      adjustCounts,
      checkExistingChatRoom
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Author);
