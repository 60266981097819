import React, { Component } from 'react'
import moment from 'moment'

let weeklyChallengeCompleted = false

class WeeklyJournalChallenge extends Component {
  getWeeks = () => {
    const { user } = this.props
    function getMonday(d) {
      d = new Date(d)
      let day = d.getDay(),
        diff = d.getDate() - day + (day === 0 ? -6 : 1) // adjust when day is sunday
      return new Date(d.setDate(diff))
    }
    const weekStart = getMonday(new Date())

    let days = {}
    for (let i = 0; i <= 6; i++) {
      days[moment(weekStart).add(i, 'days').format('YYYY-MM-DD')] = moment(
        weekStart
      )
        .add(i, 'days')
        .format('ddd')
    }

    const userJournalThisMonth =
      user.userJournal[0] && user.userJournal[0].items.length > 0
        ? user.userJournal[0].items.filter(
            (m) =>
              m.month === moment(new Date()).format('MMM YYYY') ||
              m.month ===
                moment(new Date())
                  .subtract(1, 'months')
                  .endOf('month')
                  .format('MMM YYYY')
          )
        : null

    const userWeeklyJournalCheck =
      userJournalThisMonth &&
      userJournalThisMonth.map((mj) => {
        let journalDays = {}
        const len = mj.data.length
        for (let i = 0; i < len; i++) {
          if (days[moment(mj.data[i].createdAt).format('YYYY-MM-DD')])
            journalDays[
              moment(mj.data[i].createdAt).format('YYYY-MM-DD')
            ] = true
        }
        return journalDays
      })

    if (
      userWeeklyJournalCheck &&
      userWeeklyJournalCheck.length > 0 &&
      Object.keys(userWeeklyJournalCheck[0]).length +
        (userWeeklyJournalCheck[1]
          ? Object.keys(userWeeklyJournalCheck[1]).length
          : 0) >=
        7
    ) {
      weeklyChallengeCompleted = true
    } else {
      weeklyChallengeCompleted = false
    }

    const renderDays = Object.keys(days).map((d, i) => {
      return (
        <li
          className={
            userWeeklyJournalCheck &&
            userWeeklyJournalCheck.length > 0 &&
            (userWeeklyJournalCheck[0][d] ||
              (userWeeklyJournalCheck[1] && userWeeklyJournalCheck[1][d]))
              ? 'completed'
              : 'incompleted'
          }
          key={i}
        >
          {days[d].charAt(0)}
        </li>
      )
    })

    return renderDays
  }

  render() {
    return (
      <div>
        <code>
          <b>Progress this week</b> <br />
          {weeklyChallengeCompleted && (
            <span
              style={{
                color: '#3bc183',
                fontWeight: 'bold',
                marginTop: 8,
                display: 'inline-block',
              }}
            >
              Great job! Week completed!
            </span>
          )}
        </code>
        <ul className="challenge-weelky">{this.getWeeks()}</ul>
      </div>
    )
  }
}

export default WeeklyJournalChallenge
