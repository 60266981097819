import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import QuillEditor from '../components/QuillEditor'
import UpgradePopup from '../components/UpgradePopup'
import DateTimePicker from '../utils/DateTimePicker'
import isPaidUser from '../utils/isPaidUser'

import {
  saveJournal,
  updateJournal,
  adjustBookJournalsCount,
} from '../actions/journal'

import ReactGA from 'react-ga'

class WriteJournal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      privacy: 'PRIVATE',
      journalUid: '',
      journalAuthorId:
        props.user.accountData.idToken.payload['cognito:username'],
      journalContent: '',
      journalWordCount: 0,
      autoSaved: false,
      showUpgrade: false,
      journalBookId: '',
      journalPromptCheck: false,
      journalCreatedAt: new Date(),
      upgradePopUpMessage: {
        featureName: 'Publishing a public journal',
        ctaText: 'Publish it as a private journal',
      },
      isSavingJournal: false,
    }
  }

  async componentDidMount() {
    const params = new URLSearchParams(this.props.history.location.search)
    const bookSelected = params.get('bookSelected')
    const journalPrompt = params.get('journalPrompt')

    bookSelected && this.setState({ journalBookId: bookSelected })
    journalPrompt &&
      this.setState({
        journalContent:
          '<h1><span style="color: rgb(60, 206, 102);">' +
          journalPrompt +
          '</span></h1>',
      })

    this.setState({ journalPromptCheck: true })
  }

  _selectPrivacy = async (e) => {
    this.setState({
      privacy: e.target.value,
    })
  }

  _handleChange = async (textPassed, wordCount) => {
    this.setState({
      journalContent: textPassed,
      journalWordCount: wordCount,
    })
    const {
      privacy,
      journalUid,
      autoSaved,
      journalContent,
      journalAuthorId,
      journalWordCount,
      journalBookId,
      journalCreatedAt,
    } = this.state
    const { user } = this.props

    let journalPrivacy = privacy
    if (!isPaidUser(user.currentUser.attributes['custom:membership'])) {
      journalPrivacy = 'PRIVATE'
    }
    if (journalContent.length < 10) return
    if (autoSaved) {
      // if non-paid user, disable autosave and shows the warning message for the first time only
      if (!isPaidUser(user.currentUser.attributes['custom:membership'])) {
        if (localStorage.getItem('auto-save-for-paid-users') === 'true') {
          return
        } else {
          this.setState({
            showUpgrade: true,
            upgradePopUpMessage: {
              featureName: 'Autosave',
              ctaText: 'Close',
            },
          })
          localStorage.setItem('auto-save-for-paid-users', 'true')
          return
        }
      }
      try {
        await this.props.updateJournal({
          id: journalUid,
          journalAuthorId: journalAuthorId,
          privacy: journalPrivacy,
          draft: true,
          content: journalContent,
          journalWordCount: journalWordCount,
          journalBookId: journalBookId,
        })
      } catch (err) {
        console.log(err)
      }
    } else {
      this.setState({ autoSaved: true })
      try {
        await this.props.saveJournal({
          journalAuthorId: journalAuthorId,
          privacy: privacy,
          draft: true,
          content: journalContent,
          journalWordCount: journalWordCount,
          createdAt: journalCreatedAt.toISOString(),
        })
        const { journal } = this.props
        this.setState({
          journalUid: journal.currentJournal.id,
        })
        // Replacing URL with the newly created journal ID so it won't mess around with other journals when auto save is triggered
        // There was a bug when going back by clicking go back button on web browser that sometimes replace the current journal with previous journal
        window.history.replaceState(
          null,
          ``,
          `/edit/${journal.currentJournal.id}`
        )
      } catch (err) {
        console.log(err)
        this.setState({ autoSaved: false })
      }
    }
  }

  _publishJournal = async () => {
    const { privacy } = this.state
    const { user } = this.props
    if (
      privacy === 'PUBLIC' &&
      !isPaidUser(user.currentUser.attributes['custom:membership'])
    ) {
      this.setState({
        showUpgrade: true,
        upgradePopUpMessage: {
          featureName: 'Publishing a public journal',
          ctaText: 'Publish it as a private journal',
        },
      })
    } else {
      this._updateJournal(privacy)
      // Event tracking.
      ReactGA.event({
        category: this.state.journalAuthorId,
        action: 'Writing',
        label: privacy,
      })
    }
  }

  _popupControl = async (callback) => {
    this.setState({
      showUpgrade: callback,
    })
  }

  _updateJournal = async (privacy) => {
    const {
      journalUid,
      journalAuthorId,
      journalContent,
      journalWordCount,
      journalBookId,
      journalCreatedAt,
    } = this.state
    this.setState({ isSavingJournal: true })
    if (journalUid) {
      try {
        await this.props.updateJournal({
          id: journalUid,
          privacy: privacy,
          journalAuthorId: journalAuthorId,
          draft: false,
          content: journalContent,
          journalWordCount: journalWordCount,
          journalBookId: journalBookId,
          createdAt: journalCreatedAt.toISOString(),
        })
        // Update book bookJournalCount number if journalBookId exist
        if (journalBookId && journalBookId !== null) {
          await this.props.adjustBookJournalsCount(journalBookId, 1)
        }
        this.props.history.push({
          pathname:
            (privacy === 'PUBLIC' ? '/public-journal/' : '/journal/') +
            journalUid,
          state: { prevPath: this.props.location.pathname },
        })
      } catch (err) {
        console.log(err)
        this.setState({ isSavingJournal: false })
      }
    }
  }

  _selectBook = async (e) => {
    this.setState({
      journalBookId: e.target.value,
    })
  }

  _newJournalCreatedAt = (date) => {
    const { journalCreatedAt } = this.state
    const { user } = this.props
    if (!isPaidUser(user.currentUser.attributes['custom:membership'])) {
      this.setState({
        showUpgrade: true,
        journalCreatedAt: journalCreatedAt,
        upgradePopUpMessage: {
          featureName: 'Changing date and time',
          ctaText: 'Publish it with current date and time',
        },
      })
    } else {
      this.setState({
        journalCreatedAt: date,
      })
    }
  }

  render() {
    const {
      privacy,
      showUpgrade,
      journalBookId,
      journalContent,
      journalPromptCheck,
      upgradePopUpMessage,
      journalCreatedAt,
      isSavingJournal,
    } = this.state
    const { journal, user } = this.props

    const bookList =
      user && user.userBooks.length > 0 ? (
        <select
          className="select-new book-select"
          onChange={this._selectBook}
          value={journalBookId}
          id="writeJournal_book_select"
        >
          <option value="">No book</option>
          {user.userBooks.map((book) => {
            return (
              <option key={book.id} value={book.id}>
                {book.book}
              </option>
            )
          })}
        </select>
      ) : (
        <select className="select-new book-select">
          <option value="">No book</option>
        </select>
      )

    return (
      <div className="write-journal">
        <ul className="sub-nav">
          <li>
            <span
              onClick={() => this.props.history.goBack()}
              className="link-text"
              id="writeJournal_go_back"
            >
              <ion-icon name="arrow-round-back"></ion-icon> Back
            </span>
          </li>
          <li>{bookList}</li>
          <li>
            <button
              onClick={this._publishJournal}
              className="link-btn"
              id="writeJournal_publish_button"
              disabled={
                journal.pendingCreate ||
                journal.pendingUpdate ||
                isSavingJournal
                  ? true
                  : false
              }
            >
              {journal.pendingCreate || journal.pendingUpdate || isSavingJournal
                ? 'Saving...'
                : 'Publish'}
            </button>
          </li>
        </ul>
        <div className="journal-option">
          <DateTimePicker
            setDate={journalCreatedAt}
            newDate={this._newJournalCreatedAt}
          />
          <select
            className="select-new"
            onChange={this._selectPrivacy}
            value={privacy}
            id="writeJournal_privacy_select"
          >
            <option value="PRIVATE">Private</option>
            <option value="PUBLIC">Public</option>
          </select>
        </div>
        <div className="Container write-editor">
          {journalPromptCheck && (
            <QuillEditor
              value={journalContent}
              textCallback={this._handleChange}
            />
          )}
        </div>

        <UpgradePopup
          featureName={upgradePopUpMessage.featureName}
          ctaText={upgradePopUpMessage.ctaText}
          showUpgrade={showUpgrade}
          popupControl={this._popupControl}
          publishPrivate={this._updateJournal}
        />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return state
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      saveJournal,
      updateJournal,
      adjustBookJournalsCount,
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(WriteJournal)
