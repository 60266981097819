import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import profilePic from '../assets/images/profile-pic.svg'

class MemberListItem extends Component{
  render() {
    const { member } = this.props

    return(
      <div className="journal-list-item member-list-item challenge-card">
        <div className="author">
          <Link to={"/author/" + encodeURIComponent(member.user)} id={"challenge_member_" + member.user}><img src={member.userProfileImage ? member.userProfileImage : profilePic} className="profile-pic" alt="User profile pic" /></Link> 

          <div className="challenge-card-info">
            <code><Link to={"/author/" + encodeURIComponent(member.user)} id={"challenge_member_" + member.user}><b>{member.userDisplayName ? member.userDisplayName : member.user}</b></Link></code>
            <br />
            <b>{member.journalsPerMonth}</b> journal{member.journalsPerMonth === 1 ? "" : "s"}<i className="word-count">{member.journalWordCountPerMonth ? " " + member.journalWordCountPerMonth.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (member.journalWordCountPerMonth === 1 ? " word" : " words") : ""}</i>
          </div>
        </div>
      </div>
    )
  }
}

export default MemberListItem
