import React, { Component } from 'react'
// import { Link } from "react-router-dom";
import { withRouter } from 'react-router'
import ReactModal from 'react-modal'
import membersOnly from '../assets/images/members-only.svg'

ReactModal.setAppElement('body')

class UpgradePopup extends Component {
  _closePopup = async () => {
    const { popupControl, publishPrivate, ctaText } = this.props
    if (publishPrivate && ctaText !== 'Close') publishPrivate('PRIVATE')
    popupControl(false)
  }

  _closeModal = async () => {
    const { popupControl } = this.props
    popupControl(false)
  }

  _becomeAMember = async () => {
    const { popupControl } = this.props
    // TODO: Arman, maybe you can make this better? I want to save it as Private journal(it's better if it's draft too) before take user to the Billing page
    this.props.history.push({ pathname: '/my-account/billing' })
    popupControl(false)
  }

  render() {
    const { featureName, showUpgrade, ctaText } = this.props

    return (
      <div>
        <ReactModal
          isOpen={showUpgrade}
          contentLabel="Member's only feature"
          onRequestClose={this._closeModal}
          style={{ overlay: { background: 'rgba(0, 0, 0, 0.7' } }}
          className="modal-box"
        >
          <img
            src={membersOnly}
            alt="Become a member!"
            style={{ maxHeight: '180px' }}
          />
          <h1>{featureName} is member's only feature</h1>
          <p>
            Your membership helps us out a lot! Support the community by
            becoming a member and get full access to all features.
          </p>
          {/* TODO: Save the journal as a private then take user to the Billing page */}
          <p>
            <button onClick={this._becomeAMember} id="Upgrade to member">
              Become a member now
            </button>
          </p>{' '}
          {/* TODO: Save the journal as a private */}
          {ctaText && (
            <p>
              <small>
                <button
                  className="link-btn"
                  onClick={this._closePopup}
                  id="Upgrade close popup"
                >
                  {ctaText}
                </button>
              </small>
            </p>
          )}{' '}
        </ReactModal>
      </div>
    )
  }
}

export default withRouter(UpgradePopup)
