import Amplify, { API, graphqlOperation } from 'aws-amplify';
import aws_exports from '../aws-exports';
import * as custom_queries from '../customgraphql/queries';
import * as custom_mutations from '../customgraphql/mutations';
import * as subscriptions from '../graphql/subscriptions'
import { createChatRoomCreatedNotification } from './notification';

Amplify.configure(aws_exports);

// Chat message subscription
// -----------------------------------------------------------------------------
export function chatCreateMessageSubscriptions(callback, stop) {
  return async (dispatch, getState) => {
    const chatCreateSubscription = await API.graphql(graphqlOperation(subscriptions.onCreateMessage)).subscribe({
      next: (newChatData) => {
        callback(newChatData.value.data.onCreateMessage)
        if(getState().user.userData.chatRoomUser.items.map((r) => { return r.chatRoom.id }).indexOf(newChatData.value.data.onCreateMessage.chatRoom.id) > -1){
          getState().user.userData.chatRoomUser.items[getState().user.userData.chatRoomUser.items.map((r) => { return r.chatRoom.id }).indexOf(newChatData.value.data.onCreateMessage.chatRoom.id)].chatRoom.messages.items.unshift(newChatData.value.data.onCreateMessage)
          dispatch({ type: "GET_USER_DATA", userData: getState().user.userData })
        }
      }
    })
    return stop ? chatCreateSubscription.unsubscribe() : chatCreateSubscription // TODO: double check unsubscribe(). Don't think it's working now
  }
}

export function chatDeleteMessageSubscriptions(callback, stop) {
  return async () => {
    const chatDeleteSubscription = await API.graphql(graphqlOperation(subscriptions.onDeleteMessage)).subscribe({
      next: (newChatData) => {
        callback(newChatData.value.data.onDeleteMessage)
      }
    })
    return stop ? chatDeleteSubscription.unsubscribe() : chatDeleteSubscription // TODO: double check unsubscribe(). Don't think it's working now
  }
}

// Check existing chat rooms by user
// -----------------------------------------------------------------------------
export function checkExistingChatRoom(username, members) {
  return async () => {
    try {
      const data = await API.graphql(
        graphqlOperation(custom_queries.doesChatRoomExist, { 
          chatRoomUserUserId: username, 
          members: { eq: members } })
      );
      return data.data.doesChatRoomExist;
    } catch (err) {
      console.log("error checkExistingChatRoom: ", err);
    }
  };
}

// Create chat room
// -----------------------------------------------------------------------------
export function createChatRoom(username) {
  return async () => {
    try {
      const data = await API.graphql(
        graphqlOperation(custom_mutations.createChatRoom, { input: { 
          createdBy: username,
          receiverHasRead: false
        }})
      );
      return data.data.createChatRoom;
    } catch (err) {
      console.log("error create chat room: ", err);
    }
  };
}

// Create message for chat
// -----------------------------------------------------------------------------
export function createChatMessage(username, chatRoomId, content) {
  return async () => {
    try {
      const data = await API.graphql(
        graphqlOperation(custom_mutations.createMessage, { input: { 
          messageUserId: username,
          messageChatRoomId: chatRoomId, 
          content: content
        }})
      );
      return data.data.createMessage;
    } catch (err) {
      console.log("error createChatMessage: ", err);
    }
  };
}

// Delete message for chat
// -----------------------------------------------------------------------------
export function deleteChatMessage(chatMessageId) {
  return async () => {
    try {
      const data = await API.graphql(
        graphqlOperation(custom_mutations.deleteMessage, { input: { 
          id: chatMessageId
        }})
      );
      return data.data.deleteMessage;
    } catch (err) {
      console.log("error deleteChatMessage: ", err);
    }
  };
}

// Create Chat Room Users - 3rd table that allows group chat.
// -----------------------------------------------------------------------------
export function createChatRoomUser(username, chatRoomId, members) {
  return async (dispatch, getState) => {
    try {
      const data = await API.graphql(
        graphqlOperation(custom_mutations.createChatRoomUser, { input: { 
          chatRoomUserUserId: username,
          chatRoomUserChatRoomId: chatRoomId, 
          members: members
        }})
      );

      // Send notification to users in the chat room except for the creator.
      if(username !== getState().user.userData.id){
        createChatRoomCreatedNotification(username, chatRoomId, getState().user.userData)
      }
      return data.data.createChatRoomUser;
    } catch (err) {
      console.log("error createChatRoomUser: ", err);
    }
  };
}

// Remove Chat Room Users - User leaves a chat room
// -----------------------------------------------------------------------------
export function deleteChatRoomUser(chatRoomId, username) {
  return async (dispatch, getState) => {
    try {
      const getChatRoomUserId = await API.graphql(
        graphqlOperation(custom_queries.chatRoomUserChatRoomIdAsKey, {
          chatRoomUserChatRoomId: chatRoomId, 
          chatRoomUserUserId: {eq: username }
        })
      )

      if(getChatRoomUserId.data.chatRoomUserChatRoomIdAsKey.items.length > 0) {
        const data = await API.graphql(
          graphqlOperation(custom_mutations.deleteChatRoomUser, { input: { 
            id: getChatRoomUserId.data.chatRoomUserChatRoomIdAsKey.items[0].id
          }})
        );
        getState().user.userData.chatRoomUser.items = getState().user.userData.chatRoomUser.items.filter( cr => cr.id !== getChatRoomUserId.data.chatRoomUserChatRoomIdAsKey.items[0].id)
        dispatch({ type: "GET_USER_DATA", userData: getState().user.userData })
        return data.data.deleteChatRoomUser;
      } else {
        console.log("Can't remove chat room user data.")
        return null
      }

    } catch (err) {
      console.log("error deleteChatRoomUser: ", err);
    }
  };
}


// Update Chat Room Users - to update updatedAt when there's new message in a chat room.
// -----------------------------------------------------------------------------
export function updateChatRoomUser(chatRoomId, updatedTime) {
  return async () => {
    try {
      const data = await API.graphql(
        graphqlOperation(custom_mutations.updateChatRoomUser, { input: { 
          id: chatRoomId,
          updatedAt: updatedTime
        }})
      );
      return data.data.updateChatRoomUser;
    } catch (err) {
      console.log("error updateChatRoomUser: ", err);
    }
  };
}


// Get ChatRoomUser by chat room ID
// -----------------------------------------------------------------------------
export function getChatRoomUsersByChatRoomId(chatRoomId) {
  return async () => {
    try {
      const data = await API.graphql(
        graphqlOperation(custom_queries.chatRoomUserChatRoomIdAsKey, {
          chatRoomUserChatRoomId: chatRoomId
        })
      )
      return data.data.chatRoomUserChatRoomIdAsKey;
    } catch (err) {
      console.log("error getting getChatRoomUsersByChatRoomId: ", err);
    }
  };
}

// Get chat room data
// -----------------------------------------------------------------------------
export function getChatRoomData(chatRoomId, limit, token) {
  return async () => {
    try {
      const data = await API.graphql(
        graphqlOperation(custom_queries.getChatRoom, {
          id: chatRoomId,
          limit: limit,
          nextTokenMessage: token
        })
      )
      return data.data.getChatRoom;
    } catch (err) {
      console.log("error getting getChatRoomData: ", err);
    }
  };
}

// Update receiverHasRead in ChatRoom 
// -----------------------------------------------------------------------------
export function updateChatRoomRead(chatRoomId, read) {
  return async (dispatch, getState) => {
    try {
      const data = await API.graphql(
        graphqlOperation(custom_mutations.updateChatRoom, { input: { 
          id: chatRoomId, 
          receiverHasRead: read
        }})
      );

      // Update receiverHasRead value in local redux for chat room list
      if(read){
        getState().user.userData.chatRoomUser.items[getState().user.userData.chatRoomUser.items.map((r) => { return r.chatRoom.id }).indexOf(data.data.updateChatRoom.id)].chatRoom.receiverHasRead = data.data.updateChatRoom.receiverHasRead
        dispatch({ type: "GET_USER_DATA", userData: getState().user.userData })
      }
      return data.data.updateChatRoom;
    } catch (err) {
      console.log("error updateChatRoom: ", err);
    }
  };
}

// Get More ChatRoomUsers
// -----------------------------------------------------------------------------
export function getMoreChatRoomUsers(username, nextTokenChatRoomUser) {
  return async (dispatch, getState) => {
    try { 
      const data = await API.graphql(graphqlOperation(custom_queries.getUser, {id: username, nextTokenChatRoomUser: nextTokenChatRoomUser}))
      getState().user.userData.chatRoomUser.items = [...getState().user.userData.chatRoomUser.items, ...data.data.getUser.chatRoomUser.items]
      getState().user.userData.chatRoomUser.nextToken = data.data.getUser.chatRoomUser.nextToken
      dispatch({ type: "GET_USER_DATA", userData: getState().user.userData })
    } catch(err) {
      console.log('error getMoreChatRoomUsers: ', err)
    }
  }
}

