import Amplify, { Storage, Auth, API, graphqlOperation } from 'aws-amplify'
import aws_exports from '../aws-exports'
import * as custom_queries from '../customgraphql/queries'
import * as custom_mutations from '../customgraphql/mutations'
import {
  createNotificationFromFollow,
  createNotificationThirtyJournal,
} from './notification'
import { reorderBooks } from '../utils/reorderBooks'

import moment from 'moment'

Amplify.configure(aws_exports)

const { aws_user_files_s3_bucket: bucket } = aws_exports

function fetchingCurrentUserSession(
  isFetchingCurrentUserSession,
  isAuthenticated
) {
  return {
    type: 'SET_CURRENT_USER_SESSION',
    isFetchingCurrentUserSession,
    isAuthenticated,
  }
}

function setLoginSuccess(isLoginSuccess, accountData) {
  return {
    type: 'SET_LOGIN_SUCCESS',
    isLoginSuccess,
    accountData,
  }
}

// Get current User session
// -----------------------------------------------------------------------------
export function getCurrentUserSession() {
  return (dispatch) => {
    dispatch(fetchingCurrentUserSession(true, false))
    dispatch(setLoginSuccess(false, null))

    return new Promise((resolve, reject) => {
      Auth.currentSession()
        .then((user) => {
          dispatch(setLoginSuccess(true, user))
          dispatch(
            getUserData(
              user.idToken.payload['cognito:username'],
              null,
              user.idToken.payload.sub
            )
          )
          dispatch(fetchingCurrentUserSession(false, true))
          dispatch(getCurrentUser())
          return resolve(user)
        })
        .catch((err) => {
          console.log(err)
          dispatch(fetchingCurrentUserSession(false, false))
          return reject(err)
        })
    })
  }
}

// User Log in
// -----------------------------------------------------------------------------
export const userLoginRequest = (username, password) => {
  return (dispatch) => {
    dispatch(setLoginSuccess(false, null))
    dispatch({ type: 'SET_LOGIN_STATUS', loginError: null, loginLoading: true })

    callLoginAPI(username, password, (res) => {
      if (res instanceof Error) {
        dispatch({
          type: 'SET_LOGIN_STATUS',
          loginError: res,
          loginLoading: false,
        })
      } else {
        dispatch(setLoginSuccess(true, res))
        dispatch({
          type: 'SET_LOGIN_STATUS',
          loginError: null,
          loginLoading: false,
        })
        dispatch(
          getUserData(
            res.idToken.payload['cognito:username'],
            null,
            res.idToken.payload.sub
          )
        )
        dispatch(fetchingCurrentUserSession(false, true))
        dispatch(getCurrentUser())
      }
    })
  }
}

// Reset Log in error
// -----------------------------------------------------------------------------
export const resetLoginError = () => {
  return (dispatch) => {
    dispatch({ type: 'RESET_LOAGIN_ERROR' })
  }
}

// User Login HTTP request
// -----------------------------------------------------------------------------
function callLoginAPI(username, password, callback) {
  Auth.signIn(username, password)
    .then(async (user) => {
      // Tell React Native app that user successfully logged-in to the app and send username
      window.ReactNativeWebView &&
        window.ReactNativeWebView.postMessage(
          JSON.stringify({ pushNotificationUsername: user.username })
        )
      return callback(user.signInUserSession)
    })
    .catch((err) => {
      return callback(new Error(err.message))
    })
}

// Create User data in DynamoDB on user signin for the first time
// -----------------------------------------------------------------------------
export function createUserData(username, uuid) {
  return async (dispatch) => {
    try {
      let user = await API.graphql(
        graphqlOperation(custom_mutations.createUser, {
          input: { id: username, uuid: uuid },
        })
      )
      // TODO: createUser error messege cause uuid passed in as undefined for the second time
      dispatch(
        getUserData(user.data.createUser.id, null, user.data.createUser.id)
      )
    } catch (err) {
      console.log('error creating user data: ', err)
    }
  }
}

// Get current user
// -----------------------------------------------------------------------------
export function getCurrentUser() {
  return async (dispatch) => {
    try {
      let user = await Auth.currentUserInfo()
      dispatch({ type: 'GET_CURRENT_USER', currentUser: user })
    } catch (err) {
      console.log('error creating user data: ', err)
    }
  }
}

// Update Profile Picture
// -----------------------------------------------------------------------------
export function updateProfilePic(id, file) {
  return async (dispatch) => {
    let user = await Auth.currentAuthenticatedUser()
    let userId = user.signInUserSession.idToken.payload['cognito:username']
    const extension = /^.+\.([^.]+)$/.exec(file.name)[1]
    const { type: mimeType } = file

    const dataToURL = new Date()
      .toJSON()
      .slice(0, 23)
      .replace(/[^0-9-]/g, '-')
    const filePath = `profile/${id}-${dataToURL}.${extension}`

    const url = `https://${bucket}.s3.amazonaws.com/public/profile/${encodeURIComponent(
      id
    )}-${dataToURL}.${extension}`

    try {
      // Uploading to S3 bucket
      var options = {
        ACL: 'public-read',
        contentType: mimeType,
        level: 'public',
      }
      await Storage.put(filePath, file, options)

      // Adding URL to AWS Cognito
      let params = {}
      params['picture'] = url
      let result = await Auth.updateUserAttributes(user, params)
      await API.graphql(
        graphqlOperation(custom_mutations.updateUser, {
          input: { id: userId, profileImage: url },
        })
      )
      dispatch(getCurrentUser())
      return result
    } catch (err) {
      console.log('error updating profile picture: ', err)
    }
  }
}

// Update Article Pictures
// -----------------------------------------------------------------------------
export function uploadToS3(file, id) {
  return async (dispatch) => {
    let user = await Auth.currentAuthenticatedUser()
    let userId = user.signInUserSession.idToken.payload['cognito:username']
    const extension = /^.+\.([^.]+)$/.exec(file.name)[1]
    const { type: mimeType } = file

    const dataToURL = new Date().toJSON().slice(0, 10).replace(/-/g, '/')
    const filePath = `${dataToURL}/${userId}-article-${id}.${extension}`

    const url = `https://${bucket}.s3.amazonaws.com/public/${dataToURL}/${encodeURIComponent(
      userId
    )}-article-${id}.${extension}`

    try {
      // Uploading to S3 bucket
      var options = {
        ACL: 'public-read',
        contentType: mimeType,
        level: 'public',
      }
      await Storage.put(filePath, file, options)

      return url
    } catch (err) {
      console.log('error uploading article picture: ', err)
    }
  }
}

// Get User Data
// -----------------------------------------------------------------------------
export function getUserData(username, limit, uuid) {
  return async (dispatch) => {
    try {
      dispatch({ type: 'SET_FETCH_USER_DATA_SESSION', isFetchUserData: true })
      const limitJournal = limit || 60
      const data = await API.graphql(
        graphqlOperation(custom_queries.getUser, {
          id: username,
          limit: limitJournal,
        })
      )
      // console.log(data.data.getUser)

      if (!data.data.getUser) {
        if (!uuid) return
        dispatch(createUserData(username, uuid))
      } else {
        // This is main base data for logged in user and we are modifying;
        // User journals to group by month
        // and Book order by custom order that user set'

        // Grouping journals by month
        if (
          data.data.getUser.journals &&
          data.data.getUser.journals.items.length > 0
        ) {
          let groups = data.data.getUser.journals.items.reduce(function (r, o) {
            let m = o.createdAt.split('-')[0] + '-' + o.createdAt.split('-')[1]
            r[m]
              ? r[m].data.push(o)
              : (r[m] = {
                  month: moment(o.createdAt).format('MMM YYYY'),
                  data: [o],
                })
            return r
          }, {})

          // Add month as a key
          let journalByMonth = Object.keys(groups).map(function (k) {
            return groups[k]
          })

          // Replace journals data with new format with months
          data.data.getUser.journals.items = journalByMonth

          // reorder user books by bookOrder(users can change order or books)
          if (
            data.data.getUser.bookOrder &&
            data.data.getUser.bookOrder.length > 0
          ) {
            const newBookOrder = JSON.parse(data.data.getUser.bookOrder)
            data.data.getUser.books.items = reorderBooks(
              data.data.getUser.books.items,
              newBookOrder
            )
          }
        }

        dispatch({ type: 'GET_USER_DATA', userData: data.data.getUser })
        dispatch({
          type: 'SET_FETCH_USER_DATA_SESSION',
          isFetchUserData: false,
        })
      }
    } catch (err) {
      console.log('error getting user data: ', err)
      dispatch({ type: 'SET_FETCH_USER_DATA_SESSION', isFetchUserData: false })
    }
  }
}

// Get user journal data
// -----------------------------------------------------------------------------
export function getJournalsData(username, value, nextToken) {
  return async (dispatch) => {
    try {
      // TODO: search needs to be improved because query and filter only covers the data that are queried.
      const data = await API.graphql(
        graphqlOperation(custom_queries.getJournals, {
          id: username,
          content: value,
          nextTokenJournal: nextToken,
        })
      )

      if (data.data.getUser.journals.items.length > 0) {
        let groups = data.data.getUser.journals.items.reduce(function (r, o) {
          let m = o.createdAt.split('-')[0] + '-' + o.createdAt.split('-')[1]
          r[m]
            ? r[m].data.push(o)
            : (r[m] = {
                month: moment(o.createdAt).format('MMM YYYY'),
                data: [o],
              })
          return r
        }, {})

        // Add month as a key
        let journalByMonth = Object.keys(groups).map(function (k) {
          return groups[k]
        })

        // Replace journals data with new format with months
        data.data.getUser.journals.items = journalByMonth
      } else {
        data.data.getUser.journals.items = ''
      }

      dispatch({
        type: nextToken ? 'GET_MORE_USER_SEARCH_DATA' : 'GET_USER_DATA',
        userData: data.data.getUser,
      })
    } catch (err) {
      console.log('error getting user data: ', err)
    }
  }
}

// get More user journal
// -----------------------------------------------------------------------------
export function getMoreUserJournal(username, nextToken) {
  return async (dispatch) => {
    try {
      const data = await API.graphql(
        graphqlOperation(custom_queries.getUser, {
          id: username,
          limit: 60,
          nextTokenJournal: nextToken,
        })
      )
      // console.log(data.data.getUser)

      if (data.data.getUser.journals.items.length > 0) {
        let groups = data.data.getUser.journals.items.reduce(function (r, o) {
          let m = o.createdAt.split('-')[1]
          r[m]
            ? r[m].data.push(o)
            : (r[m] = {
                month: moment(o.createdAt).format('MMM YYYY'),
                data: [o],
              })
          return r
        }, {})

        // Add month as a key
        let journalByMonth = Object.keys(groups).map(function (k) {
          return groups[k]
        })

        dispatch({
          type: 'ADD_USER_JOURNAL_DATA',
          moreUserJournals: {
            items: journalByMonth,
            nextToken: data.data.getUser.journals.nextToken,
          },
        })
      } else {
        console.log('End of your journal')
        dispatch({
          type: 'ADD_USER_JOURNAL_DATA',
          moreUserJournals: { items: [], nextToken: null },
        })
      }
    } catch (err) {
      console.log('error getting more user journal data: ', err)
    }
  }
}

export function setMyJournalLimit(limit) {
  return (dispatch) => {
    dispatch({ type: 'SET_MY_JOURNAL_LIMIT', limit })
  }
}

// User Sign Out
// -----------------------------------------------------------------------------
export function userSignOut() {
  return (dispatch) => {
    dispatch({
      type: 'SET_CURRENT_USER_SESSION',
      isAuthenticated: false,
      isFetchingCurrentUserSession: false,
    })
    Auth.signOut()
      .then((data) => {
        dispatch({ type: 'USER_LOGOUT' })
      })
      .catch((err) => console.log(err))
  }
}

// Get Author data
// -----------------------------------------------------------------------------
export function getAuthorData(username) {
  return async () => {
    try {
      const data = await API.graphql(
        graphqlOperation(custom_queries.getAuthor, { id: username })
      )

      if (
        data.data.getUser.bookOrder &&
        data.data.getUser.bookOrder.length > 0
      ) {
        const newBookOrder = JSON.parse(data.data.getUser.bookOrder)
        data.data.getUser.books.items = reorderBooks(
          data.data.getUser.books.items,
          newBookOrder
        )
      }

      return data.data.getUser
    } catch (err) {
      console.log('error getting author data: ')
      console.log(err)
    }
  }
}

// Get Author PUBLIC journal data
// -----------------------------------------------------------------------------
export function getAuthorPublicJournalData(journalAuthorId, nextToken) {
  return async () => {
    try {
      const data = await API.graphql({
        query: custom_queries.journalAuthorLatestAsKey,
        variables: {
          journalAuthorId: journalAuthorId,
          sortDirection: 'DESC',
          nextToken: nextToken,
          limit: 30,
        },
        authMode: 'AWS_IAM',
      })
      return data.data.journalAuthorLatestAsKey
    } catch (err) {
      console.log('error getting user data: ', err)
    }
  }
}

// Follow an author
// -----------------------------------------------------------------------------
export function followAuthor(targetAuthor, followingAuthor) {
  return async (dispatch, getState) => {
    let response = {}
    try {
      response = (
        await API.graphql(
          graphqlOperation(custom_mutations.createFollowing, {
            input: { user: followingAuthor.id, following: targetAuthor.id },
          })
        )
      ).data.createFollowing
      createNotificationFromFollow(targetAuthor.id, getState().user.userData)
    } catch (err) {
      console.log('error following author: ', err)
    }
    return response
  }
}

// Adjust following and follower counts of an author
// -----------------------------------------------------------------------------
export function adjustCounts(targetAuthor, followingAuthor, difference) {
  return async () => {
    let response = {}
    try {
      // increment following count of following author
      let followingCount = followingAuthor.followingCount || 0
      followingCount = followingCount + difference
      response['user'] = (
        await API.graphql(
          graphqlOperation(custom_mutations.updateUser, {
            input: {
              id: followingAuthor.id,
              followingCount: followingCount <= 0 ? 0 : followingCount,
            },
          })
        )
      ).data.updateUser

      // increment follower count of followed author
      let followerCount = targetAuthor.followerCount || 0
      followerCount = followerCount + difference
      response['author'] = (
        await API.graphql(
          graphqlOperation(custom_mutations.updateUser, {
            input: {
              id: targetAuthor.id,
              followerCount: followerCount <= 0 ? 0 : followerCount,
            },
          })
        )
      ).data.updateUser
    } catch (err) {
      console.log('error following author: ', err)
    }
    return response
  }
}

// Unfollow an author
// -----------------------------------------------------------------------------
export function unfollowAuthor(followingId) {
  return async () => {
    try {
      await API.graphql(
        graphqlOperation(custom_mutations.deleteFollowing, {
          input: { id: followingId },
        })
      )
    } catch (err) {
      console.log('error unfollowAuthor: ', err)
    }
  }
}

// Following
// -----------------------------------------------------------------------------
export function doesSourceFollowTarget(sourceUserId, targetUserId) {
  return async () => {
    try {
      const data = await API.graphql(
        graphqlOperation(custom_queries.isUserFollowingTheAuthor, {
          following: targetUserId,
          user: sourceUserId,
        })
      )
      return data.data.isUserFollowingTheAuthor
    } catch (err) {
      console.log('error getting doesSourceFollowTarget: ', err)
    }
  }
}

export function getUserFollowings(userId, nextToken) {
  return async () => {
    try {
      const data = await API.graphql(
        graphqlOperation(custom_queries.userFollowingAsKey, {
          user: userId,
          nextToken: nextToken,
          limit: 30,
        })
      )
      return data.data.userFollowingAsKey
    } catch (err) {
      console.log('error getUserFollowings()', err)
    }
  }
}

export function getUserFollowers(userId, nextToken) {
  return async () => {
    try {
      const data = await API.graphql(
        graphqlOperation(custom_queries.userFollowerAsKey, {
          following: userId,
          nextToken: nextToken,
          limit: 30,
        })
      )
      return data.data.userFollowerAsKey
    } catch (err) {
      console.log('error getUserFollowers()', err)
    }
  }
}

export function getChallengeData(username, limit, nextToken) {
  return async () => {
    try {
      const currentYear = new Date().getFullYear()
      const currentMonth = new Date().getMonth()
      if (username) {
        const data = await API.graphql(
          graphqlOperation(custom_queries.yearMonthUserAsKey, {
            user: { eq: username },
            yearMonth: currentYear + '-' + ('0' + (currentMonth + 1)).slice(-2),
            sortDirection: 'DESC',
          })
        )
        return data.data.yearMonthUserAsKey
      } else {
        const data = await API.graphql(
          graphqlOperation(custom_queries.yearMonthJournalsPerMonthAsKey, {
            yearMonth: currentYear + '-' + ('0' + (currentMonth + 1)).slice(-2),
            nextToken: nextToken,
            limit,
            sortDirection: 'DESC',
          })
        )
        return data.data.yearMonthJournalsPerMonthAsKey
      }
    } catch (err) {
      console.log('error getChallengeData()', err)
    }
  }
}

export function setChallengeLimit(limit) {
  return (dispatch) => {
    dispatch({ type: 'SET_CHALLENGE_LIMIT', limit })
  }
}

// Update user displayName
// -----------------------------------------------------------------------------
export function updateUserSetting(userData) {
  return async (dispatch) => {
    let user = await Auth.currentAuthenticatedUser()
    let userId = user.signInUserSession.idToken.payload['cognito:username']
    try {
      let data = await Auth.updateUserAttributes(user, userData)
      await API.graphql(
        graphqlOperation(custom_mutations.updateUser, {
          input: { id: userId, displayName: userData.preferred_username },
        })
      )
      dispatch(getCurrentUser())
      return data
    } catch (err) {
      console.log('error updating user setting: ', err)
    }
  }
}

export function adjustUserJournalCount(diff) {
  return async (dispatch, getState) => {
    try {
      let total = getState().user.userData.totalJournal + diff
      if (total < 0) total = 0
      await API.graphql(
        graphqlOperation(custom_mutations.updateUser, {
          input: { id: getState().user.userData.id, totalJournal: total },
        })
      )
      if (total % 30 === 0 && total !== 0) {
        createNotificationThirtyJournal(total, getState().user.userData.id)
      }
      dispatch({ type: 'ADJUST_TOTAL_JOURNAL', total })
    } catch (e) {
      console.log(e)
    }
  }
}

// Disable user
// -----------------------------------------------------------------------------
export function gnjAmplifyadminDisableUser(username) {
  return async (dispatch) => {
    try {
      await API.graphql(
        graphqlOperation(custom_mutations.gnjAmplifyadminDisableUser, {
          username: username,
        })
      )
      dispatch(userSignOut())
    } catch (e) {
      console.log(e)
    }
  }
}

// Update bookOrder under User table
// -----------------------------------------------------------------------------
export function updateUserBookOrder(newBookOrder) {
  return async (dispatch) => {
    let user = await Auth.currentAuthenticatedUser()
    let userId = user.signInUserSession.idToken.payload['cognito:username']
    try {
      await API.graphql(
        graphqlOperation(custom_mutations.updateUser, {
          input: { id: userId, bookOrder: newBookOrder },
        })
      )
      dispatch(getCurrentUser())
    } catch (err) {
      console.log('error updating userBooks data: ', err)
    }
  }
}
