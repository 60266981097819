import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";

import { stripeCancelMembership } from "../actions/membership";
import { getCurrentUser } from "../actions/user";

import Footer from '../components/Footer'
import loadingIcon from "../assets/images/loading-dark.svg"
import community from "../assets/images/community.svg"

class CancelConfirm extends Component{
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      isLoading: false
    };
  }

  _cancelMembership = async () => {
    const { currentUser } = this.props.user
    this.setState({ error: "", isLoading: true });
    const ask = window.confirm("Cancel my membership?");
    if (ask) {
      const cancelMembership  = await this.props.stripeCancelMembership(
        currentUser.attributes["custom:stripeSubscriptionId"],
        currentUser.username,
        true
      )
      if(cancelMembership.subscription){
        await this.props.getCurrentUser();
        this.setState({
          error: "",
          isLoading: false
        });
        this.props.history.push("/my-account/billing")
      } else {
        this.setState({ error: "Error occurred. Please try again.", isLoading: false });
      }
    }else{
      this.setState({
        error: "",
        isLoading: false
      });
    }
  }

  render() {
    const { error, isLoading } = this.state
    
    return(
      <div>
        <div className="Billing Container Container--md Container--side-padding">
          <h1>Cancel membership</h1>
          
          <div>
            <h3>Are you sure you want to cancel your membership?</h3>
            <p>You will lose access to all member-only features at the end of your current membership cycle.</p>

            <div className="Container Container--sm">
              <p><img src={community} alt="Being a member means" /></p>
            </div>

            <p>Your membership helps us out a lot! Your membership dues goes to running this app smoothly, to improving existing features, and in releasing new features that make writing a journal more enjoyable!</p>

            <p><Link to="/feedback" id="cancel_tell_us_feedback_link">Give us feedback to serve you better!</Link></p>
            {error ? <p className="error-msg">{error}</p> : ''}
            <div className="cancel-confirm-buttons">
              <button className="btn-secondary" onClick={() => this.props.history.push('/')} id="cancel_keep_my_membership" disabled={isLoading}>No, keep my membership</button>
              <button className="btn-secondary" onClick={this._cancelMembership} disabled={isLoading} id="cancel_cancel_my_membership">
                {isLoading ? 
                  <img
                    alt="Loading..."
                    className="loading-icon"
                    src={loadingIcon}
                  /> 
                : "Yes, cancel my membership" }
              </button>
            </div>
          </div>

          <div>
            <Footer />
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      stripeCancelMembership,
      getCurrentUser
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CancelConfirm);