import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { userSignOut } from '../actions/user'
import ProfilePic from '../utils/ProfilePic'
// import isPaidUser from '../utils/isPaidUser'
// import { TopNoticeBar } from '../components/TopNoticeBar'

import gnjLogo from '../assets/images/gnj-logo.svg'
import gnjLogoMobile from '../assets/images/favicon.svg'
import happyLogoBlack from '../assets/images/happy-logo-black.svg'
import happyLogoWhite from '../assets/images/happy-logo-white.svg'
import bibleLogoBlack from '../assets/images/bible-logo-black.svg'
import bibleLogoWhite from '../assets/images/bible-logo-white.svg'

class Navigation extends Component {
  constructor() {
    super()
    this.state = {
      showSubNav: false,
      hideBillingLink: false,
    }

    this.showSubNavUI = this.showSubNavUI.bind(this)
    this.signOut = this.signOut.bind(this)
  }

  componentDidMount() {
    // TODO: Added to see detect ios and android. Hide Billing for ios
    // window.addEventListener(
    //   'message',
    //   (event) => {
    //     if (event.data.userPlatform === 'ios') {
    //       this.setState({ hideBillingLink: true })
    //     }
    //   },
    //   false
    // )
  }

  showSubNavUI() {
    this.setState({
      showSubNav: !this.state.showSubNav,
    })
  }

  async signOut() {
    const ask = window.confirm('Do you want to log out?')
    if (ask) {
      console.log('Signing out...')
      await this.props.userSignOut()
      this.props.history.push('/login')
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({
        showSubNav: false,
      })
    }
  }

  render() {
    const { showSubNav, hideBillingLink } = this.state
    const { user } = this.props

    const notifBadgeCounts = user.notifications.filter((n) => !n.read).length
    const chatNotfication =
      user.userData &&
      user.userData.chatRoomUser.items.map((r) => {
        return (
          r.chatRoom.messages.items[0] &&
          r.chatRoom.messages.items[0].messageUserId !==
            user.accountData.idToken.payload['cognito:username'] &&
          !r.chatRoom.receiverHasRead
        )
      })
    const chatNotificationBadgeCounts =
      chatNotfication && chatNotfication.filter((n) => n === true).length

    const Nav = user.isAuthenticated ? (
      <ul className="member">
        <li>
          <Link to="/community" id="navigation_community">
            <ion-icon name="ios-globe"></ion-icon>
          </Link>
        </li>
        <li>
          <Link to="/challenge" id="navigation_challenge">
            <ion-icon name="ios-trophy"></ion-icon>
          </Link>
        </li>
        <li>
          <Link to="/chat" className="nav-notification" id="navigation_chat">
            <ion-icon name="ios-chatbubbles"></ion-icon>
            {chatNotificationBadgeCounts > 0 && (
              <span className="badge">
                {chatNotificationBadgeCounts > 9
                  ? '9+'
                  : chatNotificationBadgeCounts}
              </span>
            )}
          </Link>
        </li>
        <li className="icon-menu">
          <Link
            to="/notification"
            className="nav-notification"
            id="navigation_notification"
          >
            <ion-icon name="ios-notifications"></ion-icon>
            {notifBadgeCounts > 0 && (
              <span className="badge">
                {notifBadgeCounts > 9 ? '9+' : notifBadgeCounts}
              </span>
            )}
          </Link>
        </li>
        <li className="icon-menu my-account-menu" onClick={this.showSubNavUI}>
          {user.currentUser ? (
            <img
              src={ProfilePic(user)}
              alt="No profile pic"
              className="profile-pic"
            />
          ) : (
            <div
              style={{
                background: '#eaeaea',
                borderRadius: '50%',
                width: 24,
                height: 24,
                marginLeft: 8,
              }}
            ></div>
          )}
          <div
            className={'my-account-hover-menu' + (showSubNav ? ' show' : '')}
          >
            <ul>
              <li className="sub-nav-logos">
                <a
                  href="https://happy.goodnightjournal.com"
                  id="navigation_to_happy"
                  className="happy-nav-logo"
                >
                  <img
                    src={happyLogoBlack}
                    alt="Happy logo"
                    className="happy-nav-logo-black"
                  />
                  <img
                    src={happyLogoWhite}
                    alt="Happy logo"
                    className="happy-nav-logo-white"
                  />
                </a>
                <a
                  href="https://bible.goodnightjournal.com"
                  id="navigation_to_bible"
                  className="bible-nav-logo"
                >
                  <img
                    src={bibleLogoBlack}
                    alt="Bible logo"
                    className="bible-nav-logo-black"
                  />
                  <img
                    src={bibleLogoWhite}
                    alt="Bible logo"
                    className="bible-nav-logo-white"
                  />
                </a>
              </li>
              <li>
                <Link to="/my-account" id="navigation_my_account">
                  My account
                </Link>
              </li>
              <li>
                {/* <Link to={"/books/" + encodeURIComponent(user.accountData.idToken.payload["cognito:username"])} id="navigation_my_boook">My books</Link> #### For public book page */}
                <Link to="/books" id="navigation_my_boook">
                  My books
                </Link>
              </li>
              <li>
                <Link to="/my-account/setting" id="navigation_setting">
                  Setting
                </Link>
              </li>
              {!hideBillingLink && (
                <li>
                  <Link to="/my-account/billing" id="navigation_billing">
                    Billing
                  </Link>
                </li>
              )}
              <li>
                <span
                  onClick={this.signOut}
                  className="link-text trackit"
                  id="navigation_logout"
                >
                  Logout
                </span>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    ) : (
      <ul className="non-member">
        <li>
          <Link to="/login" id="navigation_login">
            Log in
          </Link>
        </li>
        <li>
          <Link to="/signup" id="navigation_signup">
            Sign up
          </Link>
        </li>
        <li>
          <Link to="/community" id="navigation_community">
            Community
          </Link>
        </li>
      </ul>
    )

    const logoLinkTo = user.isAuthenticated ? (
      <Link to="/" id="navigation_logo">
        <img
          src={gnjLogoMobile}
          className="logo-mobile"
          alt="Goodnight Jornal logo mobile"
        />
        <img
          src={gnjLogo}
          className="logo-desktop"
          alt="Goodnight Jornal logo"
        />
      </Link>
    ) : (
      <a
        href="https://www.goodnightjournal.com"
        id="navigation_logo_to_homepage"
      >
        <img
          src={gnjLogoMobile}
          className="logo-mobile"
          alt="Goodnight Jornal logo mobile"
        />
        <img
          src={gnjLogo}
          className="logo-desktop"
          alt="Goodnight Jornal logo"
        />
      </a>
    )

    return (
      <React.Fragment>
        {/* TODO: use TopNotieBar for future promotions. This is a benner that shows up on the top of the page */}
        {/* {user.currentUser &&
          !isPaidUser(user.currentUser.attributes['custom:membership']) && (
            <TopNoticeBar />
          )} */}
        <nav className="main-nav">
          <div className="logo-nav">{logoLinkTo}</div>
          <div className="main-nav-menu">{Nav}</div>
        </nav>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return state
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      userSignOut,
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Navigation))
