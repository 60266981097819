import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  deleteJournal,
  getPublicJournalEntry,
  createUserLovedJournal,
  deleteUserLovedJournal,
  setJournal,
  isUserLovedJournal,
  setEmptyCurrentJournal,
} from '../actions/journal'

import { getUserData } from '../actions/user'
import LoadingCard from '../components/LoadingCard'
import Comments from './Comments'
import PublicJournalEntryContent from '../components/PublicJournalEntryContent'
import NoMatch from '../components/NoMatch'

import { Helmet } from 'react-helmet'

function PublicJournalEntry(props) {
  const username = props.user.accountData
    ? props.user.accountData.idToken.payload['cognito:username']
    : ''
  const [isUserLovedJournal, setIsUserLovedJournal] = useState({})
  const [stateUpdating, setStateUpdating] = useState(true)
  const [localJournal, setLocalJournal] = useState({})

  useEffect(() => {
    let unmounted = false
    setStateUpdating(true)
    const currentJournalUid = props.match.params.journal_uid
    const getPublicJournals = async () => {
      const currentJournal = await props.getPublicJournalEntry(
        currentJournalUid
      )
      if (!unmounted) setLocalJournal(currentJournal)
    }
    getPublicJournals()

    if (username) {
      const checkIfUserLovedJournal = async () => {
        const isUserLovedJournal = await props.isUserLovedJournal(
          username,
          currentJournalUid
        )
        if (isUserLovedJournal && isUserLovedJournal.items.length > 0) {
          setIsUserLovedJournal(isUserLovedJournal.items)
        }
      }
      checkIfUserLovedJournal()
    }

    setStateUpdating(false)
    return () => {
      unmounted = true
    }
  }, [])

  const goBackDestination = async () => {
    const { location } = props

    location.state &&
    (location.state.prevPath === '/write' ||
      location.state.prevPath === '/edit')
      ? props.history.push('/')
      : props.history.goBack()
  }

  const deleteJournal = async () => {
    const { journal_uid } = props.match.params
    const ask = window.confirm(
      'Do you want to permanently delete this journal?'
    )
    if (ask) {
      try {
        const deletedJournal = await props.deleteJournal(journal_uid)
        await props.getUserData(props.user.userData.id)
        if (deletedJournal.id === journal_uid) {
          props.history.push('/')
        } else {
          alert(
            'Error deleting the journal. Please reload the page and try again.'
          )
        }
      } catch (err) {
        console.log(err)
      }
    }
  }

  const renderSubNavUI = () => {
    const { journal_uid } = props.match.params

    if (
      localJournal &&
      localJournal.author &&
      localJournal.author.id === username
    ) {
      return (
        <ul className="sub-nav">
          <li>
            <span
              className="link-text trackit"
              onClick={goBackDestination}
              id="journalEntry_subNav_go_back"
            >
              <ion-icon name="arrow-round-back"></ion-icon> Back
            </span>
          </li>
          <li
            className="link-text trackit"
            id="journalEntry_subNav_edit"
            onClick={() => props.history.push('/edit/' + journal_uid)}
          >
            <ion-icon name="create"></ion-icon>
          </li>
          <li
            className="link-text trackit"
            onClick={deleteJournal}
            id="journalEntry_subNav_delete"
          >
            <ion-icon name="trash"></ion-icon>
          </li>
        </ul>
      )
    } else {
      return (
        <ul className="sub-nav">
          <li>
            <span
              className="link-text trackit"
              onClick={goBackDestination}
              id="journalEntry_subNav_go_back"
            >
              <ion-icon name="arrow-round-back"></ion-icon> Back
            </span>
          </li>
          <li></li>
          <li></li>
        </ul>
      )
    }
  }

  const loveJournal = async (
    userLovedJournalJournalId,
    userLovedJournalUserId
  ) => {
    const { journal_uid } = props.match.params

    if (!username) {
      props.history.push({
        pathname: '/login',
        state: { prevPath: props.location.pathname },
      })
      return
    }

    setStateUpdating(true)
    setIsUserLovedJournal([{ id: 'OptimasticUI_Tem_ID' }])

    localJournal.lovedCount >= 0 || localJournal.lovedCount === null
      ? (localJournal.lovedCount = localJournal.lovedCount + 1)
      : (localJournal.lovedCount = 0)
    props.setJournal(localJournal)

    try {
      await props.createUserLovedJournal(
        userLovedJournalJournalId,
        userLovedJournalUserId,
        localJournal.author.id
      )
      const isUserLovedJournal = await props.isUserLovedJournal(
        username,
        journal_uid
      )
      if (isUserLovedJournal.items.length > 0) {
        setIsUserLovedJournal(isUserLovedJournal.items)
      }
      setStateUpdating(false)
    } catch (err) {
      console.log(err)
      setStateUpdating(false)
    }
  }

  const unloveJournal = async (id) => {
    const { journal_uid } = props.match.params

    if (!username) {
      props.history.push({
        pathname: '/login',
        state: { prevPath: props.location.pathname },
      })
      return
    }

    setStateUpdating(true)
    setIsUserLovedJournal({})

    localJournal.lovedCount > 0
      ? (localJournal.lovedCount = localJournal.lovedCount - 1)
      : (localJournal.lovedCount = 0)
    props.setJournal(localJournal)

    try {
      await props.deleteUserLovedJournal(id)
      const isUserLovedJournal = await props.isUserLovedJournal(
        username,
        journal_uid
      )
      if (isUserLovedJournal.items.length === 0) {
        setIsUserLovedJournal(isUserLovedJournal.items)
      }
      setStateUpdating(false)
    } catch (err) {
      console.log(err)
      setStateUpdating(false)
    }
  }

  if (!localJournal) {
    return <NoMatch />
  }

  const loadJournalEntry =
    Object.keys(localJournal).length > 0 ? (
      <PublicJournalEntryContent
        journalEntry={localJournal}
        loveJournal={loveJournal}
        unloveJournal={unloveJournal}
        isUserLovedJournal={isUserLovedJournal}
        userUid={username}
        stateUpdating={stateUpdating}
      />
    ) : (
      <LoadingCard />
    )

  return (
    <div className="journal-entry">
      <Helmet>
        <title>
          {Object.keys(localJournal).length > 0 &&
            localJournal.content.replace(/<(.|\n)*?>/g, ' ').slice(0, 80) +
              (localJournal.content.replace(/<(.|\n)*?>/g, ' ').length > 80 &&
                '...')}
        </title>
        <meta
          name="description"
          content={
            Object.keys(localJournal).length > 0 &&
            localJournal.content.replace(/<(.|\n)*?>/g, ' ').slice(0, 160) +
              (localJournal.content.replace(/<(.|\n)*?>/g, ' ').length > 160 &&
                '...')
          }
        />
      </Helmet>
      {renderSubNavUI()}
      <div className="journal-entry Container Container--side-padding">
        {loadJournalEntry}
        {localJournal && localJournal.privacy === 'PUBLIC' && (
          <Comments currentUserID={username} localJournal={localJournal} />
        )}
      </div>
    </div>
  )
}

function mapStateToProps(state) {
  return state
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteJournal,
      getPublicJournalEntry,
      createUserLovedJournal,
      deleteUserLovedJournal,
      setJournal,
      getUserData,
      isUserLovedJournal,
      setEmptyCurrentJournal,
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(PublicJournalEntry)
