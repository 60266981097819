import React, { Component } from "react";
import { Link } from "react-router-dom";

import moment from "moment";
import decodeHtml from "../utils/DecodeHtml";

class BookJournalListItem extends Component {
  render() {
    const { journal } = this.props;

    return (
      <div className={"journal-list-item" + (journal.draft ? " draft" : "")}>
        <Link to={(journal.privacy === "PUBLIC" && !journal.draft ? "/public-journal/" : "/journal/") + journal.id} id={"community_public_journal_" + journal.id}>
         <div className="journal-list-item-header">
            <time>{moment(journal.createdAt).format("llll")}</time>
            {journal.privacy === "PRIVATE" && <span className="badge badge--grey">Private</span>}{" "}
            {journal.draft && <span className="badge badge--grey">Draft</span>}
          </div>
          <p>
            {(journal.journalBookId || journal.publicJournalBookId) && <span style={{ paddingRight: 4, color: "#3bc183", verticalAlign: "middle" }}><ion-icon name="book"></ion-icon></span>}
            {decodeHtml(journal.content.replace(/<(.|\n)*?>/g, " ").slice(0, 80))}{" "}
            {journal.content.replace(/<(.|\n)*?>/g, "").length > 80 && "..."} {journal.journalWordCount && <i className="word-count"> - {journal.journalWordCount} words</i>}
          </p>

          {journal.privacy === "PUBLIC" &&
            <div className="journal-list-item-footer">
              <div>
                <ion-icon name="heart"></ion-icon>{" "}
                {journal.lovedCount ? journal.lovedCount : 0}
              </div>
              <div>
                <ion-icon name="ios-chatbubbles"></ion-icon>{" "}
                  {journal.commentCount ? journal.commentCount : 0}
              </div>
            </div>
          }
        </Link>
      </div>
    );
  }
}

export default BookJournalListItem;
