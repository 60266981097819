import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import {
  createABook,
  getMoreUserBooks,
  getABookWithJournals,
} from '../actions/journal'
import { convertHEICToAny, getCompressedImage } from '../utils/ImageProcess'
import isPaidUser from '../utils/isPaidUser'

import AddBookPopup from '../components/AddBookPopup'
import UpgradePopup from '../components/UpgradePopup'

class BooksMyJournal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showBook: false,
      showAddaBook: false,
      showUpgrade: false,
      bookUserId: props.user.accountData.idToken.payload['cognito:username'],
      error: '',
      isCreatingABook: false,
    }
  }

  componentDidMount() {
    if (localStorage.getItem('show-books-on-my-journal') === 'true') {
      this.setState({ showBook: true })
    }
  }

  _showBook = () => {
    const { showBook } = this.state
    this.setState(
      {
        showBook: !showBook,
      },
      () => {
        if (showBook) {
          localStorage.setItem('show-books-on-my-journal', false)
          this.setState({ showBook: false })
        } else {
          localStorage.setItem('show-books-on-my-journal', true)
          this.setState({ showBook: true })
        }
      }
    )
  }

  _showAddaBookUI = () => {
    const { userBooks, user } = this.props

    userBooks.length > 2 &&
    !isPaidUser(user.currentUser.attributes['custom:membership'])
      ? this.setState({
          showAddaBook: false,
          showUpgrade: true,
        })
      : this.setState({
          showAddaBook: true,
          showUpgrade: false,
        })
  }

  _popupControl = async (callback) => {
    this.setState({
      showUpgrade: callback,
    })
  }

  _bookPopupControl = async (callback) => {
    this.setState({
      showAddaBook: callback,
    })
    if (!callback) {
      this.setState({
        error: '',
      })
    }
  }

  _createABook = async (bookData) => {
    const { bookUserId } = this.state
    this.setState({
      isCreatingABook: true,
      showAddaBook: true,
    })

    try {
      if (bookData.bookCoverImg) {
        let file = bookData.bookCoverImg
        let iPhoneExtensions = this.props.user.iPhoneExtensions

        // file type is only image.
        if (iPhoneExtensions.includes(file.name.split('.')[1])) {
          try {
            await convertHEICToAny(file).then((result) => (file = result))
          } catch {
            console.log('Converter not working.')
          }
        }
        if (/^image\//.test(file.type)) {
          try {
            await getCompressedImage(file).then((result) => (file = result))
            console.log('Image is being uploaded...')
            bookData.bookCoverImg = file
            const bookCreated = await this.props.createABook(
              bookData,
              bookUserId
            )
            if (bookCreated && bookCreated.error) {
              this.setState({
                error: bookCreated.error,
              })
            } else {
              this.setState({
                showAddaBook: false,
                error: '',
              })
            }
          } catch (err) {
            console.log(err)
          }
        } else {
          console.warn('You could only upload images.')
        }
      } else {
        const bookCreated = await this.props.createABook(bookData, bookUserId)
        if (bookCreated && bookCreated.error) {
          this.setState({
            error: bookCreated.error,
          })
        } else {
          this.setState({
            showAddaBook: false,
            error: '',
          })
        }
      }
      this.setState({ isCreatingABook: false })
    } catch (err) {
      console.log(err)
      this.setState({ error: err, isCreatingABook: false })
    }
  }

  render() {
    const { showBook, showAddaBook, showUpgrade, error, isCreatingABook } =
      this.state
    const { userBooks } = this.props

    const listOfBooks =
      userBooks.length > 0 ? (
        userBooks.slice(0, 13).map((book) => {
          return (
            <div
              className={book.bookCoverImg ? '' : 'default-book'}
              key={book.id}
              style={{ backgroundImage: 'url(' + book.bookCoverImg + ')' }}
              onClick={() => this.props.history.push('/book/' + book.id)}
              id={'book_clicked_' + book.id}
            >
              <span>
                {book.book} ({book.bookJournalCount ? book.bookJournalCount : 0}
                )
              </span>
            </div>
          )
        })
      ) : (
        <div className="outline-book" onClick={this._showAddaBookUI}>
          <span>
            <ion-icon name="add" style={{ fontSize: 32 }}></ion-icon>
          </span>
          <span>
            Create your
            <br />
            first book!
          </span>
        </div>
      )

    return (
      <div className="book-wapper">
        <div className="Container book-control">
          <span onClick={this._showBook}>
            <ion-icon name="book"></ion-icon> My books
          </span>
          <span>
            <ion-icon name="add" onClick={this._showAddaBookUI}></ion-icon>
          </span>
        </div>

        <div className={'Container book-list ' + (!showBook ? 'hide' : '')}>
          {userBooks.items === 'loading' ? <div></div> : listOfBooks}
          {userBooks.length > 0 && (
            <div
              className="outline-book"
              onClick={() => this.props.history.push('/books')}
            >
              <span>See all books</span>
            </div>
          )}
        </div>

        <AddBookPopup
          showAddaBook={showAddaBook}
          bookPopupControl={this._bookPopupControl}
          createABook={this._createABook}
          isCreatingABook={isCreatingABook}
          error={error}
        />

        <UpgradePopup
          featureName="Creating 3+ books"
          ctaText="Close"
          showUpgrade={showUpgrade}
          popupControl={this._popupControl}
        />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return state
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createABook,
      getMoreUserBooks,
      getABookWithJournals,
    },
    dispatch
  )
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BooksMyJournal)
)
