import { combineReducers } from 'redux'
import user from './user'
import journal from './journal'
import lover from './lover'

const appReducer = combineReducers({
  user,
  journal,
  lover
})

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    state = undefined
  }

  return appReducer(state, action)
}

export default rootReducer
