import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import LoadingCard from '../components/LoadingCard'
import UpgradePopup from '../components/UpgradePopup'
import ChatMessageItem from '../components/ChatMessageItem'
import isPaidUser from '../utils/isPaidUser'

import loadingIcon from '../assets/images/loading-dark.svg'

import {
  checkExistingChatRoom,
  createChatRoom,
  createChatMessage,
  createChatRoomUser,
  getChatRoomData,
  chatCreateMessageSubscriptions,
  chatDeleteMessageSubscriptions,
  getChatRoomUsersByChatRoomId,
  updateChatRoomUser,
  updateChatRoomRead,
  deleteChatMessage,
  deleteChatRoomUser,
} from '../actions/chat'

function ChatRoom(props) {
  const [membership, checkMembership] = useState(null)
  const [currentUsername, setCurrentUsername] = useState(null)
  const [showUpgrade, setUpgradePopup] = useState(false)
  const [message, setMessage] = useState('')
  const [newChatRoom, setChatRoom] = useState(props.match.params)
  const [chatData, getChatData] = useState({})
  const [subscriptionChatData, setSubscriptionChatData] = useState()
  const [limit, setLimit] = useState(30)
  const [nextToken, setNextToken] = useState(null)
  const [loading, setLoading] = useState(false)
  const [stopScrollingToBottom, setStopScrollingToBottom] = useState(false)
  const [sendingMessage, setSendingMessage] = useState(false)
  const [deletingMessage, setDeletingMessage] = useState(false)

  useEffect(() => {
    const { user } = props
    setChatRoom(props.match.params)
    props.user.currentUser &&
      checkMembership(
        isPaidUser(user.currentUser.attributes['custom:membership'])
      )
    setCurrentUsername(
      props.user.accountData.idToken.payload['cognito:username']
    )

    document.getElementById('main_header').classList.add('sticky-main-header')
    return () =>
      document
        .getElementById('main_header')
        .classList.remove('sticky-main-header')
  }, [newChatRoom, props.user.currentUser, props.match.params])

  const scrollToBottom = () => {
    window.scrollTo(0, document.body.scrollHeight)
  }

  useEffect(() => {
    if (!stopScrollingToBottom) {
      scrollToBottom()
    }
  })

  const loadMoreMessages = () => {
    if (nextToken) {
      setStopScrollingToBottom(true)
      setLoading(true)
      const fetchMoreData = async () => {
        const loadMoreFetchChatData = await props.getChatRoomData(
          newChatRoom.chatRoom_uid,
          30,
          nextToken
        )
        loadMoreFetchChatData.messages.items.reverse()
        setNextToken(loadMoreFetchChatData.messages.nextToken)
        setLimit(limit + 30)
        loadMoreFetchChatData.messages.items = [
          ...loadMoreFetchChatData.messages.items,
          ...chatData.messages.items,
        ]
        getChatData(loadMoreFetchChatData) // this does not update the front end right away.
      }
      fetchMoreData()
      setLoading(false)
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const fetchChatData = await props.getChatRoomData(
        newChatRoom.chatRoom_uid,
        limit
      )
      fetchChatData && fetchChatData.messages.items.reverse()
      fetchChatData && setNextToken(fetchChatData.messages.nextToken)
      fetchChatData && setLimit(limit + 1)
      getChatData(fetchChatData)
    }
    if (newChatRoom.chatRoom_uid && newChatRoom.chatRoom_uid !== 'new') {
      fetchData()
    }
  }, [subscriptionChatData, newChatRoom.chatRoom_uid])

  useEffect(() => {
    const subscribeChatData = async () => {
      await props.chatCreateMessageSubscriptions(setSubscriptionChatData, false)
      await props.chatDeleteMessageSubscriptions(setSubscriptionChatData, false)
    }
    subscribeChatData()
    setStopScrollingToBottom(false)

    // Unsubscribe Chat message
    return () => {
      props.chatCreateMessageSubscriptions(null, true)
      window.scrollTo(0, 0)
    }
  }, [])

  const setChatMessage = (e) => {
    setMessage(e.target.value)
  }

  const submitForm = (e) => {
    if ((window.e ? e.keyCode : e.which) === 13 && !e.shiftKey) {
      sendMessage(e)
    }
  }

  const sendMessage = async (e) => {
    e.preventDefault()
    setSendingMessage(true)

    // Don't send if there's no massage but spaces
    if (!message.replace(/\s/g, '').length) {
      alert('Type something to send')
      return
    }

    // If non-member, show upgrade popup
    if (!membership) {
      setUpgradePopup(true)
      setMessage('')
      setSendingMessage(false)
      return
    }

    setStopScrollingToBottom(false)

    if (newChatRoom.chatRoom_uid && newChatRoom.chatRoom_uid === 'new') {
      if (
        !props.history.location.state &&
        !props.history.location.state.chatWith
      ) {
        //if no chatWith state is passed, no users to chat with
        alert('No user to chat with')
        return
      } else {
        // Create a ChatRoom, ChatRoomUser, and Message

        // TODO: Group Chat in the future
        // User will be able great a group chat with multiple users. Save all users to `allUsers` as String

        const allUsers = props.history.location.state.chatWith // const allUsers = "currentUsername, user4, user3, user2, user1"
        const sortedMembersToChatArray = allUsers.split(', ')
        const AllSortedMembersToChat = sortedMembersToChatArray
          .sort()
          .join(', ') // members must be sorted alphabetically
        const existingChatRooms = await props.checkExistingChatRoom(
          currentUsername,
          AllSortedMembersToChat
        )

        if (existingChatRooms.items.length === 0) {
          const chatRoomCreated = await props.createChatRoom(currentUsername)
          await props.createChatMessage(
            currentUsername,
            chatRoomCreated.id,
            message
          )
          sortedMembersToChatArray.map(async (user) => {
            return await props.createChatRoomUser(
              user,
              chatRoomCreated.id,
              AllSortedMembersToChat
            ) // All other users to chat with
          })

          props.history.push('/chat/' + chatRoomCreated.id)
        } else {
          props.history.push(
            '/chat/' + existingChatRooms.items[0].chatRoomUserChatRoomId
          )
        }
      }
    } else if (newChatRoom.chatRoom_uid && Object.keys(chatData).length > 0) {
      // Add message to existing chatRoom
      const messageCreated = await props.createChatMessage(
        currentUsername,
        newChatRoom.chatRoom_uid,
        message
      )

      // Updating updatedAt under ChatRoomUser table for all users(In order to query ChatRoomUser in chronological order)
      const ChatRoomUserIds = await props.getChatRoomUsersByChatRoomId(
        newChatRoom.chatRoom_uid
      )
      ChatRoomUserIds.items.map(async (row) => {
        return await props.updateChatRoomUser(row.id, messageCreated.createdAt)
      })

      // Update receiverHasRead to false when oher user send currentUsername a message
      const currentUserSentLastMessage = chatData.messages.items[
        chatData.messages.items.length - 1
      ]
        ? chatData.messages.items[chatData.messages.items.length - 1]
            .messageUserId === currentUsername
        : false
      if (!currentUserSentLastMessage) {
        await props.updateChatRoomRead(newChatRoom.chatRoom_uid, false)
      }
    } else {
      alert("You can't send message.")
    }

    setMessage('')
    setSendingMessage(false)
  }

  const userClickedInput = async () => {
    // Check if the last message is from current user then update receiverHadRead value
    const currentUserSentLastMessage =
      newChatRoom.chatRoom_uid &&
      Object.keys(chatData).length > 0 &&
      (chatData.messages.items[chatData.messages.items.length - 1]
        ? chatData.messages.items[chatData.messages.items.length - 1]
            .messageUserId === currentUsername
        : false)
    if (chatData.receiverHasRead === false && !currentUserSentLastMessage) {
      await props.updateChatRoomRead(newChatRoom.chatRoom_uid, true)
    }
  }

  const popupControl = (callback) => {
    setUpgradePopup(callback)
  }

  const removeChatMessageByUser = async (messageId) => {
    const ask = window.confirm('Remove this message?')
    if (ask) {
      setDeletingMessage(true)
      await props.deleteChatMessage(messageId)
      setTimeout(() => {
        setDeletingMessage(false)
      }, 1000)
    }
  }

  const leaveChatRoom = async () => {
    const ask = window.confirm('Leave this chat room?')
    if (ask) {
      const leaveChatRoom = await props.deleteChatRoomUser(
        newChatRoom.chatRoom_uid,
        currentUsername
      )
      leaveChatRoom && props.history.push('/chat')
    }
  }

  // Show loading stage(I know it's weird but membership here is only used for the loading state)
  if (membership === null) {
    return (
      <div className="Container chat-message-wrapper Container--side-padding">
        <LoadingCard />
      </div>
    )
  }

  const renderChatUI =
    chatData &&
    Object.keys(chatData).length === 0 &&
    newChatRoom.chatRoom_uid !== 'new' ? (
      <div className="chat Container">
        <LoadingCard />
      </div>
    ) : chatData === null ? (
      <div className="chat Container">
        <div className="Container Container--sm sm-form no-chat-found">
          <h1>
            <span role="img" aria-label="Sweat">
              😅
            </span>
            <br />
            No chat found here
          </h1>
          <p>
            <button
              className="btn-secondary"
              onClick={() => props.history.goBack()}
            >
              Go back
            </button>
          </p>
        </div>
      </div>
    ) : (
      <React.Fragment>
        <div
          id="chat_message_wrapper"
          className="Container chat-message-wrapper Container--side-padding"
        >
          <p className="chat-alert">
            <small>
              <span role="img" aria-label="Bell">
                🔔
              </span>{' '}
              Only{' '}
              <span
                className="link-text"
                onClick={() =>
                  props.history.push({ pathname: '/my-account/billing' })
                }
              >
                members
              </span>{' '}
              can send messages
            </small>
          </p>
          {loading ? (
            <div className="load-more-chat-messages">
              <img
                alt="Loading..."
                className="loading-icon"
                src={loadingIcon}
              />
            </div>
          ) : (
            nextToken && (
              <div className="load-more-chat-messages">
                <button className="btn-secondary" onClick={loadMoreMessages}>
                  Load more
                </button>
              </div>
            )
          )}
          {Object.keys(chatData).length > 0 &&
            chatData.messages.items.map((m) => {
              return (
                <ChatMessageItem
                  key={m.id}
                  chatMessage={m}
                  currentUser={currentUsername}
                  removeChatMessage={removeChatMessageByUser}
                  deletingMessage={deletingMessage}
                />
              )
            })}
        </div>

        <div className="chat-form">
          {Object.keys(chatData).length > 0 &&
          chatData.chatRoomUsers.items.length <= 1 ? (
            <div className="Container Container--sm sm-form no-chat-found">
              <h1>
                <span role="img" aria-label="Sweat">
                  😅
                </span>
                <br />
                User left chat room
              </h1>
              <p>You can leave the room and create a new chat.</p>
            </div>
          ) : (
            <form id="chatFrom" onSubmit={sendMessage}>
              <textarea
                rows="1"
                placeholder="Message"
                value={message}
                onChange={setChatMessage}
                required
                id="chat_textarea"
                onKeyDown={submitForm}
                onFocus={userClickedInput}
                disabled={sendingMessage}
              ></textarea>
              <button id="chat_submit" disabled={!message || sendingMessage}>
                Send
              </button>
            </form>
          )}
        </div>
      </React.Fragment>
    )

  // TODO: Show displayNames of users in the room except current user with the link
  const renderChatTitle =
    chatData && Object.keys(chatData).length !== 0
      ? chatData.chatRoomUsers.items
          .map((u) => (u.user.displayName ? u.user.displayName : u.user.id))
          .join(', ').length > 24
        ? chatData.chatRoomUsers.items
            .map((u) => (u.user.displayName ? u.user.displayName : u.user.id))
            .join(', ')
            .slice(0, 24) + '...'
        : chatData.chatRoomUsers.items
            .map((u) => (u.user.displayName ? u.user.displayName : u.user.id))
            .join(', ')
      : props.history.location.state &&
        props.history.location.state.chatWithDisplayname.length > 24
      ? props.history.location.state &&
        props.history.location.state.chatWithDisplayname.slice(0, 24) + '...'
      : props.history.location.state &&
        props.history.location.state.chatWithDisplayname

  return (
    <div className="chat-room">
      <div>
        <div className="chat-header">
          <ul className="sub-nav sub-nav-back chat-ui-top">
            <li>
              <span
                className="link-text trackit chat-room-goback"
                onClick={() => props.history.goBack()}
                id="chatRoom_subNav_go_back"
              >
                <ion-icon name="arrow-round-back"></ion-icon>
              </span>
            </li>
            <li className="chat-room-title">
              <b>{renderChatTitle}</b>
            </li>
            <li>
              {chatData &&
                Object.keys(chatData).length > 0 &&
                chatData.chatRoomUsers.items.length > 0 && (
                  <span
                    className="link-text trackit"
                    onClick={leaveChatRoom}
                    id="chatRoom_create_a_book"
                  >
                    <ion-icon name="exit"></ion-icon>
                  </span>
                )}
            </li>
          </ul>
        </div>

        {renderChatUI}
      </div>

      <UpgradePopup
        featureName={'Chat'}
        showUpgrade={showUpgrade}
        popupControl={popupControl}
      />
    </div>
  )
}

function mapStateToProps(state) {
  return state
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      checkExistingChatRoom,
      createChatRoom,
      createChatMessage,
      createChatRoomUser,
      getChatRoomData,
      chatCreateMessageSubscriptions,
      chatDeleteMessageSubscriptions,
      getChatRoomUsersByChatRoomId,
      updateChatRoomUser,
      updateChatRoomRead,
      deleteChatMessage,
      deleteChatRoomUser,
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoom)
