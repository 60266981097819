import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import {
  createComment,
  deleteComment,
  updateComment,
  getMoreComments,
  createUserLovedComment,
  deleteUserLovedComment,
  setJournal,
  isUserLovedComment,
} from '../actions/journal'
import CommentWrite from '../components/CommentWrite'
import CommentListItem from '../components/CommentListItem'
import loadingIcon from '../assets/images/loading-dark.svg'
import UpgradePopup from '../components/UpgradePopup'
import isPaidUser from '../utils/isPaidUser'

class Comments extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showLoadingIcon: false,
      showLoadingIconWriting: false,
      showUpgrade: false,
      writingContent: '',
    }
  }

  async componentDidMount() {
    const { currentUserID } = this.props
    const { currentJournal } = this.props.journal

    if (currentJournal.comments.items.length > 0) {
      currentJournal.comments.items.map(async (comment) => {
        const currentUserLovedComment = await this.props.isUserLovedComment(
          currentUserID,
          comment.id
        )
        if (
          currentUserLovedComment &&
          currentUserLovedComment.items.length > 0
        ) {
          comment['hasLoved'] = currentUserLovedComment.items[0].id
          this.props.setJournal(currentJournal)
        }
      })
    }
  }

  _addComment = async (commentPassed) => {
    const { currentJournal } = this.props.journal
    const { userData, currentUser } = this.props.user
    if (!userData) {
      this.props.history.push({
        pathname: '/login',
        state: { prevPath: this.props.location.pathname },
      })
      return
    }
    if (!isPaidUser(currentUser.attributes['custom:membership'])) {
      this.setState({ showUpgrade: true })
      return
    }
    try {
      this.setState({ showLoadingIconWriting: true })
      await this.props.createComment(
        {
          content: commentPassed,
          commentAuthorId: userData.id,
          commentJournalId: currentJournal.id,
        },
        currentJournal.author.id
      )
      this.setState({
        showLoadingIconWriting: false,
        writingContent: '',
      })
    } catch (err) {
      console.log(err)
      this.setState({ showLoadingIconWriting: false })
    }
  }

  _updateComment = async (comment, newComment) => {
    try {
      await this.props.updateComment(comment, newComment)
    } catch (err) {
      console.log(err)
    }
  }

  _deleteComment = async (commentId) => {
    try {
      await this.props.deleteComment({ id: commentId })
    } catch (err) {
      console.log(err)
    }
  }

  _loadMore = async () => {
    const { currentJournal } = this.props.journal
    const { currentUserID } = this.props
    const { userData } = this.props.user
    if (!userData) {
      this.props.history.push({
        pathname: '/login',
        state: { prevPath: this.props.location.pathname },
      })
      return
    }
    this.setState({ showLoadingIcon: true })
    try {
      const moreComment = await this.props.getMoreComments(
        currentJournal.id,
        currentJournal.comments.nextToken
      )
      // Set nextToken
      moreComment.nextToken
        ? (currentJournal.comments.nextToken = moreComment.nextToken)
        : (currentJournal.comments.nextToken = '')

      // TODO: optimize and improve(probably move it to Redux). Setting "hasLoved" to render Loved UI on comment properly.
      // Order by date.
      moreComment.items.map(async (comment) => {
        // Check if current user already loved comment or not
        const currentUserLovedComment = await this.props.isUserLovedComment(
          currentUserID,
          comment.id
        )
        if (currentUserLovedComment.items.length > 0) {
          comment['hasLoved'] = currentUserLovedComment.items[0].id
          currentJournal.comments.items =
            currentJournal.comments.items.concat(comment)
          // Remove duplicated comments from comment array. This can happen when user writes a comment without loading all comments on the journal.
          currentJournal.comments.items = currentJournal.comments.items.filter(
            (comment, index, self) =>
              index === self.findIndex((x) => x.id === comment.id)
          )
          // Sort by date
          currentJournal.comments.items = currentJournal.comments.items
            .slice()
            .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
          this.props.setJournal(currentJournal)
        } else {
          currentJournal.comments.items =
            currentJournal.comments.items.concat(comment)
          // Remove duplicated comments from comment array. This can happen when user writes a comment without loading all comments on the journal.
          currentJournal.comments.items = currentJournal.comments.items.filter(
            (comment, index, self) =>
              index === self.findIndex((x) => x.id === comment.id)
          )
          // Sort by date
          currentJournal.comments.items = currentJournal.comments.items
            .slice()
            .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
          this.props.setJournal(currentJournal)
        }
      })

      this.setState({ showLoadingIcon: false })
    } catch (err) {
      console.log(err)
      this.setState({ showLoadingIcon: false })
    }
  }

  _loveComment = async (comment, userLovedCommentUserId) => {
    const { currentJournal } = this.props.journal
    try {
      let foundIndex = currentJournal.comments.items.findIndex(
        (x) => x.id === comment.id
      )
      currentJournal.comments.items[foundIndex]['hasLoved'] = 'TEMP'
      currentJournal.comments.items[foundIndex].commentLovedCount =
        currentJournal.comments.items[foundIndex].commentLovedCount + 1
      const userLovedComment = await this.props.createUserLovedComment(
        comment,
        userLovedCommentUserId,
        currentJournal.id
      )
      currentJournal.comments.items[foundIndex]['hasLoved'] =
        userLovedComment.id
      this.props.setJournal(currentJournal)
    } catch (err) {
      console.log(err)
    }
  }

  _unloveComment = async (comment) => {
    const { currentJournal } = this.props.journal
    try {
      let foundIndex = currentJournal.comments.items.findIndex(
        (x) => x.id === comment.id
      )
      currentJournal.comments.items[foundIndex].commentLovedCount &&
      currentJournal.comments.items[foundIndex].commentLovedCount > 0
        ? (currentJournal.comments.items[foundIndex].commentLovedCount =
            currentJournal.comments.items[foundIndex].commentLovedCount - 1)
        : (currentJournal.comments.items[foundIndex].commentLovedCount = 0)
      await this.props.deleteUserLovedComment(comment)
      currentJournal.comments.items[foundIndex]['hasLoved'] = null
      this.props.setJournal(currentJournal)
    } catch (err) {
      console.log(err)
    }
  }

  _onWritingComment = (e) => {
    this.setState({ writingContent: e.target.value })
  }

  _popupControl = async (callback) => {
    this.setState({
      showUpgrade: callback,
    })
  }

  render() {
    const { currentUserID } = this.props
    const { localJournal } = this.props

    const {
      showLoadingIcon,
      showLoadingIconWriting,
      showUpgrade,
      writingContent,
    } = this.state

    const commentList = localJournal.comments
      ? localJournal.comments.items.map((comment, i) => (
          <CommentListItem
            comment={comment}
            key={comment.id}
            currentUserID={currentUserID}
            deleteComment={this._deleteComment}
            updateComment={this._updateComment}
            loveComment={this._loveComment}
            unloveComment={this._unloveComment}
          />
        ))
      : ''

    return (
      <div
        className="journal-comments animate-fade-in-up animation-delay-04"
        id="comments"
      >
        {localJournal.commentCount > 0 ? (
          <h2>
            {localJournal.commentCount +
              ' comment' +
              (localJournal.commentCount === 1 ? '' : 's')}
          </h2>
        ) : (
          ''
        )}
        <div className="journal-list">
          {commentList}

          {localJournal.comments && localJournal.comments.nextToken ? (
            <div className="load-more">
              {showLoadingIcon ? (
                <img
                  alt="Loading comments"
                  className="loading-icon"
                  src={loadingIcon}
                />
              ) : (
                <button className="btn-secondary" onClick={this._loadMore}>
                  Load more
                </button>
              )}
            </div>
          ) : (
            ''
          )}

          <CommentWrite
            journal_uid={localJournal.id}
            commentCallback={this._addComment}
            showLoadingIcon={showLoadingIconWriting}
            commentValue={writingContent}
            onChangeCommend={this._onWritingComment}
          />
        </div>
        <UpgradePopup
          featureName="Commenting a public journal"
          ctaText=""
          showUpgrade={showUpgrade}
          popupControl={this._popupControl}
        />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return state
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createComment,
      deleteComment,
      updateComment,
      getMoreComments,
      createUserLovedComment,
      deleteUserLovedComment,
      setJournal,
      isUserLovedComment,
    },
    dispatch
  )
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Comments)
)
