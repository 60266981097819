import React, { useState } from 'react';
import moment from 'moment';
import { Link } from "react-router-dom";
import loadingIcon from "../assets/images/loading-dark.svg";

function ChatMessageItem(props) {
  const [showDeleteLink, setShowDeleteLink] = useState(false)

  const deleteMessageCallback = () => {
    props.removeChatMessage(props.chatMessage.id)
  }

  const onClickMessage = () => { 
    setShowDeleteLink(!showDeleteLink)
  }

  const renderUsername = props.chatMessage.messageUserId === props.currentUser ? 
    <small>
      <code><b>{props.chatMessage.user.displayName || props.chatMessage.user.id}</b></code> &#183; <time>{moment(props.chatMessage.createdAt).fromNow()}</time>
    </small>
  :
  <small>
    <code><b><Link to={`/author/${encodeURIComponent(props.chatMessage.user.id)}`} id={"chat_room_author_id_" + props.chatMessage.user.id} className="chat-message-username">
    {props.chatMessage.user.displayName || props.chatMessage.user.id}</Link></b></code> &#183; <time>{moment(props.chatMessage.createdAt).fromNow()}</time>
  </small>

  return(
    <div className={props.chatMessage.messageUserId === props.currentUser ? "message my-message" : "message"} id={props.chatMessage.id}> 
      <p className="message-content" onClick={onClickMessage}>
        {renderUsername}
        <br />
        {props.chatMessage.content}
        {props.chatMessage.messageUserId === props.currentUser && (props.deletingMessage ? 
          <span className="chat-options chat-option-loading track-it"><img
            alt="Loading..."
            className="loading-icon"
            src={loadingIcon}
          /></span>
         : 
          <span className={showDeleteLink ? "chat-options track-it show" : "chat-options track-it"} onClick={deleteMessageCallback} id={"Remove_chat_message_" + props.chatMessage.id}>Remove</span>)
        }
      </p>
    </div>
  )
}

export default ChatMessageItem