import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import {
  createABook,
  getMoreUserBooks,
  getABookWithJournals,
} from '../actions/journal'
import { updateUserBookOrder } from '../actions/user'
import { convertHEICToAny, getCompressedImage } from '../utils/ImageProcess'
import isPaidUser from '../utils/isPaidUser'

import AddBookPopup from '../components/AddBookPopup'
import UpgradePopup from '../components/UpgradePopup'
import LoadingBook from '../components/LoadingBook'
import { SortableBooks } from '../components/SortableBooks'
class MyBooks extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showAddaBook: false,
      showUpgrade: false,
      bookUserId: props.user.accountData.idToken.payload['cognito:username'],
      error: '',
      isCreatingABook: false,
      selectedBook: '',
      reorderMode: false,
      upgradeMessage: '',
    }
  }

  _showAddaBookUI = () => {
    const { userBooks, currentUser } = this.props.user

    userBooks.length > 2 &&
    !isPaidUser(currentUser.attributes['custom:membership'])
      ? this.setState({
          showAddaBook: false,
          showUpgrade: true,
          upgradeMessage: 'Creating 3+ books',
        })
      : this.setState({
          showAddaBook: true,
          showUpgrade: false,
        })
  }

  _popupControl = async (callback) => {
    this.setState({
      showUpgrade: callback,
    })
  }

  _bookPopupControl = async (callback) => {
    this.setState({
      showAddaBook: callback,
    })
    if (!callback) {
      this.setState({
        error: '',
      })
    }
  }

  _createABook = async (bookData) => {
    const { bookUserId } = this.state
    this.setState({
      isCreatingABook: true,
      showAddaBook: true,
    })

    try {
      if (bookData.bookCoverImg) {
        let file = bookData.bookCoverImg
        let iPhoneExtensions = this.props.user.iPhoneExtensions

        // file type is only image.
        if (iPhoneExtensions.includes(file.name.split('.')[1])) {
          try {
            await convertHEICToAny(file).then((result) => (file = result))
          } catch {
            console.log('Converter not working.')
          }
        }
        if (/^image\//.test(file.type)) {
          try {
            await getCompressedImage(file).then((result) => (file = result))
            console.log('Image is being uploaded...')
            bookData.bookCoverImg = file
            const bookCreated = await this.props.createABook(
              bookData,
              bookUserId
            )
            if (bookCreated && bookCreated.error) {
              this.setState({
                error: bookCreated.error,
              })
            } else {
              this.setState({
                showAddaBook: false,
                error: '',
              })
            }
          } catch (err) {
            console.log(err)
          }
        } else {
          console.warn('You can only upload image files.')
        }
      } else {
        const bookCreated = await this.props.createABook(bookData, bookUserId)
        if (bookCreated && bookCreated.error) {
          this.setState({
            error: bookCreated.error,
          })
        } else {
          this.setState({
            showAddaBook: false,
            error: '',
          })
        }
      }
      this.setState({ isCreatingABook: false })
    } catch (err) {
      console.log(err)
      this.setState({ error: err, isCreatingABook: false })
    }
  }

  reorderBooks = () => {
    const { reorderMode } = this.state
    this.setState({ reorderMode: !reorderMode })
  }

  getNewOrderofBooks = (newOrder) => {
    this.setState({ newBookOrder: newOrder })
  }

  saveNewBookOrder = async () => {
    const { newBookOrder } = this.state
    const { user, updateUserBookOrder } = this.props
    const { currentUser } = this.props.user

    if (!isPaidUser(currentUser.attributes['custom:membership'])) {
      this.setState({
        showUpgrade: true,
        upgradeMessage: 'Reordering books',
      })
      return
    }

    const newBookOrderIds =
      newBookOrder &&
      newBookOrder.map((book) => {
        return book.id
      })

    const exisitingBookOrderIds =
      user.userBooks &&
      user.userBooks.map((b) => {
        return b.id
      })

    let isOrderSame = newBookOrderIds.every((el, index) => {
      return el === exisitingBookOrderIds[index]
    })

    if (
      !isOrderSame &&
      newBookOrderIds.length === exisitingBookOrderIds.length
    ) {
      try {
        await updateUserBookOrder(JSON.stringify(newBookOrderIds))
      } catch (e) {
        console.log(e)
      }
    }
  }

  render() {
    const {
      showAddaBook,
      showUpgrade,
      error,
      isCreatingABook,
      reorderMode,
      upgradeMessage,
    } = this.state
    const { userBooks, isFetchUserData, currentUser } = this.props.user

    const listOfBooks =
      userBooks && userBooks.length > 0 ? (
        <SortableBooks
          books={userBooks}
          reorderMode={reorderMode}
          callbackNewOrder={this.getNewOrderofBooks}
        />
      ) : (
        <div className="outline-book" onClick={this._showAddaBookUI}>
          <span>
            <ion-icon name="add" style={{ fontSize: 32 }}></ion-icon>
          </span>
          <span>
            Create your
            <br />
            first book!
          </span>
        </div>
      )

    return (
      <React.Fragment>
        <ul className="sub-nav sub-nav-back">
          <li>
            <span
              className="link-text trackit"
              onClick={() => this.props.history.goBack()}
              id="mybooks_subNav_go_back"
            >
              <ion-icon name="arrow-round-back"></ion-icon> Back
            </span>
          </li>
          <li>
            <span
              className="link-text trackit"
              onClick={this.reorderBooks}
              id="mybooks_re_order_books"
            >
              {reorderMode ? (
                <ion-icon
                  name="checkmark"
                  style={{ color: '#3bc183' }}
                  onClick={this.saveNewBookOrder}
                ></ion-icon>
              ) : (
                <ion-icon name="ios-swap"></ion-icon>
              )}
            </span>
          </li>
          <li>
            <span
              style={{
                pointerEvents: reorderMode ? 'none' : 'initial',
                opacity: reorderMode ? 0.4 : 1,
              }}
              className="link-text trackit"
              onClick={this._showAddaBookUI}
              id="mybooks_create_a_book"
            >
              <ion-icon name="add"></ion-icon>
            </span>
          </li>
        </ul>
        <div className="my-books Container">
          <div className="Container--side-padding">
            <h1>My books</h1>
            <p>
              {reorderMode ? (
                !isPaidUser(currentUser.attributes['custom:membership']) ? (
                  <React.Fragment>
                    <b
                      className="link-text"
                      onClick={() =>
                        this.props.history.push({
                          pathname: '/my-account/billing',
                        })
                      }
                    >
                      Become a member
                    </b>{' '}
                    to save new book order.
                  </React.Fragment>
                ) : (
                  <b>
                    Drag and drop to re-order books then click the check mark
                    icon to save
                  </b>
                )
              ) : (
                'Organize your journals with books'
              )}
            </p>
          </div>

          {isFetchUserData ? (
            <div className="my-book-loading">
              <LoadingBook />
              <LoadingBook />
              <LoadingBook />
              <LoadingBook />
            </div>
          ) : (
            <div
              className={
                reorderMode
                  ? 'Container book-list reorder-mode-dng'
                  : 'Container book-list'
              }
            >
              {listOfBooks}
            </div>
          )}

          <AddBookPopup
            showAddaBook={showAddaBook}
            bookPopupControl={this._bookPopupControl}
            createABook={this._createABook}
            isCreatingABook={isCreatingABook}
            error={error}
          />

          <UpgradePopup
            featureName={upgradeMessage}
            ctaText="Close"
            showUpgrade={showUpgrade}
            popupControl={this._popupControl}
          />
        </div>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return state
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createABook,
      getMoreUserBooks,
      getABookWithJournals,
      updateUserBookOrder,
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MyBooks))
