import React, { Component } from "react";
import { Link } from "react-router-dom";
import moment from 'moment';
import ProfilePic from "../assets/images/profile-pic.svg"

class LovedUsersListItem extends Component {
  render() {
    const { lovedUsers } = this.props;
    return (
      <React.Fragment>
        {lovedUsers.map(lovedUser => (
          <div key={lovedUser.user.id}>
            <Link to={`/author/${encodeURIComponent(lovedUser.user.id)}`}>
              <div className="author">
                <img
                  src={lovedUser.user.profileImage || ProfilePic}
                  alt="No profile pic"
                  className="profile-pic"
                />
                {lovedUser.user.displayName || lovedUser.user.id}<br /><time>{moment(lovedUser.createdAt).fromNow()}</time>
              </div>
            </Link>
          </div>
        ))}
      </React.Fragment>
    );
  }
}

export default LovedUsersListItem;
