import React, { Component } from 'react';
import { CardElement } from 'react-stripe-elements';
import loadingIcon from "../assets/images/loading.svg";

class CreditCardForm extends Component {
  render() {
    const { ctaText, creditCardSubmitForm, error, isLoading } = this.props
    return (
      <form onSubmit={creditCardSubmitForm} className="credit-card">
        <CardElement style={{base: { fontSize: '16px' }}} />
        {error ? <p className="error-msg">{error}</p> : ''}
        <button disabled={isLoading} id="billing_credit_card_submit_button">
          {isLoading ? 
          <img
            alt="Loading..."
            className="loading-icon"
            src={loadingIcon}
          /> 
          : 
          ctaText}
        </button>
      </form>
    )
  }
}

export default CreditCardForm