import React, { Component } from "react";
import ReactModal from "react-modal";
import loadingIcon from "../assets/images/loading.svg";

class EditBookPopup extends Component {
  constructor(props) {
    super(props)
    this.state = {
      book: props.book,
      bookCoverImg: props.bookCoverImg,
      removeImage: false
    }
  }

  _closeModal = () => {
    const { bookPopupControl } = this.props;
    bookPopupControl(false);
  }

  _editAbook = async (e) => {
    e.preventDefault()
    await this.props.editBook(this.state)
  }

  _onChange = async e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  _coverImageSelect = async e => {
    this.setState({ bookCoverImg: e.target.files[0] })
  }
  
  _removeImage = (e) => {
    this.setState({ removeImage: e.target.checked })
  }
  
  render() {
    const { showAddaBook, error, isUpdatingABook } = this.props
    const { book, bookCoverImg } = this.state

    return (
      <div>
        <ReactModal
          isOpen={showAddaBook}
          contentLabel="Edit book"
          onRequestClose={this._closeModal}
          style={{ overlay: { background: "rgba(0, 0, 0, 0.7" } }}
          className="modal-box book-add-modal"
        >

          <form onSubmit={this._editAbook}>
            <h1>Edit book</h1>

            <div className="form-field">
              <label>
                <code>
                  <small>Name of book</small>
                </code>
              </label>
              <input
                type="text"
                name="book"
                value={book}
                placeholder="Name of book"
                onChange={this._onChange}
                autoCorrect="off"
                autoCapitalize="none"
                maxLength="40"
                required
              />
            </div>

            <div className="form-field">
              <label htmlFor="book_cover_imgae">
                <code>
                  <small>Replace cover image(optional)</small>
                </code>
              </label>
              <input type="file" name="bookCoverImg" id="book_cover_imgae" onChange={this._coverImageSelect} />
            </div>

            {bookCoverImg && <div className="form-field">
                            <label>
                              <input
                                type="checkbox"
                                onChange={this._removeImage}
                              /> 
                              <code><small>Remove cover image</small></code>
                            </label>
                          </div>}

            {error && <p className="error-msg">{error}</p>}
            <button id="Create a book on popup" disabled={isUpdatingABook}>
              {isUpdatingABook ? 
                <img
                  alt="Loading..."
                  className="loading-icon"
                  src={loadingIcon}
                /> 
              : "Update"}
            </button>
          </form>

        </ReactModal>
      </div>
    );
  }
}

export default EditBookPopup;
