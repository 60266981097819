import React, { Component } from "react";
import { Link } from "react-router-dom";

import moment from "moment";
import decodeHtml from "../utils/DecodeHtml";

class MyJournalListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showJournalList: true
    };

    this.showJournalButton = this.showJournalButton.bind(this);
  }

  showJournalButton() {
    this.setState({
      showJournalList: !this.state.showJournalList
    });
  }

  render() {
    const { showJournalList } = this.state;
    const { month, monthJournal } = this.props;

    const monthJournalList = monthJournal.map((journal, index) => (
      <div
        className={
          "my-journal-list journal-list-item" +
          (showJournalList ? " show" : "") +
          (journal.draft ? " draft" : "")
        }
        key={index}
      >
        <Link to={(journal.privacy === "PUBLIC" && !journal.draft ? "/public-journal/" : "/journal/") + journal.id} id={"myJournal_" + journal.id}>
          <div className="journal-list-item-header">
            <time>{moment(journal.createdAt).format("llll")}</time>
            {journal.privacy === "PRIVATE" && <span className="badge badge--grey">Private</span>}{" "}
            {journal.draft && <span className="badge badge--grey">Draft</span>}
          </div>
          <div>
            <p>
              {journal.journalBookId && <span style={{ paddingRight: 4, color: "#3bc183", verticalAlign: "middle" }}><ion-icon name="book"></ion-icon></span>}
              {decodeHtml(journal.content.replace(/<(.|\n)*?>/g, " ").slice(0, 80))}
              {journal.content.replace(/<(.|\n)*?>/g, "").length > 80 && "..."} {journal.journalWordCount && <i className="word-count"> - {journal.journalWordCount} words</i>}
            </p>
          </div>
          {journal.privacy === "PUBLIC" &&
          !journal.draft ? (
            <div className="journal-list-item-footer">
              <div>
                <ion-icon name="heart"></ion-icon>{" "}
                {journal.lovedCount ? journal.lovedCount : 0}
              </div>
              <div>
                {/* TODO: get total count of comments instead of items.length */}
                <ion-icon name="ios-chatbubbles"></ion-icon>{" "}
                {journal.commentCount ? journal.commentCount : 0}
              </div>
            </div>
          ) : (
            ""
          )}
        </Link>
      </div>
    ));

    return (
      <div className="journal-list-month">
        <div
          className="journal-by-month trackit"
          id={"myJournal_month_accordion_" + month}
          onClick={this.showJournalButton}
        >
          <time>
            {month}{" "}
            <span style={{ fontWeight: "normal", textTransform: "lowercase" }}>
              / {monthJournal.length} journal
              {monthJournal.length === 1 ? "" : "s"}
            </span>
          </time>
          <ion-icon
            name={"ios-arrow-" + (showJournalList ? "up" : "down")}
          ></ion-icon>
        </div>
        {monthJournalList}
      </div>
    );
  }
}

export default MyJournalListItem;
