import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MemberListItem from "../components/MemberListItem";
import LoadingCard from "../components/LoadingCard";
import { getChallengeData, setChallengeLimit, getUserData } from "../actions/user";
import moment from "moment";
import loadingIcon from "../assets/images/loading-dark.svg";
import WeeklyJournalChallenge from "../components/WeeklyJournalChallenge";

class Challenge extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      username: props.user.accountData.idToken.payload["cognito:username"],
      memberList: [],
      myJournalThisMonth: "",
      isLoading: false,
      nextToken: null,
      isMoreLoading: false
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    const { username } = this.state;
    const { journal, user } = this.props;
    this.setState({ isLoading: true });

    try {
      // TODO: optimize this. This can be done without calling 3 different requests
      const myChallenge = await this.props.getChallengeData(username);
      const listUsers = await this.props.getChallengeData('', journal.challengeLimit);
      if (user.isAuthenticated) await this.props.getUserData(username, journal.myJournalLimit);

      if (this._isMounted)
        this.setState({
          memberList: listUsers.items,
          myJournalThisMonth: myChallenge.items,
          isLoading: false,
          nextToken: listUsers.nextToken
        });
    } catch (err) {
      console.log(err);
      this.setState({ isLoading: false });
    }
  }

  loadMore = async () => {
    this.setState({ isMoreLoading: true });
    try {
      const listUsers = await this.props.getChallengeData('', 30, this.state.nextToken);
      this.props.setChallengeLimit(this.state.memberList.length + 30)
      this.setState({
        memberList: [...this.state.memberList, ...listUsers.items],
        nextToken: listUsers.nextToken,
        isMoreLoading: false
      });
    } catch (err) {
      console.log(err);
      this.setState({ isMoreLoading: false });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  render() {
    const { memberList, myJournalThisMonth, isLoading, isMoreLoading } = this.state;
    const { user } = this.props
    const memberListRender = memberList.map(member => {
      return <MemberListItem member={member} key={member.id} />;
    });

    return(
      <div className="challenge Container">
        <div className="Container--side-padding header">
          <h1>Challenge</h1>
        </div>

        <div className="animate-fade-in-up animation-delay-04">
          <h3 className="journal-by-month">
            My progress
          </h3>


          <div className="my-stats">
            <WeeklyJournalChallenge user={user} />
            {isLoading ? <p>...</p> :
            <code>
              You wrote{" "}
              <b>
                {myJournalThisMonth && myJournalThisMonth.length > 0 ? myJournalThisMonth[0].journalsPerMonth : 0}{" "}
                {myJournalThisMonth && myJournalThisMonth.length > 0 && myJournalThisMonth[0].journalsPerMonth <= 1
                  ? "journal"
                  : "journals"}
              </b>{" "}
              this month.<br />
              {myJournalThisMonth && myJournalThisMonth.length > 0 ? "Keep it up!" : "Write one today!"}
            </code>}
          </div>

          <h3 className="journal-by-month">
            {moment(new Date()).format("MMM YYYY")} - Journal challenge
          </h3>
          {isLoading && <LoadingCard />}
          {!isLoading && memberListRender}
          {this.state.nextToken && (
            <div className="load-more">
              {isMoreLoading && (
                <img
                  alt="Loading journals"
                  className="loading-icon"
                  src={loadingIcon}
                />
              )}
              {!isMoreLoading && (
                <button className="btn-secondary" onClick={this.loadMore} id="challenge_load_more">
                  Load more
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getChallengeData,
      setChallengeLimit,
      getUserData
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Challenge);
