import React, { Component } from "react";
import DatePicker from "react-datepicker";
import { addDays } from 'date-fns';

import "react-datepicker/dist/react-datepicker.css";

class DateTimePicker extends Component{
  render() {
    const { newDate, setDate} = this.props
    return (
      <div>
        <DatePicker
          selected={setDate}
          className="select-new"
          onChange={date => {
              newDate(date)
            }
          }
          timeInputLabel="Time:"
          dateFormat="MMM d, yyyy h:mm aa"
          maxDate={addDays(new Date(), 0)}
          showTimeInput
        />
      </div>
    );
  }
}

export default DateTimePicker