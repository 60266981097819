import React, { Component } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { withRouter } from 'react-router';

import ProfilePic from "../utils/ProfilePic";

class CommentListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editMode: false,
      commentCotent: props.comment.content,
      loveLoading: false
    };
  }

  _editComment = () => {
    this.setState({ editMode: true });
  };

  _cancelEditComment = () => {
    const { comment } = this.props;
    this.setState({
      editMode: false,
      commentCotent: comment.content
    });
  };

  _editCommentHandleChange = e => {
    this.setState({ commentCotent: e.target.value });
  };

  _editCommentSave = () => {
    const { comment, updateComment } = this.props;
    const { commentCotent } = this.state;

    if (comment.content === commentCotent) {
      this.setState({ editMode: false });
    } else {
      updateComment(comment, commentCotent);
      this.setState({ editMode: false });
    }
  };

  _deleteComment = e => {
    const { comment, deleteComment } = this.props;
    const ask = window.confirm("Delete this comment?");
    if (ask) {
      deleteComment(comment.id);
    }
  };

  _userLovedComment = async () => {
    if (this.state.loveLoading) return
    const { loveComment, comment, currentUserID } = this.props;
    if(!currentUserID){
      this.props.history.push({
        pathname: "/login",
        state: { prevPath: this.props.location.pathname }
      });
      return;
    }
    this.setState({ loveLoading: true });
    await loveComment(comment, currentUserID);
    this.setState({ loveLoading: false });
  };

  _userUnlovedComment = async (comment) => {
    if (this.state.loveLoading) return
    const { unloveComment } = this.props;
    this.setState({ loveLoading: true });
    await unloveComment(comment);
    this.setState({ loveLoading: false });
  };

  render() {
    const { commentCotent, editMode, loveLoading } = this.state;
    const { comment, currentUserID } = this.props;

    const commentEditUI =
      comment.author.id === currentUserID ? (
        <div className="comment-edit-ui">
          <span onClick={this._editComment} className="trackit" id="comment_edit_button">Edit</span> /{" "}
          <span onClick={this._deleteComment} className="trackit" id="comment_delete_button">Delete</span>
        </div>
      ) : (
        ""
      );

    const editCommentFormBtns =
      comment.author.id === currentUserID ? (
        <div className="comment-edit-ui">
          <span onClick={this._cancelEditComment} className="trackit" id="comment_edit_cancel">Cancel</span> &nbsp;{" "}
          <span onClick={this._editCommentSave} className="trackit" id="comment_edit_save">Save</span>
        </div>
      ) : (
        ""
      );

    const editCommentForm = (
      <form>
        <textarea
          value={commentCotent}
          onChange={this._editCommentHandleChange}
        ></textarea>
        {editCommentFormBtns}
      </form>
    );

    const lovedUI = comment.hasLoved ? (
      <button
        className="link-btn loved"
        onClick={() => this._userUnlovedComment(comment)}
        key={comment.id}
        disabled={loveLoading}
        id="comment_unlove_button"
      >
        <ion-icon name="heart" data-id={comment.id}></ion-icon>
      </button>
    ) : (
      <button
        className="link-btn unloved"
        onClick={this._userLovedComment}
        disabled={loveLoading}
        id="comment_love_button"
      >
        <ion-icon name="heart-empty"></ion-icon>
      </button>
    );

    return (
      <div className="journal-list-item" id={comment.id}>
        <div className="author">
          <Link
            to={"/author/" + encodeURIComponent(comment.author.id)}
            id={"comment_author_id_" + comment.author.id}
          >
            <img
              src={ProfilePic(comment.author)}
              className="profile-pic"
              alt="User profile pic"
            />
          </Link>
          <time>
            <Link
              to={"/author/" + encodeURIComponent(comment.author.id)}
              id={"comment_author_id_" + comment.author.id}
            >
              <b>{comment.author.displayName
                ? comment.author.displayName
                : comment.author.id}</b>
            </Link>
            <br />
            {moment(comment.createdAt).format('llll')}
          </time>
        </div>
        {editMode ? <div>{editCommentForm}</div> : <p>{comment.content}</p>}
        <div className="journal-list-item-footer comment-list">
          <div>
            {lovedUI}&nbsp;{comment.commentLovedCount > 0 ? <Link to={`/loved/comment/${comment.id}`} id="commnet_loved_users">{comment.commentLovedCount}</Link> : 0}
          </div>
          <div>{editMode ? "" : commentEditUI}</div>
        </div>
      </div>
    );
  }
}


export default withRouter(CommentListItem);