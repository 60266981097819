import heic2any from "heic2any";
import imageCompression from "browser-image-compression";

export const convertHEICToAny = async(file) => {
  try {
    console.log("HEIC image is being converted...");
    await heic2any({
      blob: file,
      toType: "image/jpeg",
      quality: 0.8
    }).then(result => (file = result));
  } catch (error) {
    console.warn(error);
  }
  if (/^image\//.test(file.type)) {
    try {
      return file;
    } catch (err) {
      console.log(err);
    }
  } else {
    console.warn("Could not convert image at the moment, please try later.");
  }
}

export const getCompressedImage = async(imageFile) => {
  var options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 2000,
    useWebWorker: false
  };
  try {
    console.log("Image is being compressed...");
    let compressedFile = "";
    await imageCompression(imageFile, options).then(
      result => (compressedFile = result)
    );
    compressedFile.name = "converted.jpeg";
    return compressedFile;
  } catch (error) {
    console.warn("Image could not be compressed: ", error);
    return imageFile;
  }
}