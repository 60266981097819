import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import {
  getABookWithJournals,
  deleteABook,
  updateABook,
} from '../actions/journal'
import { convertHEICToAny, getCompressedImage } from '../utils/ImageProcess'

import EditBookPopup from '../components/EditBookPopup'
import LoadingCard from '../components/LoadingCard'
import LoadingBook from '../components/LoadingBook'
import BookJournalListItem from '../components/BookJournalListItem'
import loadingIcon from '../assets/images/loading-dark.svg'

class BookJournals extends Component {
  _isMounted = false

  constructor(props) {
    super(props)
    this.state = {
      book: null,
      fetchingJournals: false,
      currentUsername:
        props.user.accountData.idToken.payload['cognito:username'],
      showAddaBook: false,
      error: '',
      isUpdatingABook: false,
      newImage: false,
      bookJournalNextToken: '',
      showLoadingIcon: false,
    }
  }

  async componentDidMount() {
    this._isMounted = true
    this.setState({ fetchingJournals: true })
    const { book_uid } = this.props.match.params
    await this._getJournalsByBook(book_uid)
    this.setState({ fetchingJournals: false })
  }

  _loadMore = async () => {
    this.setState({ showLoadingIcon: true })
    const { book_uid } = this.props.match.params
    const { bookJournalNextToken } = this.state
    await this._getJournalsByBook(book_uid, bookJournalNextToken)
    this.setState({ showLoadingIcon: false })
  }

  _getJournalsByBook = async (bookID, bookJournalNextToken) => {
    const bookJournals = await this.props.getABookWithJournals(
      bookID,
      bookJournalNextToken
    )
    let newBookObj = bookJournals
    if (bookJournalNextToken) {
      let journalAdded = [
        ...this.state.book.journals.items,
        ...bookJournals.journals.items,
      ]
      newBookObj.journals.items = journalAdded
    }
    this.setState({
      book: newBookObj,
      bookJournalNextToken: bookJournals.journals.nextToken,
    })
  }

  _editPopUp = () => {
    this.setState({
      showAddaBook: true,
    })
  }

  _editBook = async (newData) => {
    const { book, currentUsername } = this.state
    this.setState({ isUpdatingABook: true })

    if (currentUsername === book.bookUserId) {
      if (newData.bookCoverImg !== book.bookCoverImg && !newData.removeImage) {
        let file = newData.bookCoverImg
        let iPhoneExtensions = this.props.user.iPhoneExtensions

        // file type is only image.
        if (iPhoneExtensions.includes(file.name.split('.')[1])) {
          try {
            await convertHEICToAny(file).then((result) => (file = result))
          } catch {
            console.log('Converter not working.')
          }
        }
        if (/^image\//.test(file.type)) {
          try {
            await getCompressedImage(file).then((result) => (file = result))
            console.log('Image is being uploaded...')
            newData.bookCoverImg = file
            const updatedBook = await this.props.updateABook({
              id: book.id,
              book: newData.book,
              bookCoverImg: newData.removeImage ? null : newData.bookCoverImg,
              bookJournalCount: newData.bookJournalCount
                ? newData.bookJournalCount
                : book.bookJournalCount,
              newImage: true,
            })
            this.setState({
              book: updatedBook,
              isUpdatingABook: false,
              showAddaBook: false,
              error: '',
            })
          } catch (err) {
            console.log(err)
            this.setState({
              error: err,
              isUpdatingABook: false,
            })
          }
        } else {
          console.warn('You could only upload images.')
          this.setState({
            error: 'You could only upload images.',
            isUpdatingABook: false,
          })
        }
      } else {
        const updatedBook = await this.props.updateABook({
          id: book.id,
          book: newData.book,
          bookCoverImg: newData.removeImage ? null : newData.bookCoverImg,
          bookJournalCount: newData.bookJournalCount
            ? newData.bookJournalCount
            : book.bookJournalCount,
          newImage: false,
        })

        this.setState({
          book: updatedBook,
          isUpdatingABook: false,
          showAddaBook: false,
          error: '',
        })
      }
    }
  }

  _deleteBook = async () => {
    const { book, currentUsername } = this.state

    if (book && book.journals.items.length > 0) {
      alert('You must remove all journals from the book to delete.')
      return
    }

    const ask = window.confirm('Do you want to permanently delete this book?')

    if (ask) {
      try {
        if (currentUsername === book.bookUserId) {
          const deletedBook = this.props.deleteABook(book.id)
          deletedBook && this.props.history.push('/books')
        } else {
          alert(
            'Error deleting the journal. Please reload the page and try again.'
          )
        }
      } catch (err) {
        console.log(err)
      }
    }
  }

  _bookPopupControl = async (callback) => {
    this.setState({
      showAddaBook: callback,
    })
    if (!callback) {
      this.setState({
        error: '',
      })
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  render() {
    const {
      book,
      fetchingJournals,
      currentUsername,
      showAddaBook,
      isUpdatingABook,
      error,
      bookJournalNextToken,
      showLoadingIcon,
    } = this.state

    const noJournalMessage =
      book && currentUsername === book.bookUserId ? (
        <div
          className="Container Container--sm"
          style={{ textAlign: 'center', paddingTop: 60 }}
        >
          <span
            onClick={() =>
              this.props.history.push('/write?bookSelected=' + book.id)
            }
            className="btn-secondary track-it btn-secondary-cta"
          >
            Write a first journal of this book{' '}
            <span role="img" aria-label="peace">
              ✌️
            </span>
          </span>
        </div>
      ) : book && book.publicJournals.items.length > 0 ? (
        <div>
          <div className="journal-list-title">
            Showing public journals from this book
          </div>
          {book.publicJournals.items.map((journal) => (
            <BookJournalListItem key={journal.id} journal={journal} />
          ))}
        </div>
      ) : (
        <div
          className="Container Container--sm"
          style={{ textAlign: 'center', paddingTop: 60 }}
        >
          <h3>
            <code>
              No public journals added
              <br />
              to this book yet{' '}
              <span role="img" aria-label="Grinning Face with Sweat">
                😅
              </span>
            </code>
          </h3>
        </div>
      )

    const editPopup = book && (
      <EditBookPopup
        showAddaBook={showAddaBook}
        bookPopupControl={this._bookPopupControl}
        editBook={this._editBook}
        book={book.book}
        bookCoverImg={book.bookCoverImg}
        isUpdatingABook={isUpdatingABook}
        error={error}
      />
    )

    const bookPresent = book && (
      <div className="Container--side-padding book-present">
        {book && book.bookCoverImg ? (
          <div
            className="book-present-cover"
            style={{
              backgroundImage: 'url(' + book.bookCoverImg + ')',
              backgroundSize: 'cover',
            }}
          ></div>
        ) : (
          <div className="book-present-cover default-book"></div>
        )}
        <h1>{book.book}</h1>
        <p>
          <code>
            by{' '}
            <b
              className="link-text"
              onClick={() =>
                this.props.history.push(
                  '/author/' + encodeURIComponent(book.bookUserId)
                )
              }
            >
              {book.user.displayName ? book.user.displayName : book.bookUserId}
            </b>{' '}
            / {book.bookJournalCount ? book.bookJournalCount : 0} journal
            {book.bookJournalCount > 1 && 's'}{' '}
          </code>
        </p>
      </div>
    )

    return (
      <React.Fragment>
        {/* Only show Edit and Delete UI to the owner */}
        {book && currentUsername === book.bookUserId ? (
          <ul className="sub-nav sub-nav-back">
            <li>
              <span
                className="link-text trackit"
                onClick={() => this.props.history.goBack()}
                id="mybooks_subNav_go_back"
              >
                <ion-icon name="arrow-round-back"></ion-icon> Back
              </span>
            </li>
            <li
              className="link-text trackit"
              id="mybooks_subNav_edit"
              onClick={this._editPopUp}
            >
              <ion-icon name="brush"></ion-icon>
            </li>
            <li
              className="link-text trackit"
              id="mybooks_subNav_delete"
              onClick={this._deleteBook}
            >
              <ion-icon name="trash"></ion-icon>
            </li>
          </ul>
        ) : (
          <ul className="sub-nav sub-nav-back">
            <li>
              <span
                className="link-text trackit"
                onClick={() => this.props.history.goBack()}
                id="mybooks_subNav_go_back"
              >
                <ion-icon name="arrow-round-back"></ion-icon> Back
              </span>
            </li>
            <li></li>
            <li></li>
          </ul>
        )}

        <div className="book-journals Container">
          {fetchingJournals ? (
            <div className="book-journals">
              <LoadingBook />
              <LoadingCard />
            </div>
          ) : (
            <React.Fragment>
              {bookPresent}
              <div className="Container">
                {book && book.journals.items.length > 0 ? (
                  <React.Fragment>
                    <div
                      className="Container Container--sm"
                      style={{
                        textAlign: 'center',
                        padding: 16,
                        marginBottom: 24,
                      }}
                    >
                      <span
                        onClick={() =>
                          this.props.history.push(
                            '/write?bookSelected=' + book.id
                          )
                        }
                        className="btn-secondary track-it btn-secondary-cta"
                      >
                        Write a journal in this book
                      </span>
                    </div>
                    {book.journals.items.map((journal) => (
                      <BookJournalListItem key={journal.id} journal={journal} />
                    ))}
                    {bookJournalNextToken && (
                      <div className="load-more">
                        {showLoadingIcon ? (
                          <img
                            alt="Loading journals"
                            className="loading-icon"
                            src={loadingIcon}
                          />
                        ) : (
                          <button
                            className="btn-secondary"
                            onClick={this._loadMore}
                          >
                            Load more
                          </button>
                        )}
                      </div>
                    )}
                  </React.Fragment>
                ) : (
                  noJournalMessage
                )}
              </div>
            </React.Fragment>
          )}
        </div>

        {editPopup}
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return state
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getABookWithJournals,
      deleteABook,
      updateABook,
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(BookJournals)
