import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Masonry from 'masonry-layout';

import PublicJournalListItem from "../components/PublicJournalListItem";
import { getPublicJournals, searchPublicJournals, setCommunityLimit } from "../actions/journal";
import LoadingCard from "../components/LoadingCard";
import loadingIcon from "../assets/images/loading-dark.svg";

class Community extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      showSearch: false,
      publicJournals: {
        items: []
      },
      loadingJournals: false,
      showLoadingIcon: false,
      searchText: ""
    };

    this.showSearchUI = this.showSearchUI.bind(this);
    this.searchJournals = this.searchJournals.bind(this);
  }

  async componentDidMount() {
    this._isMounted = true;
    this.setState({ loadingJournals: true });
    const { getPublicJournals, journal } = this.props;

    try {
      const data = await getPublicJournals(journal.communityLimit)
      if (!this._isMounted) return
      this.setState({
        publicJournals: data,
        loadingJournals: false
      });
    } catch (err) {
      console.log(err);
    }
  }

  invokeMasonry() {
    let elem = document.querySelector('.masonry')
    new Masonry( elem, {
      itemSelector: '.journal-card',
      percentPosition: true
    });
  }

  invokeMasonryAgain = () => {
    this.invokeMasonry();
  }

  async searchJournals(event) {
    if (event.key === "Enter" || event.target.value === "") {
      const { searchPublicJournals, getPublicJournals } = this.props;
      this.setState({ loadingJournals: true, searchText: event.target.value });
      const { currentUser } = this.props.user;
      if(!currentUser){
        this.props.history.push({
          pathname: "/login",
          state: { prevPath: this.props.location.pathname }
        });
        return;
      }
      if (event.target.value !== "") {
        try {
          searchPublicJournals(event.target.value).then(data => {
            if (data) {
              this.setState({
                publicJournals: data,
                loadingJournals: false
              });
            } else {
              this.setState({
                publicJournals: { items: [] },
                loadingJournals: false
              });
            }
          });
        } catch (err) {
          console.log(err);
        }
      } else {
        try {
          getPublicJournals().then(data => {
            if (data) {
              this.setState({
                publicJournals: data,
                loadingJournals: false
              });
            } else {
              this.setState({
                publicJournals: { items: [] },
                loadingJournals: false
              });
            }
          });
        } catch (err) {
          console.log(err);
        }
      }
    }
  }

  showSearchUI() {
    this.setState({
      showSearch: !this.state.showSearch
    });
  }

  _loadMore = async () => {
    const { publicJournals, showSearch, searchText } = this.state;
    this.setState({ showLoadingIcon: true });
    let data;
    try {
      if (showSearch && searchText) {
        data = await this.props.searchPublicJournals(searchText, publicJournals.nextToken)
      } else {
        data = await this.props.getPublicJournals(30, publicJournals.nextToken)
        this.props.setCommunityLimit(publicJournals.items.length + 30)
      }
      this.setState({
        publicJournals: {
          items: [...publicJournals.items, ...data.items],
          nextToken: data.nextToken
        },
        showLoadingIcon: false
      });
      this.invokeMasonry();
    } catch (err) {
      console.log(err);
      this.setState({ showLoadingIcon: false });
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const {
      showSearch,
      publicJournals,
      loadingJournals,
      showLoadingIcon
    } = this.state;
    const { user } = this.props;
    // console.log(publicJournals)

    const jornalList = loadingJournals ? (
      <div>
        <LoadingCard />
        <LoadingCard />
      </div>
    ) : publicJournals && publicJournals.items.length > 0 ? (
      publicJournals.items.map(journal => (
        <PublicJournalListItem key={journal.id} journal={journal} user={user} imageLoaded={this.invokeMasonryAgain} />
      ))
    ) : (
      <div className="Container Container--sm sm-form">
        <h2>Nothing found <span role="img" aria-label="Sweat">😅</span></h2>
        <p>Please search with different keywords(case-sensitive) or reload the page.</p>
      </div>
    );

    return (
      <div className="community">
        <div className="Container Container--side-padding">
          <h1>Community</h1>
        </div>

        <div className="Container journal-list-title">
          {publicJournals && publicJournals.items.length} public journal
          {publicJournals && publicJournals.items.length > 1 ? "s" : ""} loaded
          <ion-icon
            className="trackit"
            id="community_search_icon"
            name={showSearch ? "close" : "search"}
            onClick={this.showSearchUI}
          ></ion-icon>
        </div>
        <div className={"Container search-journal" + (showSearch ? " show" : "")}>
          <input
            type="text"
            placeholder="Search public journal"
            autoCapitalize="none"
            onKeyUp={event => this.searchJournals(event)}
          />
        </div>

        <div className="Container journal-list">
          <div className="animate-fade-in-up masonry" onLoad={this.invokeMasonry}>
            {jornalList}
          </div>
          {(publicJournals && publicJournals.nextToken) && (
            <div className="load-more">
              {showLoadingIcon ? (
                <img
                  alt="Loading journals"
                  className="loading-icon"
                  src={loadingIcon}
                />
              ) : (
                <button className="btn-secondary" onClick={this._loadMore} id="community_load_more">
                  Load more
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getPublicJournals,
      searchPublicJournals,
      setCommunityLimit
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Community);
