import React, { Component } from 'react';

import followIconNoProfilePic from '../assets/images/profile-pic.svg'
import moment from 'moment';

class NotificationListItem extends Component{
  readNotification = () => {
    const { notification, readNotification, history } = this.props
    if(!notification.read) {
      readNotification(notification.id)
    }
    history.push(notification.link)    
  }

  render() {
    const { notification } = this.props

    return(
      <div onClick={this.readNotification} className={`notification-list-item trackit ${notification.read ? "" : "unread"}`} id={"notification_list_item_" + notification.id}>
        <div className="author">
          <img src={notification.actionUser.photoURL || followIconNoProfilePic} className="profile-pic" alt="Notification icon" />
        </div>
        <div>
          <code><b>{notification.actionUser.displayName || notification.actionUser.userID}</b></code> {notification.content}.
          <br />
          <time>{moment(notification.createdAt).fromNow()}</time>
        </div>
        <div>
          <span className="badge badge--grey">{notification.category}</span>
        </div>
      </div>
    )
  }
}

export default NotificationListItem
