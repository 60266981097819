import React, { Component } from 'react';
import { connect } from 'react-redux';

import profilePic from '../assets/images/profile-pic.svg'

class MemberLevel extends Component{
  render() {
    return(
      <div className="member-levels">
        <div className="Container Container--side-padding">
          <h1>Member levels</h1>
          <p>Level up your account by writing journals and motivating others to do the same :)</p>
          <div className="level-list-item">
            <div><img src={profilePic} alt="No profile pic" className="profile-pic lvl-1" /> &nbsp; <b>Level 1</b></div>
            <div>Write 10 journals</div>
          </div>
          <div className="level-list-item">
            <div><img src={profilePic} alt="No profile pic" className="profile-pic lvl-2" /> &nbsp; <b>Level 2</b></div>
            <div>Write 30 journals</div>
          </div>
          <div className="level-list-item">
            <div><img src={profilePic} alt="No profile pic" className="profile-pic lvl-3" /> &nbsp; <b>Level 3</b></div>
            <div>Write 5 comments & like 5 journals</div>
          </div>
          <div className="level-list-item">
            <div><img src={profilePic} alt="No profile pic" className="profile-pic lvl-4" /> &nbsp; <b>Level 4</b></div>
            <div>Write 90 journals</div>
          </div>
          <div className="level-list-item">
            <div><img src={profilePic} alt="No profile pic" className="profile-pic lvl-5" /> &nbsp; <b>Level 5</b></div>
            <div>Write 10 comments & like 10 journals & follow 10 authors</div>
          </div>
          <div className="level-list-item">
            <div><img src={profilePic} alt="No profile pic" className="profile-pic lvl-6" /> &nbsp; <b>Level 6</b></div>
            <div>Write 365 journals & complete 1 month challenge</div>
          </div>
          <div className="level-list-item">
            <div><img src={profilePic} alt="No profile pic" className="profile-pic lvl-7" /> &nbsp; <b>Level 7</b></div>
            <div>Write 20 comments & like 20 journals & follow 20 authors</div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return state
}

export default connect(mapStateToProps)(MemberLevel)
