export const gnjAmplifyStripeCreateMember = /* GraphQL */ `
  mutation GnjAmplifyStripeCreateMember(
    $stripeToken: String!
    $username: String!
    $email: String!
    $uuid: String!
    $membershipPlanSelected: String!
    $stripeCouponId: String
    $stripeCustomerId: String
  ) {
    gnjAmplifyStripeCreateMember(
      stripeToken: $stripeToken
      username: $username
      email: $email
      uuid: $uuid
      membershipPlanSelected: $membershipPlanSelected
      stripeCouponId: $stripeCouponId
      stripeCustomerId: $stripeCustomerId
    )
  }
`
export const gnjAmplifyStripeGetCouponCode = `mutation GnjAmplifyStripeGetCouponCode($stripeCouponId: String!) {
  gnjAmplifyStripeGetCouponCode(stripeCouponId: $stripeCouponId)
}
`
export const gnjAmplifyStripeUpdateCustomer = `mutation GnjAmplifyStripeUpdateCustomer(
  $stripeCustomerId: String!
  $stripeToken: String
  $email: String
) {
  gnjAmplifyStripeUpdateCustomer(
    stripeCustomerId: $stripeCustomerId
    stripeToken: $stripeToken
    email: $email
  )
}
`
export const gnjAmplifyupdateCustomerCreditCard = `mutation GnjAmplifyupdateCustomerCreditCard(
  $stripeCustomerId: String!
  $stripeToken: String!
) {
  gnjAmplifyupdateCustomerCreditCard(
    stripeCustomerId: $stripeCustomerId
    stripeToken: $stripeToken
  )
}
`
export const gnjAmplifyStripeCancelMembership = /* GraphQL */ `
  mutation GnjAmplifyStripeCancelMembership(
    $stripeSubscriptionId: String!
    $username: String!
    $cancel: Boolean!
    $membershipPlan: String
  ) {
    gnjAmplifyStripeCancelMembership(
      stripeSubscriptionId: $stripeSubscriptionId
      username: $username
      cancel: $cancel
      membershipPlan: $membershipPlan
    )
  }
`
export const createFollowing = /* GraphQL */ `
  mutation CreateFollowing($input: CreateFollowingInput!) {
    createFollowing(input: $input) {
      id
      user
      following
      followingProfile
      followerProfile
      createdAt
      updatedAt
    }
  }
`
export const deleteFollowing = /* GraphQL */ `
  mutation DeleteFollowing($input: DeleteFollowingInput!) {
    deleteFollowing(input: $input) {
      id
      user
      following
      followingProfile
      followerProfile
      createdAt
      updatedAt
    }
  }
`
export const createUser = `mutation CreateUser($input: CreateUserInput!) {
  createUser(input: $input) {
    id
    uuid
  }
}
`
export const updateUser = `mutation UpdateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    id
    uuid
    displayName
    profileImage
    membership
    totalJournal
    followingCount
    followerCount
    bookOrder
    journals {
      items {
        id
        privacy
        draft
        content
        journalAuthorId
        markedAs
        createdAt
        updatedAt
      }
      nextToken
    }
    comments {
      items {
        id
        content
        createdAt
        updatedAt
      }
      nextToken
    }
    lovedJournals {
      items {
        id
        createdAt
      }
      nextToken
    }
    books(limit: 30, sortDirection: DESC) {
      items {
        id
        book
        bookUserId
        bookCoverImg
        bookJournalCount
        createdAt
        updatedAt
      }
      nextToken
    }
    publicJournals {
      items {
        id
        privacy
        content
        journalAuthorId
        markedAs
        lovedCount
        commentCount
        journalWordCount
        publicJournalBookId
        createdAt
        updatedAt
      }
      nextToken
    }
    lovedComments {
      items {
        id
        createdAt
      }
      nextToken
    }
    notifications {
      items {
        id
        content
        category
        link
        read
        actionUser {
          userID
          photoURL
          displayName
        }
        user {
          id
        }
      }
      nextToken
    }
    createdAt
    updatedAt
  }
}
`
export const createUserLovedJournal = /* GraphQL */ `
  mutation CreateUserLovedJournal($input: CreateUserLovedJournalInput!) {
    createUserLovedJournal(input: $input) {
      id
      userLovedJournalUserId
      userLovedJournalJournalId
      createdAt
      user {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        journals {
          nextToken
        }
        createdAt
        updatedAt
        lovedJournals {
          nextToken
        }
        publicJournals {
          nextToken
        }
        lovedComments {
          nextToken
        }
        comments {
          nextToken
        }
        notifications {
          nextToken
        }
      }
    }
  }
`
export const updateUserLovedJournal = `mutation UpdateUserLovedJournal(
  $input: UpdateUserLovedJournalInput!
  $nextTokenComment: String
) {
  updateUserLovedJournal(input: $input) {
    id
    journal {
      id
      privacy
      draft
      content
      markedAs
      author {
        id
        uuid
        displayName
        profileImage
        createdAt
        updatedAt
      }
      lovedUsers {
        items{
          id
          user{
            id
            displayName
            profileImage
          }
        }
        nextToken
      }
      comments(limit: 30, sortDirection: ASC, nextToken: $nextTokenComment){
        items {
          id
          content
          createdAt
          updatedAt
          author {
            id
            uuid
            displayName
            profileImage
          }
          lovedUsers {
            items{
              id
              user{
                id
                displayName
                profileImage
              }
            }
            nextToken
          }
        }
        nextToken
      }
      createdAt
      updatedAt
    }
      createdAt
      updatedAt
    }
    user {
      id
      uuid
      displayName
      profileImage
      journals {
        nextToken
      }
      comments {
        nextToken
      }
      lovedJournals {
        nextToken
      }
      lovedComments {
        nextToken
      }
      notifications {
        nextToken
      }
      createdAt
      updatedAt
    }
    createdAt
  }
}
`
export const deleteUserLovedJournal = /* GraphQL */ `
  mutation DeleteUserLovedJournal($input: DeleteUserLovedJournalInput!) {
    deleteUserLovedJournal(input: $input) {
      id
      userLovedJournalUserId
      userLovedJournalJournalId
      createdAt
      user {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        journals {
          nextToken
        }
        createdAt
        updatedAt
        lovedJournals {
          nextToken
        }
        publicJournals {
          nextToken
        }
        lovedComments {
          nextToken
        }
        comments {
          nextToken
        }
        notifications {
          nextToken
        }
      }
    }
  }
`
export const createJournal = `mutation CreateJournal($input: CreateJournalInput!) {
  createJournal(input: $input) {
    id
    privacy
    draft
    content
    markedAs
    lovedCount
    commentCount
    journalWordCount
    journalAuthorId
    journalBookId
    author {
      id
      uuid
      displayName
      journals {
        nextToken
      }
      comments {
        nextToken
      }
      lovedJournals {
        nextToken
      }
      lovedComments {
        nextToken
      }
      notifications {
        nextToken
      }
      createdAt
      updatedAt
    }
    book {
      id
      book
      bookUserId
      bookCoverImg
      bookJournalCount
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
  }
}
`
export const updateJournal = `mutation UpdateJournal(
  $input: UpdateJournalInput!
) {
  updateJournal(input: $input) {
    id
    privacy
    draft
    content
    markedAs
    lovedCount
    commentCount
    journalWordCount
    journalAuthorId
    journalBookId
    author {
      id
      uuid
      displayName
      profileImage
      journals {
        nextToken
      }
      comments {
        nextToken
      }
      lovedJournals {
        nextToken
      }
      lovedComments {
        nextToken
      }
      notifications {
        nextToken
      }
      createdAt
      updatedAt
    }
    book {
      id
      book
      bookUserId
      bookCoverImg
      bookJournalCount
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
  }
}
`
export const deleteJournal = `mutation DeleteJournal($input: DeleteJournalInput!) {
  deleteJournal(input: $input) {
    id
    privacy
    draft
    content
    markedAs
    lovedCount
    commentCount
    journalWordCount
    journalAuthorId
    journalBookId
    author {
      id
      uuid
      displayName
      journals {
        nextToken
      }
      comments {
        nextToken
      }
      lovedJournals {
        nextToken
      }
      lovedComments {
        nextToken
      }
      notifications {
        nextToken
      }
      createdAt
      updatedAt
    }
    book {
      id
      book
      bookUserId
      bookCoverImg
      bookJournalCount
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
  }
}
`
export const createUserLovedComment = /* GraphQL */ `
  mutation CreateUserLovedComment($input: CreateUserLovedCommentInput!) {
    createUserLovedComment(input: $input) {
      id
      userLovedCommentUserId
      userLovedCommentCommentId
      createdAt
      user {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        journals {
          nextToken
        }
        createdAt
        updatedAt
        lovedJournals {
          nextToken
        }
        publicJournals {
          nextToken
        }
        lovedComments {
          nextToken
        }
        comments {
          nextToken
        }
        notifications {
          nextToken
        }
      }
      comment {
        id
        content
        createdAt
        updatedAt
        author {
          id
          uuid
          displayName
          profileImage
          membership
          totalJournal
          followingCount
          followerCount
          createdAt
          updatedAt
        }
        journal {
          id
          privacy
          content
          journalAuthorId
          markedAs
          lovedCount
          commentCount
          journalWordCount
          createdAt
          updatedAt
        }
        lovedUsers {
          nextToken
        }
      }
    }
  }
`
export const updateUserLovedComment = `mutation UpdateUserLovedComment(
  $input: UpdateUserLovedCommentInput!
  $nextTokenComment: String
) {
  updateUserLovedComment(input: $input) {
    id
    comment {
      id
      content
      author {
        id
        uuid
        displayName
        createdAt
        updatedAt
      }
      journal {
        id
        privacy
        draft
        content
        author {
          id
          uuid
          displayName
          createdAt
          updatedAt
          profileImage
        }
        lovedUsers {
          items{
            id
            user{
              id
              displayName
              profileImage
            }
          }
          nextToken
        }
        comments(limit: 30, sortDirection: ASC, nextToken: $nextTokenComment){
          items {
            id
            content
            createdAt
            updatedAt
            author {
              id
              uuid
              displayName
              profileImage
            }
            lovedUsers {
              items{
                id
                user{
                  id
                  displayName
                  profileImage
                }
              }
              nextToken
            }
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      lovedUsers {
        nextToken
      }
      createdAt
      updatedAt
    }
    user {
      id
      uuid
      displayName
      profileImage
      journals {
        nextToken
      }
      comments {
        nextToken
      }
      lovedJournals {
        nextToken
      }
      lovedComments {
        nextToken
      }
      notifications {
        nextToken
      }
      createdAt
      updatedAt
    }
    createdAt
  }
}
`
export const deleteUserLovedComment = `mutation DeleteUserLovedComment(
  $input: DeleteUserLovedCommentInput!
) {
  deleteUserLovedComment(input: $input) {
    id
    createdAt
  }
}
`
export const createComment = /* GraphQL */ `
  mutation CreateComment($input: CreateCommentInput!) {
    createComment(input: $input) {
      id
      content
      commentLovedCount
      createdAt
      updatedAt
      author {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        journals {
          nextToken
        }
        createdAt
        updatedAt
        lovedJournals {
          nextToken
        }
        publicJournals {
          nextToken
        }
        lovedComments {
          nextToken
        }
        comments {
          nextToken
        }
        notifications {
          nextToken
        }
      }
      journal {
        id
        privacy
        content
        journalAuthorId
        markedAs
        lovedCount
        commentCount
        journalWordCount
        createdAt
        updatedAt
        author {
          id
          uuid
          displayName
          profileImage
          membership
          totalJournal
          followingCount
          followerCount
          createdAt
          updatedAt
        }
        lovedUsers {
          nextToken
        }
        comments {
          nextToken
        }
      }
      lovedUsers {
        items {
          id
          userLovedCommentUserId
          userLovedCommentCommentId
          createdAt
        }
        nextToken
      }
    }
  }
`
export const updateComment = /* GraphQL */ `
  mutation UpdateComment($input: UpdateCommentInput!) {
    updateComment(input: $input) {
      id
      content
      commentLovedCount
      createdAt
      updatedAt
      author {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        journals {
          nextToken
        }
        createdAt
        updatedAt
        lovedJournals {
          nextToken
        }
        publicJournals {
          nextToken
        }
        lovedComments {
          nextToken
        }
        comments {
          nextToken
        }
        notifications {
          nextToken
        }
      }
      journal {
        id
        privacy
        content
        journalAuthorId
        markedAs
        lovedCount
        commentCount
        journalWordCount
        createdAt
        updatedAt
        author {
          id
          uuid
          displayName
          profileImage
          membership
          totalJournal
          followingCount
          followerCount
          createdAt
          updatedAt
        }
        lovedUsers {
          nextToken
        }
        comments {
          nextToken
        }
      }
      lovedUsers {
        items {
          id
          userLovedCommentUserId
          userLovedCommentCommentId
          createdAt
        }
        nextToken
      }
    }
  }
`
export const deleteComment = /* GraphQL */ `
  mutation DeleteComment($input: DeleteCommentInput!) {
    deleteComment(input: $input) {
      id
      content
      commentLovedCount
      createdAt
      updatedAt
      author {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        journals {
          nextToken
        }
        createdAt
        updatedAt
        lovedJournals {
          nextToken
        }
        publicJournals {
          nextToken
        }
        lovedComments {
          nextToken
        }
        comments {
          nextToken
        }
        notifications {
          nextToken
        }
      }
      journal {
        id
        privacy
        content
        journalAuthorId
        markedAs
        lovedCount
        commentCount
        journalWordCount
        createdAt
        updatedAt
        author {
          id
          uuid
          displayName
          profileImage
          membership
          totalJournal
          followingCount
          followerCount
          createdAt
          updatedAt
        }
        lovedUsers {
          nextToken
        }
        comments {
          nextToken
        }
      }
      lovedUsers {
        items {
          id
          userLovedCommentUserId
          userLovedCommentCommentId
          createdAt
        }
        nextToken
      }
    }
  }
`
export const gnjAmplifyadminDisableUser = `mutation GnjAmplifyadminDisableUser($username: String!) {
  gnjAmplifyadminDisableUser(username: $username)
}
`
export const createChallenge = `mutation CreateChallenge($input: CreateChallengeInput!) {
  createChallenge(input: $input) {
    id
    yearMonth
    user
    userDisplayName
    userProfileImage
    journalsPerMonth
    journalWordCountPerMonth
    createdAt
    updatedAt
  }
}
`
export const updateChallenge = `mutation UpdateChallenge($input: UpdateChallengeInput!) {
  updateChallenge(input: $input) {
    id
    yearMonth
    user
    userDisplayName
    userProfileImage
    journalsPerMonth
    journalWordCountPerMonth
    createdAt
    updatedAt
  }
}
`
export const deleteChallenge = `mutation DeleteChallenge($input: DeleteChallengeInput!) {
  deleteChallenge(input: $input) {
    id
    yearMonth
    user
    userDisplayName
    userProfileImage
    journalsPerMonth
    journalWordCountPerMonth
    createdAt
    updatedAt
  }
}
`
export const createPublicJournal = /* GraphQL */ `
  mutation CreatePublicJournal($input: CreatePublicJournalInput!) {
    createPublicJournal(input: $input) {
      id
      privacy
      content
      journalAuthorId
      markedAs
      lovedCount
      commentCount
      journalWordCount
      publicJournalBookId
      createdAt
      updatedAt
      author {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        journals {
          nextToken
        }
        createdAt
        updatedAt
        lovedJournals {
          nextToken
        }
        publicJournals {
          nextToken
        }
        lovedComments {
          nextToken
        }
        comments {
          nextToken
        }
        notifications {
          nextToken
        }
      }
      lovedUsers {
        items {
          id
          userLovedJournalUserId
          userLovedJournalJournalId
          createdAt
        }
        nextToken
      }
      book {
        id
        book
        bookUserId
        bookCoverImg
        bookJournalCount
        journals {
          nextToken
        }
        createdAt
        updatedAt
        user {
          id
          uuid
          displayName
          profileImage
          membership
          totalJournal
          followingCount
          followerCount
          createdAt
          updatedAt
        }
        publicJournals {
          nextToken
        }
      }
      comments {
        items {
          id
          content
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`
export const updatePublicJournal = /* GraphQL */ `
  mutation UpdatePublicJournal($input: UpdatePublicJournalInput!) {
    updatePublicJournal(input: $input) {
      id
      privacy
      content
      journalAuthorId
      markedAs
      lovedCount
      commentCount
      journalWordCount
      publicJournalBookId
      createdAt
      updatedAt
      author {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        journals {
          nextToken
        }
        createdAt
        updatedAt
        lovedJournals {
          nextToken
        }
        publicJournals {
          nextToken
        }
        lovedComments {
          nextToken
        }
        comments {
          nextToken
        }
        notifications {
          nextToken
        }
      }
      lovedUsers {
        items {
          id
          userLovedJournalUserId
          userLovedJournalJournalId
          createdAt
        }
        nextToken
      }
      book {
        id
        book
        bookUserId
        bookCoverImg
        bookJournalCount
        journals {
          nextToken
        }
        createdAt
        updatedAt
        user {
          id
          uuid
          displayName
          profileImage
          membership
          totalJournal
          followingCount
          followerCount
          createdAt
          updatedAt
        }
        publicJournals {
          nextToken
        }
      }
      comments {
        items {
          id
          content
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`
export const deletePublicJournal = /* GraphQL */ `
  mutation DeletePublicJournal($input: DeletePublicJournalInput!) {
    deletePublicJournal(input: $input) {
      id
      privacy
      content
      journalAuthorId
      markedAs
      lovedCount
      commentCount
      journalWordCount
      publicJournalBookId
      createdAt
      updatedAt
      author {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        journals {
          nextToken
        }
        createdAt
        updatedAt
        lovedJournals {
          nextToken
        }
        publicJournals {
          nextToken
        }
        lovedComments {
          nextToken
        }
        comments {
          nextToken
        }
        notifications {
          nextToken
        }
      }
      lovedUsers {
        items {
          id
          userLovedJournalUserId
          userLovedJournalJournalId
          createdAt
        }
        nextToken
      }
      book {
        id
        book
        bookUserId
        bookCoverImg
        bookJournalCount
        journals {
          nextToken
        }
        createdAt
        updatedAt
        user {
          id
          uuid
          displayName
          profileImage
          membership
          totalJournal
          followingCount
          followerCount
          createdAt
          updatedAt
        }
        publicJournals {
          nextToken
        }
      }
      comments {
        items {
          id
          content
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`
export const gnjAmplifyExpoSendNotification = /* GraphQL */ `
  mutation GnjAmplifyExpoSendNotification(
    $sendTo: String!
    $title: String!
    $body: String
  ) {
    gnjAmplifyExpoSendNotification(sendTo: $sendTo, title: $title, body: $body)
  }
`
export const createBook = /* GraphQL */ `
  mutation CreateBook($input: CreateBookInput!) {
    createBook(input: $input) {
      id
      book
      bookUserId
      bookCoverImg
      bookJournalCount
      journals {
        items {
          id
          privacy
          draft
          content
          journalAuthorId
          markedAs
          lovedCount
          commentCount
          journalWordCount
          journalBookId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      user {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        journals {
          nextToken
        }
        createdAt
        updatedAt
        lovedJournals {
          nextToken
        }
        books {
          nextToken
        }
        publicJournals {
          nextToken
        }
        lovedComments {
          nextToken
        }
        comments {
          nextToken
        }
        notifications {
          nextToken
        }
      }
      publicJournals {
        items {
          id
          privacy
          content
          journalAuthorId
          markedAs
          lovedCount
          commentCount
          journalWordCount
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`
export const updateBook = /* GraphQL */ `
  mutation UpdateBook($input: UpdateBookInput!) {
    updateBook(input: $input) {
      id
      book
      bookUserId
      bookCoverImg
      bookJournalCount
      journals {
        items {
          id
          privacy
          draft
          content
          journalAuthorId
          markedAs
          lovedCount
          commentCount
          journalWordCount
          journalBookId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      user {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        journals {
          nextToken
        }
        createdAt
        updatedAt
        lovedJournals {
          nextToken
        }
        books {
          nextToken
        }
        publicJournals {
          nextToken
        }
        lovedComments {
          nextToken
        }
        comments {
          nextToken
        }
        notifications {
          nextToken
        }
      }
      publicJournals {
        items {
          id
          privacy
          content
          journalAuthorId
          markedAs
          lovedCount
          commentCount
          journalWordCount
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`
export const deleteBook = /* GraphQL */ `
  mutation DeleteBook($input: DeleteBookInput!) {
    deleteBook(input: $input) {
      id
      book
      bookUserId
      bookCoverImg
      bookJournalCount
      createdAt
      updatedAt
    }
  }
`
export const gnjAmplifyJournalCommentCount = /* GraphQL */ `
  mutation GnjAmplifyJournalCommentCount(
    $journalId: String!
    $commentCount: Int!
  ) {
    gnjAmplifyJournalCommentCount(
      journalId: $journalId
      commentCount: $commentCount
    )
  }
`
export const gnjAmplifyJournalLovedCount = /* GraphQL */ `
  mutation GnjAmplifyJournalLovedCount($journalId: String!, $lovedCount: Int!) {
    gnjAmplifyJournalLovedCount(journalId: $journalId, lovedCount: $lovedCount)
  }
`
export const gnjAmplifyForgotUsername = /* GraphQL */ `
  mutation GnjAmplifyForgotUsername($email: String!) {
    gnjAmplifyForgotUsername(email: $email)
  }
`
export const createChatRoomUser = /* GraphQL */ `
  mutation CreateChatRoomUser($input: CreateChatRoomUserInput!) {
    createChatRoomUser(input: $input) {
      id
      chatRoomUserUserId
      chatRoomUserChatRoomId
      members
      chatRoom {
        id
        createdBy
        receiverHasRead
        chatRoomUsers {
          nextToken
        }
        messages {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      user {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        journals {
          nextToken
        }
        chatRoomUser {
          nextToken
        }
        chatMassages {
          nextToken
        }
        createdAt
        updatedAt
        lovedJournals {
          nextToken
        }
        books {
          nextToken
        }
        publicJournals {
          nextToken
        }
        lovedComments {
          nextToken
        }
        comments {
          nextToken
        }
        notifications {
          nextToken
        }
      }
    }
  }
`
export const deleteChatRoomUser = /* GraphQL */ `
  mutation DeleteChatRoomUser($input: DeleteChatRoomUserInput!) {
    deleteChatRoomUser(input: $input) {
      id
      chatRoomUserUserId
      chatRoomUserChatRoomId
      members
      chatRoom {
        id
        createdBy
        receiverHasRead
        chatRoomUsers {
          nextToken
        }
        messages {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      user {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        journals {
          nextToken
        }
        chatRoomUser {
          nextToken
        }
        chatMassages {
          nextToken
        }
        createdAt
        updatedAt
        lovedJournals {
          nextToken
        }
        books {
          nextToken
        }
        publicJournals {
          nextToken
        }
        lovedComments {
          nextToken
        }
        comments {
          nextToken
        }
        notifications {
          nextToken
        }
      }
    }
  }
`
export const createChatRoom = /* GraphQL */ `
  mutation CreateChatRoom($input: CreateChatRoomInput!) {
    createChatRoom(input: $input) {
      id
      createdBy
      receiverHasRead
      chatRoomUsers {
        items {
          id
          chatRoomUserUserId
          chatRoomUserChatRoomId
          members
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          content
          messageChatRoomId
          messageUserId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`
export const createMessage = /* GraphQL */ `
  mutation CreateMessage($input: CreateMessageInput!) {
    createMessage(input: $input) {
      id
      content
      messageChatRoomId
      messageUserId
      chatRoom {
        id
        createdBy
        receiverHasRead
        chatRoomUsers {
          nextToken
        }
        messages {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      user {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        journals {
          nextToken
        }
        chatRoomUser {
          nextToken
        }
        chatMassages {
          nextToken
        }
        createdAt
        updatedAt
        lovedJournals {
          nextToken
        }
        books {
          nextToken
        }
        publicJournals {
          nextToken
        }
        lovedComments {
          nextToken
        }
        comments {
          nextToken
        }
        notifications {
          nextToken
        }
      }
    }
  }
`
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage($input: DeleteMessageInput!) {
    deleteMessage(input: $input) {
      id
      content
      messageChatRoomId
      messageUserId
      chatRoom {
        id
        createdBy
        receiverHasRead
        chatRoomUsers {
          nextToken
        }
        messages {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      user {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        journals {
          nextToken
        }
        chatRoomUser {
          nextToken
        }
        chatMassages {
          nextToken
        }
        createdAt
        updatedAt
        lovedJournals {
          nextToken
        }
        books {
          nextToken
        }
        publicJournals {
          nextToken
        }
        lovedComments {
          nextToken
        }
        comments {
          nextToken
        }
        notifications {
          nextToken
        }
      }
    }
  }
`
export const updateChatRoomUser = /* GraphQL */ `
  mutation UpdateChatRoomUser($input: UpdateChatRoomUserInput!) {
    updateChatRoomUser(input: $input) {
      id
      chatRoomUserUserId
      chatRoomUserChatRoomId
      members
      chatRoom {
        id
        createdBy
        receiverHasRead
        chatRoomUsers {
          nextToken
        }
        messages {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      user {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        journals {
          nextToken
        }
        chatRoomUser {
          nextToken
        }
        chatMassages {
          nextToken
        }
        createdAt
        updatedAt
        lovedJournals {
          nextToken
        }
        books {
          nextToken
        }
        publicJournals {
          nextToken
        }
        lovedComments {
          nextToken
        }
        comments {
          nextToken
        }
        notifications {
          nextToken
        }
      }
    }
  }
`
export const updateChatRoom = /* GraphQL */ `
  mutation UpdateChatRoom($input: UpdateChatRoomInput!) {
    updateChatRoom(input: $input) {
      id
      createdBy
      receiverHasRead
      chatRoomUsers {
        items {
          id
          chatRoomUserUserId
          chatRoomUserChatRoomId
          members
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          content
          messageChatRoomId
          messageUserId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`
