/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const gnjAmplifyStripeCreateMember = /* GraphQL */ `
  mutation GnjAmplifyStripeCreateMember(
    $stripeToken: String!
    $username: String!
    $email: String!
    $uuid: String!
    $membershipPlanSelected: String!
    $stripeCouponId: String
    $stripeCustomerId: String
  ) {
    gnjAmplifyStripeCreateMember(
      stripeToken: $stripeToken
      username: $username
      email: $email
      uuid: $uuid
      membershipPlanSelected: $membershipPlanSelected
      stripeCouponId: $stripeCouponId
      stripeCustomerId: $stripeCustomerId
    )
  }
`;
export const gnjAmplifyStripeUpdateCustomer = /* GraphQL */ `
  mutation GnjAmplifyStripeUpdateCustomer(
    $stripeCustomerId: String!
    $stripeToken: String
    $email: String
  ) {
    gnjAmplifyStripeUpdateCustomer(
      stripeCustomerId: $stripeCustomerId
      stripeToken: $stripeToken
      email: $email
    )
  }
`;
export const gnjAmplifyStripeGetCouponCode = /* GraphQL */ `
  mutation GnjAmplifyStripeGetCouponCode($stripeCouponId: String!) {
    gnjAmplifyStripeGetCouponCode(stripeCouponId: $stripeCouponId)
  }
`;
export const gnjAmplifyStripeCancelMembership = /* GraphQL */ `
  mutation GnjAmplifyStripeCancelMembership(
    $stripeSubscriptionId: String!
    $username: String!
    $cancel: Boolean!
    $membershipPlan: String
  ) {
    gnjAmplifyStripeCancelMembership(
      stripeSubscriptionId: $stripeSubscriptionId
      username: $username
      cancel: $cancel
      membershipPlan: $membershipPlan
    )
  }
`;
export const gnjAmplifyadminDisableUser = /* GraphQL */ `
  mutation GnjAmplifyadminDisableUser($username: String!) {
    gnjAmplifyadminDisableUser(username: $username)
  }
`;
export const gnjAmplifyJournalLovedCount = /* GraphQL */ `
  mutation GnjAmplifyJournalLovedCount($journalId: String!, $lovedCount: Int!) {
    gnjAmplifyJournalLovedCount(journalId: $journalId, lovedCount: $lovedCount)
  }
`;
export const gnjAmplifyJournalCommentCount = /* GraphQL */ `
  mutation GnjAmplifyJournalCommentCount(
    $journalId: String!
    $commentCount: Int!
  ) {
    gnjAmplifyJournalCommentCount(
      journalId: $journalId
      commentCount: $commentCount
    )
  }
`;
export const gnjAmplifyExpoSendNotification = /* GraphQL */ `
  mutation GnjAmplifyExpoSendNotification(
    $sendTo: String!
    $title: String!
    $body: String
  ) {
    gnjAmplifyExpoSendNotification(sendTo: $sendTo, title: $title, body: $body)
  }
`;
export const gnjAmplifySendEmailNotification = /* GraphQL */ `
  mutation GnjAmplifySendEmailNotification(
    $sendTo: String!
    $title: String!
    $body: String
  ) {
    gnjAmplifySendEmailNotification(sendTo: $sendTo, title: $title, body: $body)
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      id
      uuid
      displayName
      profileImage
      membership
      totalJournal
      followingCount
      followerCount
      journals {
        items {
          id
          privacy
          draft
          content
          journalAuthorId
          markedAs
          lovedCount
          commentCount
          journalWordCount
          journalBookId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      chatRoomUser {
        items {
          id
          chatRoomUserUserId
          chatRoomUserChatRoomId
          members
          createdAt
          updatedAt
        }
        nextToken
      }
      chatMassages {
        items {
          id
          content
          messageChatRoomId
          messageUserId
          createdAt
          updatedAt
        }
        nextToken
      }
      bookOrder
      createdAt
      updatedAt
      lovedJournals {
        items {
          id
          userLovedJournalUserId
          userLovedJournalJournalId
          createdAt
          updatedAt
        }
        nextToken
      }
      books {
        items {
          id
          book
          bookUserId
          bookCoverImg
          bookJournalCount
          createdAt
          updatedAt
        }
        nextToken
      }
      publicJournals {
        items {
          id
          privacy
          content
          journalAuthorId
          markedAs
          lovedCount
          commentCount
          journalWordCount
          publicJournalBookId
          createdAt
          updatedAt
        }
        nextToken
      }
      lovedComments {
        items {
          id
          userLovedCommentUserId
          userLovedCommentCommentId
          createdAt
          updatedAt
        }
        nextToken
      }
      comments {
        items {
          id
          content
          commentLovedCount
          createdAt
          updatedAt
        }
        nextToken
      }
      notifications {
        items {
          id
          content
          category
          link
          read
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      uuid
      displayName
      profileImage
      membership
      totalJournal
      followingCount
      followerCount
      journals {
        items {
          id
          privacy
          draft
          content
          journalAuthorId
          markedAs
          lovedCount
          commentCount
          journalWordCount
          journalBookId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      chatRoomUser {
        items {
          id
          chatRoomUserUserId
          chatRoomUserChatRoomId
          members
          createdAt
          updatedAt
        }
        nextToken
      }
      chatMassages {
        items {
          id
          content
          messageChatRoomId
          messageUserId
          createdAt
          updatedAt
        }
        nextToken
      }
      bookOrder
      createdAt
      updatedAt
      lovedJournals {
        items {
          id
          userLovedJournalUserId
          userLovedJournalJournalId
          createdAt
          updatedAt
        }
        nextToken
      }
      books {
        items {
          id
          book
          bookUserId
          bookCoverImg
          bookJournalCount
          createdAt
          updatedAt
        }
        nextToken
      }
      publicJournals {
        items {
          id
          privacy
          content
          journalAuthorId
          markedAs
          lovedCount
          commentCount
          journalWordCount
          publicJournalBookId
          createdAt
          updatedAt
        }
        nextToken
      }
      lovedComments {
        items {
          id
          userLovedCommentUserId
          userLovedCommentCommentId
          createdAt
          updatedAt
        }
        nextToken
      }
      comments {
        items {
          id
          content
          commentLovedCount
          createdAt
          updatedAt
        }
        nextToken
      }
      notifications {
        items {
          id
          content
          category
          link
          read
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser($input: DeleteUserInput!) {
    deleteUser(input: $input) {
      id
      uuid
      displayName
      profileImage
      membership
      totalJournal
      followingCount
      followerCount
      journals {
        items {
          id
          privacy
          draft
          content
          journalAuthorId
          markedAs
          lovedCount
          commentCount
          journalWordCount
          journalBookId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      chatRoomUser {
        items {
          id
          chatRoomUserUserId
          chatRoomUserChatRoomId
          members
          createdAt
          updatedAt
        }
        nextToken
      }
      chatMassages {
        items {
          id
          content
          messageChatRoomId
          messageUserId
          createdAt
          updatedAt
        }
        nextToken
      }
      bookOrder
      createdAt
      updatedAt
      lovedJournals {
        items {
          id
          userLovedJournalUserId
          userLovedJournalJournalId
          createdAt
          updatedAt
        }
        nextToken
      }
      books {
        items {
          id
          book
          bookUserId
          bookCoverImg
          bookJournalCount
          createdAt
          updatedAt
        }
        nextToken
      }
      publicJournals {
        items {
          id
          privacy
          content
          journalAuthorId
          markedAs
          lovedCount
          commentCount
          journalWordCount
          publicJournalBookId
          createdAt
          updatedAt
        }
        nextToken
      }
      lovedComments {
        items {
          id
          userLovedCommentUserId
          userLovedCommentCommentId
          createdAt
          updatedAt
        }
        nextToken
      }
      comments {
        items {
          id
          content
          commentLovedCount
          createdAt
          updatedAt
        }
        nextToken
      }
      notifications {
        items {
          id
          content
          category
          link
          read
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createFollowing = /* GraphQL */ `
  mutation CreateFollowing($input: CreateFollowingInput!) {
    createFollowing(input: $input) {
      id
      user
      following
      followingProfile
      followerProfile
      createdAt
      updatedAt
    }
  }
`;
export const updateFollowing = /* GraphQL */ `
  mutation UpdateFollowing($input: UpdateFollowingInput!) {
    updateFollowing(input: $input) {
      id
      user
      following
      followingProfile
      followerProfile
      createdAt
      updatedAt
    }
  }
`;
export const deleteFollowing = /* GraphQL */ `
  mutation DeleteFollowing($input: DeleteFollowingInput!) {
    deleteFollowing(input: $input) {
      id
      user
      following
      followingProfile
      followerProfile
      createdAt
      updatedAt
    }
  }
`;
export const createUserLovedJournal = /* GraphQL */ `
  mutation CreateUserLovedJournal($input: CreateUserLovedJournalInput!) {
    createUserLovedJournal(input: $input) {
      id
      userLovedJournalUserId
      userLovedJournalJournalId
      createdAt
      updatedAt
      user {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        journals {
          nextToken
        }
        chatRoomUser {
          nextToken
        }
        chatMassages {
          nextToken
        }
        bookOrder
        createdAt
        updatedAt
        lovedJournals {
          nextToken
        }
        books {
          nextToken
        }
        publicJournals {
          nextToken
        }
        lovedComments {
          nextToken
        }
        comments {
          nextToken
        }
        notifications {
          nextToken
        }
      }
      journal {
        id
        privacy
        content
        journalAuthorId
        markedAs
        lovedCount
        commentCount
        journalWordCount
        publicJournalBookId
        createdAt
        updatedAt
        author {
          id
          uuid
          displayName
          profileImage
          membership
          totalJournal
          followingCount
          followerCount
          bookOrder
          createdAt
          updatedAt
        }
        lovedUsers {
          nextToken
        }
        book {
          id
          book
          bookUserId
          bookCoverImg
          bookJournalCount
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
      }
    }
  }
`;
export const updateUserLovedJournal = /* GraphQL */ `
  mutation UpdateUserLovedJournal($input: UpdateUserLovedJournalInput!) {
    updateUserLovedJournal(input: $input) {
      id
      userLovedJournalUserId
      userLovedJournalJournalId
      createdAt
      updatedAt
      user {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        journals {
          nextToken
        }
        chatRoomUser {
          nextToken
        }
        chatMassages {
          nextToken
        }
        bookOrder
        createdAt
        updatedAt
        lovedJournals {
          nextToken
        }
        books {
          nextToken
        }
        publicJournals {
          nextToken
        }
        lovedComments {
          nextToken
        }
        comments {
          nextToken
        }
        notifications {
          nextToken
        }
      }
      journal {
        id
        privacy
        content
        journalAuthorId
        markedAs
        lovedCount
        commentCount
        journalWordCount
        publicJournalBookId
        createdAt
        updatedAt
        author {
          id
          uuid
          displayName
          profileImage
          membership
          totalJournal
          followingCount
          followerCount
          bookOrder
          createdAt
          updatedAt
        }
        lovedUsers {
          nextToken
        }
        book {
          id
          book
          bookUserId
          bookCoverImg
          bookJournalCount
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
      }
    }
  }
`;
export const deleteUserLovedJournal = /* GraphQL */ `
  mutation DeleteUserLovedJournal($input: DeleteUserLovedJournalInput!) {
    deleteUserLovedJournal(input: $input) {
      id
      userLovedJournalUserId
      userLovedJournalJournalId
      createdAt
      updatedAt
      user {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        journals {
          nextToken
        }
        chatRoomUser {
          nextToken
        }
        chatMassages {
          nextToken
        }
        bookOrder
        createdAt
        updatedAt
        lovedJournals {
          nextToken
        }
        books {
          nextToken
        }
        publicJournals {
          nextToken
        }
        lovedComments {
          nextToken
        }
        comments {
          nextToken
        }
        notifications {
          nextToken
        }
      }
      journal {
        id
        privacy
        content
        journalAuthorId
        markedAs
        lovedCount
        commentCount
        journalWordCount
        publicJournalBookId
        createdAt
        updatedAt
        author {
          id
          uuid
          displayName
          profileImage
          membership
          totalJournal
          followingCount
          followerCount
          bookOrder
          createdAt
          updatedAt
        }
        lovedUsers {
          nextToken
        }
        book {
          id
          book
          bookUserId
          bookCoverImg
          bookJournalCount
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
      }
    }
  }
`;
export const createJournal = /* GraphQL */ `
  mutation CreateJournal($input: CreateJournalInput!) {
    createJournal(input: $input) {
      id
      privacy
      draft
      content
      journalAuthorId
      markedAs
      lovedCount
      commentCount
      journalWordCount
      journalBookId
      createdAt
      updatedAt
      author {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        journals {
          nextToken
        }
        chatRoomUser {
          nextToken
        }
        chatMassages {
          nextToken
        }
        bookOrder
        createdAt
        updatedAt
        lovedJournals {
          nextToken
        }
        books {
          nextToken
        }
        publicJournals {
          nextToken
        }
        lovedComments {
          nextToken
        }
        comments {
          nextToken
        }
        notifications {
          nextToken
        }
      }
      owner
      book {
        id
        book
        bookUserId
        bookCoverImg
        bookJournalCount
        journals {
          nextToken
        }
        createdAt
        updatedAt
        user {
          id
          uuid
          displayName
          profileImage
          membership
          totalJournal
          followingCount
          followerCount
          bookOrder
          createdAt
          updatedAt
        }
        publicJournals {
          nextToken
        }
      }
    }
  }
`;
export const updateJournal = /* GraphQL */ `
  mutation UpdateJournal($input: UpdateJournalInput!) {
    updateJournal(input: $input) {
      id
      privacy
      draft
      content
      journalAuthorId
      markedAs
      lovedCount
      commentCount
      journalWordCount
      journalBookId
      createdAt
      updatedAt
      author {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        journals {
          nextToken
        }
        chatRoomUser {
          nextToken
        }
        chatMassages {
          nextToken
        }
        bookOrder
        createdAt
        updatedAt
        lovedJournals {
          nextToken
        }
        books {
          nextToken
        }
        publicJournals {
          nextToken
        }
        lovedComments {
          nextToken
        }
        comments {
          nextToken
        }
        notifications {
          nextToken
        }
      }
      owner
      book {
        id
        book
        bookUserId
        bookCoverImg
        bookJournalCount
        journals {
          nextToken
        }
        createdAt
        updatedAt
        user {
          id
          uuid
          displayName
          profileImage
          membership
          totalJournal
          followingCount
          followerCount
          bookOrder
          createdAt
          updatedAt
        }
        publicJournals {
          nextToken
        }
      }
    }
  }
`;
export const deleteJournal = /* GraphQL */ `
  mutation DeleteJournal($input: DeleteJournalInput!) {
    deleteJournal(input: $input) {
      id
      privacy
      draft
      content
      journalAuthorId
      markedAs
      lovedCount
      commentCount
      journalWordCount
      journalBookId
      createdAt
      updatedAt
      author {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        journals {
          nextToken
        }
        chatRoomUser {
          nextToken
        }
        chatMassages {
          nextToken
        }
        bookOrder
        createdAt
        updatedAt
        lovedJournals {
          nextToken
        }
        books {
          nextToken
        }
        publicJournals {
          nextToken
        }
        lovedComments {
          nextToken
        }
        comments {
          nextToken
        }
        notifications {
          nextToken
        }
      }
      owner
      book {
        id
        book
        bookUserId
        bookCoverImg
        bookJournalCount
        journals {
          nextToken
        }
        createdAt
        updatedAt
        user {
          id
          uuid
          displayName
          profileImage
          membership
          totalJournal
          followingCount
          followerCount
          bookOrder
          createdAt
          updatedAt
        }
        publicJournals {
          nextToken
        }
      }
    }
  }
`;
export const createBook = /* GraphQL */ `
  mutation CreateBook($input: CreateBookInput!) {
    createBook(input: $input) {
      id
      book
      bookUserId
      bookCoverImg
      bookJournalCount
      journals {
        items {
          id
          privacy
          draft
          content
          journalAuthorId
          markedAs
          lovedCount
          commentCount
          journalWordCount
          journalBookId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      user {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        journals {
          nextToken
        }
        chatRoomUser {
          nextToken
        }
        chatMassages {
          nextToken
        }
        bookOrder
        createdAt
        updatedAt
        lovedJournals {
          nextToken
        }
        books {
          nextToken
        }
        publicJournals {
          nextToken
        }
        lovedComments {
          nextToken
        }
        comments {
          nextToken
        }
        notifications {
          nextToken
        }
      }
      publicJournals {
        items {
          id
          privacy
          content
          journalAuthorId
          markedAs
          lovedCount
          commentCount
          journalWordCount
          publicJournalBookId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateBook = /* GraphQL */ `
  mutation UpdateBook($input: UpdateBookInput!) {
    updateBook(input: $input) {
      id
      book
      bookUserId
      bookCoverImg
      bookJournalCount
      journals {
        items {
          id
          privacy
          draft
          content
          journalAuthorId
          markedAs
          lovedCount
          commentCount
          journalWordCount
          journalBookId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      user {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        journals {
          nextToken
        }
        chatRoomUser {
          nextToken
        }
        chatMassages {
          nextToken
        }
        bookOrder
        createdAt
        updatedAt
        lovedJournals {
          nextToken
        }
        books {
          nextToken
        }
        publicJournals {
          nextToken
        }
        lovedComments {
          nextToken
        }
        comments {
          nextToken
        }
        notifications {
          nextToken
        }
      }
      publicJournals {
        items {
          id
          privacy
          content
          journalAuthorId
          markedAs
          lovedCount
          commentCount
          journalWordCount
          publicJournalBookId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteBook = /* GraphQL */ `
  mutation DeleteBook($input: DeleteBookInput!) {
    deleteBook(input: $input) {
      id
      book
      bookUserId
      bookCoverImg
      bookJournalCount
      journals {
        items {
          id
          privacy
          draft
          content
          journalAuthorId
          markedAs
          lovedCount
          commentCount
          journalWordCount
          journalBookId
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      user {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        journals {
          nextToken
        }
        chatRoomUser {
          nextToken
        }
        chatMassages {
          nextToken
        }
        bookOrder
        createdAt
        updatedAt
        lovedJournals {
          nextToken
        }
        books {
          nextToken
        }
        publicJournals {
          nextToken
        }
        lovedComments {
          nextToken
        }
        comments {
          nextToken
        }
        notifications {
          nextToken
        }
      }
      publicJournals {
        items {
          id
          privacy
          content
          journalAuthorId
          markedAs
          lovedCount
          commentCount
          journalWordCount
          publicJournalBookId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createPublicJournal = /* GraphQL */ `
  mutation CreatePublicJournal($input: CreatePublicJournalInput!) {
    createPublicJournal(input: $input) {
      id
      privacy
      content
      journalAuthorId
      markedAs
      lovedCount
      commentCount
      journalWordCount
      publicJournalBookId
      createdAt
      updatedAt
      author {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        journals {
          nextToken
        }
        chatRoomUser {
          nextToken
        }
        chatMassages {
          nextToken
        }
        bookOrder
        createdAt
        updatedAt
        lovedJournals {
          nextToken
        }
        books {
          nextToken
        }
        publicJournals {
          nextToken
        }
        lovedComments {
          nextToken
        }
        comments {
          nextToken
        }
        notifications {
          nextToken
        }
      }
      lovedUsers {
        items {
          id
          userLovedJournalUserId
          userLovedJournalJournalId
          createdAt
          updatedAt
        }
        nextToken
      }
      book {
        id
        book
        bookUserId
        bookCoverImg
        bookJournalCount
        journals {
          nextToken
        }
        createdAt
        updatedAt
        user {
          id
          uuid
          displayName
          profileImage
          membership
          totalJournal
          followingCount
          followerCount
          bookOrder
          createdAt
          updatedAt
        }
        publicJournals {
          nextToken
        }
      }
      comments {
        items {
          id
          content
          commentLovedCount
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deletePublicJournal = /* GraphQL */ `
  mutation DeletePublicJournal($input: DeletePublicJournalInput!) {
    deletePublicJournal(input: $input) {
      id
      privacy
      content
      journalAuthorId
      markedAs
      lovedCount
      commentCount
      journalWordCount
      publicJournalBookId
      createdAt
      updatedAt
      author {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        journals {
          nextToken
        }
        chatRoomUser {
          nextToken
        }
        chatMassages {
          nextToken
        }
        bookOrder
        createdAt
        updatedAt
        lovedJournals {
          nextToken
        }
        books {
          nextToken
        }
        publicJournals {
          nextToken
        }
        lovedComments {
          nextToken
        }
        comments {
          nextToken
        }
        notifications {
          nextToken
        }
      }
      lovedUsers {
        items {
          id
          userLovedJournalUserId
          userLovedJournalJournalId
          createdAt
          updatedAt
        }
        nextToken
      }
      book {
        id
        book
        bookUserId
        bookCoverImg
        bookJournalCount
        journals {
          nextToken
        }
        createdAt
        updatedAt
        user {
          id
          uuid
          displayName
          profileImage
          membership
          totalJournal
          followingCount
          followerCount
          bookOrder
          createdAt
          updatedAt
        }
        publicJournals {
          nextToken
        }
      }
      comments {
        items {
          id
          content
          commentLovedCount
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createChallenge = /* GraphQL */ `
  mutation CreateChallenge($input: CreateChallengeInput!) {
    createChallenge(input: $input) {
      id
      yearMonth
      user
      userDisplayName
      userProfileImage
      journalsPerMonth
      journalWordCountPerMonth
      createdAt
      updatedAt
    }
  }
`;
export const updateChallenge = /* GraphQL */ `
  mutation UpdateChallenge($input: UpdateChallengeInput!) {
    updateChallenge(input: $input) {
      id
      yearMonth
      user
      userDisplayName
      userProfileImage
      journalsPerMonth
      journalWordCountPerMonth
      createdAt
      updatedAt
    }
  }
`;
export const deleteChallenge = /* GraphQL */ `
  mutation DeleteChallenge($input: DeleteChallengeInput!) {
    deleteChallenge(input: $input) {
      id
      yearMonth
      user
      userDisplayName
      userProfileImage
      journalsPerMonth
      journalWordCountPerMonth
      createdAt
      updatedAt
    }
  }
`;
export const createUserLovedComment = /* GraphQL */ `
  mutation CreateUserLovedComment($input: CreateUserLovedCommentInput!) {
    createUserLovedComment(input: $input) {
      id
      userLovedCommentUserId
      userLovedCommentCommentId
      createdAt
      updatedAt
      user {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        journals {
          nextToken
        }
        chatRoomUser {
          nextToken
        }
        chatMassages {
          nextToken
        }
        bookOrder
        createdAt
        updatedAt
        lovedJournals {
          nextToken
        }
        books {
          nextToken
        }
        publicJournals {
          nextToken
        }
        lovedComments {
          nextToken
        }
        comments {
          nextToken
        }
        notifications {
          nextToken
        }
      }
      comment {
        id
        content
        commentLovedCount
        createdAt
        updatedAt
        author {
          id
          uuid
          displayName
          profileImage
          membership
          totalJournal
          followingCount
          followerCount
          bookOrder
          createdAt
          updatedAt
        }
        journal {
          id
          privacy
          content
          journalAuthorId
          markedAs
          lovedCount
          commentCount
          journalWordCount
          publicJournalBookId
          createdAt
          updatedAt
        }
        lovedUsers {
          nextToken
        }
      }
    }
  }
`;
export const updateUserLovedComment = /* GraphQL */ `
  mutation UpdateUserLovedComment($input: UpdateUserLovedCommentInput!) {
    updateUserLovedComment(input: $input) {
      id
      userLovedCommentUserId
      userLovedCommentCommentId
      createdAt
      updatedAt
      user {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        journals {
          nextToken
        }
        chatRoomUser {
          nextToken
        }
        chatMassages {
          nextToken
        }
        bookOrder
        createdAt
        updatedAt
        lovedJournals {
          nextToken
        }
        books {
          nextToken
        }
        publicJournals {
          nextToken
        }
        lovedComments {
          nextToken
        }
        comments {
          nextToken
        }
        notifications {
          nextToken
        }
      }
      comment {
        id
        content
        commentLovedCount
        createdAt
        updatedAt
        author {
          id
          uuid
          displayName
          profileImage
          membership
          totalJournal
          followingCount
          followerCount
          bookOrder
          createdAt
          updatedAt
        }
        journal {
          id
          privacy
          content
          journalAuthorId
          markedAs
          lovedCount
          commentCount
          journalWordCount
          publicJournalBookId
          createdAt
          updatedAt
        }
        lovedUsers {
          nextToken
        }
      }
    }
  }
`;
export const deleteUserLovedComment = /* GraphQL */ `
  mutation DeleteUserLovedComment($input: DeleteUserLovedCommentInput!) {
    deleteUserLovedComment(input: $input) {
      id
      userLovedCommentUserId
      userLovedCommentCommentId
      createdAt
      updatedAt
      user {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        journals {
          nextToken
        }
        chatRoomUser {
          nextToken
        }
        chatMassages {
          nextToken
        }
        bookOrder
        createdAt
        updatedAt
        lovedJournals {
          nextToken
        }
        books {
          nextToken
        }
        publicJournals {
          nextToken
        }
        lovedComments {
          nextToken
        }
        comments {
          nextToken
        }
        notifications {
          nextToken
        }
      }
      comment {
        id
        content
        commentLovedCount
        createdAt
        updatedAt
        author {
          id
          uuid
          displayName
          profileImage
          membership
          totalJournal
          followingCount
          followerCount
          bookOrder
          createdAt
          updatedAt
        }
        journal {
          id
          privacy
          content
          journalAuthorId
          markedAs
          lovedCount
          commentCount
          journalWordCount
          publicJournalBookId
          createdAt
          updatedAt
        }
        lovedUsers {
          nextToken
        }
      }
    }
  }
`;
export const createComment = /* GraphQL */ `
  mutation CreateComment($input: CreateCommentInput!) {
    createComment(input: $input) {
      id
      content
      commentLovedCount
      createdAt
      updatedAt
      author {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        journals {
          nextToken
        }
        chatRoomUser {
          nextToken
        }
        chatMassages {
          nextToken
        }
        bookOrder
        createdAt
        updatedAt
        lovedJournals {
          nextToken
        }
        books {
          nextToken
        }
        publicJournals {
          nextToken
        }
        lovedComments {
          nextToken
        }
        comments {
          nextToken
        }
        notifications {
          nextToken
        }
      }
      journal {
        id
        privacy
        content
        journalAuthorId
        markedAs
        lovedCount
        commentCount
        journalWordCount
        publicJournalBookId
        createdAt
        updatedAt
        author {
          id
          uuid
          displayName
          profileImage
          membership
          totalJournal
          followingCount
          followerCount
          bookOrder
          createdAt
          updatedAt
        }
        lovedUsers {
          nextToken
        }
        book {
          id
          book
          bookUserId
          bookCoverImg
          bookJournalCount
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
      }
      lovedUsers {
        items {
          id
          userLovedCommentUserId
          userLovedCommentCommentId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateComment = /* GraphQL */ `
  mutation UpdateComment($input: UpdateCommentInput!) {
    updateComment(input: $input) {
      id
      content
      commentLovedCount
      createdAt
      updatedAt
      author {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        journals {
          nextToken
        }
        chatRoomUser {
          nextToken
        }
        chatMassages {
          nextToken
        }
        bookOrder
        createdAt
        updatedAt
        lovedJournals {
          nextToken
        }
        books {
          nextToken
        }
        publicJournals {
          nextToken
        }
        lovedComments {
          nextToken
        }
        comments {
          nextToken
        }
        notifications {
          nextToken
        }
      }
      journal {
        id
        privacy
        content
        journalAuthorId
        markedAs
        lovedCount
        commentCount
        journalWordCount
        publicJournalBookId
        createdAt
        updatedAt
        author {
          id
          uuid
          displayName
          profileImage
          membership
          totalJournal
          followingCount
          followerCount
          bookOrder
          createdAt
          updatedAt
        }
        lovedUsers {
          nextToken
        }
        book {
          id
          book
          bookUserId
          bookCoverImg
          bookJournalCount
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
      }
      lovedUsers {
        items {
          id
          userLovedCommentUserId
          userLovedCommentCommentId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteComment = /* GraphQL */ `
  mutation DeleteComment($input: DeleteCommentInput!) {
    deleteComment(input: $input) {
      id
      content
      commentLovedCount
      createdAt
      updatedAt
      author {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        journals {
          nextToken
        }
        chatRoomUser {
          nextToken
        }
        chatMassages {
          nextToken
        }
        bookOrder
        createdAt
        updatedAt
        lovedJournals {
          nextToken
        }
        books {
          nextToken
        }
        publicJournals {
          nextToken
        }
        lovedComments {
          nextToken
        }
        comments {
          nextToken
        }
        notifications {
          nextToken
        }
      }
      journal {
        id
        privacy
        content
        journalAuthorId
        markedAs
        lovedCount
        commentCount
        journalWordCount
        publicJournalBookId
        createdAt
        updatedAt
        author {
          id
          uuid
          displayName
          profileImage
          membership
          totalJournal
          followingCount
          followerCount
          bookOrder
          createdAt
          updatedAt
        }
        lovedUsers {
          nextToken
        }
        book {
          id
          book
          bookUserId
          bookCoverImg
          bookJournalCount
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
      }
      lovedUsers {
        items {
          id
          userLovedCommentUserId
          userLovedCommentCommentId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification($input: CreateNotificationInput!) {
    createNotification(input: $input) {
      id
      content
      category
      link
      read
      actionUser {
        userID
        displayName
        photoURL
      }
      createdAt
      updatedAt
      user {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        journals {
          nextToken
        }
        chatRoomUser {
          nextToken
        }
        chatMassages {
          nextToken
        }
        bookOrder
        createdAt
        updatedAt
        lovedJournals {
          nextToken
        }
        books {
          nextToken
        }
        publicJournals {
          nextToken
        }
        lovedComments {
          nextToken
        }
        comments {
          nextToken
        }
        notifications {
          nextToken
        }
      }
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification($input: UpdateNotificationInput!) {
    updateNotification(input: $input) {
      id
      content
      category
      link
      read
      actionUser {
        userID
        displayName
        photoURL
      }
      createdAt
      updatedAt
      user {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        journals {
          nextToken
        }
        chatRoomUser {
          nextToken
        }
        chatMassages {
          nextToken
        }
        bookOrder
        createdAt
        updatedAt
        lovedJournals {
          nextToken
        }
        books {
          nextToken
        }
        publicJournals {
          nextToken
        }
        lovedComments {
          nextToken
        }
        comments {
          nextToken
        }
        notifications {
          nextToken
        }
      }
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification($input: DeleteNotificationInput!) {
    deleteNotification(input: $input) {
      id
      content
      category
      link
      read
      actionUser {
        userID
        displayName
        photoURL
      }
      createdAt
      updatedAt
      user {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        journals {
          nextToken
        }
        chatRoomUser {
          nextToken
        }
        chatMassages {
          nextToken
        }
        bookOrder
        createdAt
        updatedAt
        lovedJournals {
          nextToken
        }
        books {
          nextToken
        }
        publicJournals {
          nextToken
        }
        lovedComments {
          nextToken
        }
        comments {
          nextToken
        }
        notifications {
          nextToken
        }
      }
    }
  }
`;
export const createChatRoomUser = /* GraphQL */ `
  mutation CreateChatRoomUser($input: CreateChatRoomUserInput!) {
    createChatRoomUser(input: $input) {
      id
      chatRoomUserUserId
      chatRoomUserChatRoomId
      members
      chatRoom {
        id
        createdBy
        receiverHasRead
        chatRoomUsers {
          nextToken
        }
        messages {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      user {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        journals {
          nextToken
        }
        chatRoomUser {
          nextToken
        }
        chatMassages {
          nextToken
        }
        bookOrder
        createdAt
        updatedAt
        lovedJournals {
          nextToken
        }
        books {
          nextToken
        }
        publicJournals {
          nextToken
        }
        lovedComments {
          nextToken
        }
        comments {
          nextToken
        }
        notifications {
          nextToken
        }
      }
    }
  }
`;
export const updateChatRoomUser = /* GraphQL */ `
  mutation UpdateChatRoomUser($input: UpdateChatRoomUserInput!) {
    updateChatRoomUser(input: $input) {
      id
      chatRoomUserUserId
      chatRoomUserChatRoomId
      members
      chatRoom {
        id
        createdBy
        receiverHasRead
        chatRoomUsers {
          nextToken
        }
        messages {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      user {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        journals {
          nextToken
        }
        chatRoomUser {
          nextToken
        }
        chatMassages {
          nextToken
        }
        bookOrder
        createdAt
        updatedAt
        lovedJournals {
          nextToken
        }
        books {
          nextToken
        }
        publicJournals {
          nextToken
        }
        lovedComments {
          nextToken
        }
        comments {
          nextToken
        }
        notifications {
          nextToken
        }
      }
    }
  }
`;
export const deleteChatRoomUser = /* GraphQL */ `
  mutation DeleteChatRoomUser($input: DeleteChatRoomUserInput!) {
    deleteChatRoomUser(input: $input) {
      id
      chatRoomUserUserId
      chatRoomUserChatRoomId
      members
      chatRoom {
        id
        createdBy
        receiverHasRead
        chatRoomUsers {
          nextToken
        }
        messages {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      user {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        journals {
          nextToken
        }
        chatRoomUser {
          nextToken
        }
        chatMassages {
          nextToken
        }
        bookOrder
        createdAt
        updatedAt
        lovedJournals {
          nextToken
        }
        books {
          nextToken
        }
        publicJournals {
          nextToken
        }
        lovedComments {
          nextToken
        }
        comments {
          nextToken
        }
        notifications {
          nextToken
        }
      }
    }
  }
`;
export const createChatRoom = /* GraphQL */ `
  mutation CreateChatRoom($input: CreateChatRoomInput!) {
    createChatRoom(input: $input) {
      id
      createdBy
      receiverHasRead
      chatRoomUsers {
        items {
          id
          chatRoomUserUserId
          chatRoomUserChatRoomId
          members
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          content
          messageChatRoomId
          messageUserId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateChatRoom = /* GraphQL */ `
  mutation UpdateChatRoom($input: UpdateChatRoomInput!) {
    updateChatRoom(input: $input) {
      id
      createdBy
      receiverHasRead
      chatRoomUsers {
        items {
          id
          chatRoomUserUserId
          chatRoomUserChatRoomId
          members
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          content
          messageChatRoomId
          messageUserId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteChatRoom = /* GraphQL */ `
  mutation DeleteChatRoom($input: DeleteChatRoomInput!) {
    deleteChatRoom(input: $input) {
      id
      createdBy
      receiverHasRead
      chatRoomUsers {
        items {
          id
          chatRoomUserUserId
          chatRoomUserChatRoomId
          members
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          content
          messageChatRoomId
          messageUserId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage($input: CreateMessageInput!) {
    createMessage(input: $input) {
      id
      content
      messageChatRoomId
      messageUserId
      chatRoom {
        id
        createdBy
        receiverHasRead
        chatRoomUsers {
          nextToken
        }
        messages {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      user {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        journals {
          nextToken
        }
        chatRoomUser {
          nextToken
        }
        chatMassages {
          nextToken
        }
        bookOrder
        createdAt
        updatedAt
        lovedJournals {
          nextToken
        }
        books {
          nextToken
        }
        publicJournals {
          nextToken
        }
        lovedComments {
          nextToken
        }
        comments {
          nextToken
        }
        notifications {
          nextToken
        }
      }
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage($input: UpdateMessageInput!) {
    updateMessage(input: $input) {
      id
      content
      messageChatRoomId
      messageUserId
      chatRoom {
        id
        createdBy
        receiverHasRead
        chatRoomUsers {
          nextToken
        }
        messages {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      user {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        journals {
          nextToken
        }
        chatRoomUser {
          nextToken
        }
        chatMassages {
          nextToken
        }
        bookOrder
        createdAt
        updatedAt
        lovedJournals {
          nextToken
        }
        books {
          nextToken
        }
        publicJournals {
          nextToken
        }
        lovedComments {
          nextToken
        }
        comments {
          nextToken
        }
        notifications {
          nextToken
        }
      }
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage($input: DeleteMessageInput!) {
    deleteMessage(input: $input) {
      id
      content
      messageChatRoomId
      messageUserId
      chatRoom {
        id
        createdBy
        receiverHasRead
        chatRoomUsers {
          nextToken
        }
        messages {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      user {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        journals {
          nextToken
        }
        chatRoomUser {
          nextToken
        }
        chatMassages {
          nextToken
        }
        bookOrder
        createdAt
        updatedAt
        lovedJournals {
          nextToken
        }
        books {
          nextToken
        }
        publicJournals {
          nextToken
        }
        lovedComments {
          nextToken
        }
        comments {
          nextToken
        }
        notifications {
          nextToken
        }
      }
    }
  }
`;
export const updatePublicJournal = /* GraphQL */ `
  mutation UpdatePublicJournal($input: UpdatePublicJournalInput!) {
    updatePublicJournal(input: $input) {
      id
      privacy
      content
      journalAuthorId
      markedAs
      lovedCount
      commentCount
      journalWordCount
      publicJournalBookId
      createdAt
      updatedAt
      author {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        journals {
          nextToken
        }
        chatRoomUser {
          nextToken
        }
        chatMassages {
          nextToken
        }
        bookOrder
        createdAt
        updatedAt
        lovedJournals {
          nextToken
        }
        books {
          nextToken
        }
        publicJournals {
          nextToken
        }
        lovedComments {
          nextToken
        }
        comments {
          nextToken
        }
        notifications {
          nextToken
        }
      }
      lovedUsers {
        items {
          id
          userLovedJournalUserId
          userLovedJournalJournalId
          createdAt
          updatedAt
        }
        nextToken
      }
      book {
        id
        book
        bookUserId
        bookCoverImg
        bookJournalCount
        journals {
          nextToken
        }
        createdAt
        updatedAt
        user {
          id
          uuid
          displayName
          profileImage
          membership
          totalJournal
          followingCount
          followerCount
          bookOrder
          createdAt
          updatedAt
        }
        publicJournals {
          nextToken
        }
      }
      comments {
        items {
          id
          content
          commentLovedCount
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const gnjAmplifyExpoNotificationToken = /* GraphQL */ `
  mutation GnjAmplifyExpoNotificationToken(
    $username: String!
    $expoNotificationToken: String!
  ) {
    gnjAmplifyExpoNotificationToken(
      username: $username
      expoNotificationToken: $expoNotificationToken
    )
  }
`;
export const gnjAmplifyForgotUsername = /* GraphQL */ `
  mutation GnjAmplifyForgotUsername($email: String!) {
    gnjAmplifyForgotUsername(email: $email)
  }
`;
