import React, { Component } from "react";
import MyJournalListItem from "./MyJournalListItem";
import LoadingCard from "../components/LoadingCard";
import gnjLogoMobile from "../assets/images/favicon.svg";

class MyJournalList extends Component {
  render() {
    const { journals, searched, searchingJournals, userData } = this.props;
    if (searchingJournals || !userData) return <LoadingCard />;
    return (
      <div className="animate-fade-in-up">
        {Object.keys(journals).length > 0 &&
          Object.keys(journals).map((month, index) => {
            return (
              <MyJournalListItem
                month={month}
                monthJournal={journals[month]}
                key={index}
              />
            );
          })}
        {Object.keys(journals).length === 0 && (
          <div style={{ textAlign: "center", paddingTop: 60 }}>
            {!searched && (
              <img
                src={gnjLogoMobile}
                style={{ width: 120 }}
                alt="Goodnight Jornal logo"
              />
            )}
            <h2>
              {searched ? "Nothing found..." : "Write your first journal :)"}
            </h2>
          </div>
        )}
      </div>
    );
  }
}

export default MyJournalList;
