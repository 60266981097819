import React, { Component } from 'react';
import Footer from "./Footer";

class UserFeedback extends Component{
  render() {
    return(
      <div className="feedback">
        <div className="Container Container--md">
          <p>&nbsp;</p>
          <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdkUsNeUnHwe5XSbjbIxz-gtmVreFyuX_w7QEITnPY6MBi1GA/viewform?embedded=true" width="100%" height="3200" frameBorder="0" marginHeight="0" marginWidth="0" scrolling="no" allowtransparency="false" title="User Feedback">Loading…</iframe>
          <div>
            <Footer />
          </div>
        </div>
      </div>
    )
  }
}

export default UserFeedback
