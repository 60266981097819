import React, { Component } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getCurrentUserSession } from './actions/user'
import LoadingCard from './components/LoadingCard'

import Navigation from './containers/Navigation'
import Login from './containers/Auth/Login'
import Signup from './containers/Auth/Signup'
import ConfirmAccount from './containers/Auth/ConfirmAccount'
import ResendVerificationCode from './containers/Auth/ResendVerificationCode'
import ForgotPassword from './containers/Auth/ForgotPassword'
import ForgotUsername from './containers/Auth/ForgotUsername'
import SetNewPassword from './containers/Auth/SetNewPassword'

import MyJournal from './containers/MyJournal'
import WriteJournal from './containers/WriteJournal'
import JournalEntry from './containers/JournalEntry'
import PublicJournalEntry from './containers/PublicJournalEntry'
import EditJournal from './containers/EditJournal'
import Community from './containers/Community'
import Challenge from './containers/Challenge'
import Chat from './containers/Chat'
import ChatRoom from './containers/ChatRoom'
import Notification from './containers/Notification'
import MyAccount from './containers/MyAccount'
import Author from './containers/Author'
import Billing from './containers/Billing'
import Setting from './containers/Setting'
import CancelConfirm from './containers/CancelConfirm'
import NoMatch from './components/NoMatch'

import MemberLevel from './components/MemberLevel'
import Following from './containers/Following'
import Follower from './containers/Follower'
import LovedUsers from './containers/LovedUsers'
import Contact from './components/Contact'
import UserFeedback from './components/UserFeedback'

import MyBooks from './containers/MyBooks'
import BookJournals from './containers/BookJournals'

import { createNotificationSubscription } from './actions/notification'

import gnjFav from './assets/images/favicon.svg'

import ReactGA from 'react-ga'
ReactGA.initialize('UA-37230984-1')
ReactGA.pageview(window.location.pathname + window.location.search)

const PrivateRoute = ({ component: Component, ...rest }) => {
  const loading = rest.auth.isFetchingCurrentUserSession
  if (loading) return <LoadingCard />
  return (
    <Route
      {...rest}
      render={(props) =>
        rest.auth.isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  )
}

class App extends Component {
  constructor(props) {
    super(props)
    // Dark mode
    let d = document.getElementsByTagName('HTML')[0],
      m = localStorage.getItem('darkmode')
    if (m === 'true') {
      d.classList.add('dark-mode')
      window.ReactNativeWebView &&
        window.ReactNativeWebView.postMessage('dark-mode')
    }
  }

  async componentDidMount() {
    // Get real time notification
    await this.props.createNotificationSubscription()
    // Get accountData and userData
    try {
      await this.props.getCurrentUserSession()
    } catch (err) {
      console.log(err)
    }

    // GA event tracking
    document
      .querySelectorAll('a, button, select, .trackit')
      .forEach(function (elem) {
        elem.addEventListener(
          'click',
          function () {
            let myId = elem.id
            ReactGA.event({
              category: 'GoodnightJournal App', //TODO: Replace this with username
              action: 'click',
              label: myId,
            })
          },
          false
        )
      })
  }

  render() {
    const { user } = this.props

    if (user.isFetchingCurrentUserSession !== false) {
      return (
        <div>
          <img
            src={gnjFav}
            className="logo-loading"
            alt="Loading GoodnightJournal.com"
          />
        </div>
      )
    }

    return (
      <Router>
        <header className="main-header" id="main_header">
          <Navigation />
        </header>
        <div className="App">
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/signup" component={Signup} />
            <Route path="/confirm-account" component={ConfirmAccount} />
            <Route
              path="/resend-verification-code"
              component={ResendVerificationCode}
            />
            <Route path="/forgot-password" component={ForgotPassword} />
            <Route path="/forgot-username" component={ForgotUsername} />
            <Route path="/set-new-password" component={SetNewPassword} />
            <Route path="/community" component={Community} />
            <Route path="/contact" component={Contact} />
            <Route
              path="/public-journal/:journal_uid"
              component={PublicJournalEntry}
            />

            <PrivateRoute
              exact
              path="/journal/:journal_uid"
              component={JournalEntry}
              auth={user}
            />
            <PrivateRoute
              exact
              path="/author/:author_uid"
              component={Author}
              auth={user}
            />
            <PrivateRoute path="/level" component={MemberLevel} auth={user} />
            <PrivateRoute
              exact
              path="/following/:author_uid"
              component={Following}
              auth={user}
            />
            <PrivateRoute
              exact
              path="/follower/:author_uid"
              component={Follower}
              auth={user}
            />
            <PrivateRoute
              exact
              path="/loved/:category/:journalOrComment_uid"
              component={LovedUsers}
              auth={user}
            />
            <PrivateRoute exact path="/" component={MyJournal} auth={user} />
            <PrivateRoute path="/write" component={WriteJournal} auth={user} />
            <PrivateRoute
              exact
              path="/edit/:journal_uid"
              component={EditJournal}
              auth={user}
            />
            <PrivateRoute path="/challenge" component={Challenge} auth={user} />
            <PrivateRoute exact path="/chat" component={Chat} auth={user} />
            <PrivateRoute
              exact
              path="/chat/:chatRoom_uid"
              component={ChatRoom}
              auth={user}
            />
            <PrivateRoute
              path="/notification"
              component={Notification}
              auth={user}
            />
            <PrivateRoute
              path="/my-account"
              component={MyAccountPage}
              auth={user}
            />
            <PrivateRoute
              path="/feedback"
              component={UserFeedback}
              auth={user}
            />
            <PrivateRoute exact path="/books" component={MyBooks} auth={user} />
            <PrivateRoute
              exact
              path="/book/:book_uid"
              component={BookJournals}
              auth={user}
            />

            <Route component={NoMatch} />
          </Switch>
        </div>
      </Router>
    )
  }
}

const MyAccountPage = ({ match }) => (
  <div>
    <Route exact path={match.url} component={MyAccount} />
    <Route path={`${match.url}/billing`} component={Billing} />
    <Route path={`${match.url}/setting`} component={Setting} />
    <Route path={`${match.url}/cancel`} component={CancelConfirm} />
  </div>
)

function mapStateToProps(state) {
  return state
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getCurrentUserSession,
      createNotificationSubscription,
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
