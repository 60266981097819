import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';

import Footer from "../../components/Footer";
import loadingIcon from '../../assets/images/loading.svg';

import Amplify, { API } from 'aws-amplify';
import aws_exports from '../../aws-exports';
import * as custom_mutations from '../../customgraphql/mutations';

Amplify.configure(aws_exports);

class ForgotUsername extends Component {
  constructor(){
    super()
    this.state = {
      email: '',
      errorMsg: '',
      isLoading: false,
      emailSent: false
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  _onChange = async e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  _findUsername = async e => {
    e.preventDefault()
    this.setState({ isLoading: true })
    const { email } = this.state;
    const emailLowercase = email.toLowerCase()

    if(emailLowercase.length > 3){
      try {
        const data = await API.graphql({
          query: custom_mutations.gnjAmplifyForgotUsername,
          variables: {
            email: emailLowercase
          },
          authMode: 'AWS_IAM'
        });
        const result = JSON.parse(data.data.gnjAmplifyForgotUsername)

        !result.err ? 
        this.setState({
          errorMsg: "Sent username to your email! It might be in junk mail.",
          isLoading: false,
          emailSent: true
        })
        : 
        this.setState({
          errorMsg: result.err,
          isLoading: false
        })
      } catch (e) {
        console.log(e)
        this.setState({
          errorMsg: "Something went wrong. Please try again.",
          isLoading: false
        })
      }
    }
  }

  render() {
    const { user } = this.props
    const { username, errorMsg, isLoading, emailSent } = this.state

    if (user.isAuthenticated) {
      return <Redirect to="/" />
    }

    return(
      <div>
        <div className="Container Container--sm sm-form">
          <h3>Enter your email address to find your username</h3>
          <form onSubmit={this._findUsername} >
            <input
              type="email"
              name="email"
              value={username}
              placeholder="Email"
              onChange={this._onChange}
              autoCorrect="off"
              autoCapitalize="none"
              required
            />

            {errorMsg ? <p className={errorMsg.includes("Sent username to your email") ? "success-msg" : "error-msg"}>{errorMsg}</p> : ''}
            <button
              id="forgotUsername_forgot_username_button"
              disabled={isLoading || emailSent}
            >
              {isLoading ? <img alt="Loading..." className="loading-icon" src={loadingIcon} /> : 'Submit'}
            </button>
          </form>
          <div style={{ marginTop: 8 }}>
            <p>
              <small>
                <Link to="/forgot-password" id="login_forgot_password">Forgot password</Link> 
              </small>
            </p>
          </div>
          <div style={{ padding: 16 }}>
            <p>or <Link to="/login" id="forgotUsername_login">back to Log in</Link></p>
          </div>
        </div>
        <div>
          <Footer />
        </div>
      </div>
    )
  }
};

const mapStateToProps = state => {
  return state
}

export default connect(
  mapStateToProps
)(ForgotUsername);
