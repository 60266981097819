import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import QuillEditor from './QuillEditor'

import moment from 'moment'

import ProfilePic from '../utils/ProfilePic'

class PublicJournalEntryContent extends Component {
  _userLovedJournal = () => {
    const { journalEntry, loveJournal, userUid } = this.props
    loveJournal(journalEntry.id, userUid)
  }

  _userUnlovedJournal = (e) => {
    const { unloveJournal } = this.props
    unloveJournal(e.target.getAttribute('data-id'))
  }

  _renderLovedBtn = () => {
    const { stateUpdating, isUserLovedJournal } = this.props
    if (isUserLovedJournal.length > 0) {
      return (
        <button
          className="link-btn loved"
          onClick={this._userUnlovedJournal}
          disabled={stateUpdating}
          id="journalEntry_unlove_journal_button"
        >
          <ion-icon name="heart" data-id={isUserLovedJournal[0].id}></ion-icon>
        </button>
      )
    } else {
      return (
        <button
          className="link-btn unloved"
          onClick={this._userLovedJournal}
          disabled={stateUpdating}
          id="journalEntry_love_journal_button"
        >
          <ion-icon name="heart-empty"></ion-icon>
        </button>
      )
    }
  }

  _copyURL = () => {
    const ask = window.confirm('Copy URL to clipboard?')
    if (ask) {
      let dummy = document.createElement('input'),
        URLtoCopy = window.location.href
      document.body.appendChild(dummy)
      dummy.value = URLtoCopy

      let range, selection

      range = document.createRange()
      range.selectNodeContents(dummy)
      selection = window.getSelection()
      selection.removeAllRanges()
      selection.addRange(range)
      dummy.setSelectionRange(0, 999999)

      dummy.select()
      document.execCommand('copy')
      document.body.removeChild(dummy)
    }
  }

  render() {
    const { journalEntry } = this.props

    if (!journalEntry) return null

    const journalInteraction =
      journalEntry.privacy === 'PUBLIC' && !journalEntry.draft ? (
        <div className="journal-list-item-footer journal-loved-ui">
          <div>
            {this._renderLovedBtn()}&nbsp;
            {journalEntry.lovedCount > 0 ? (
              <Link
                to={'/loved/journal/' + journalEntry.id}
                id="journalEntry_loved_users"
              >
                {journalEntry.lovedCount}
              </Link>
            ) : (
              0
            )}
          </div>
          <div>
            <a href="#comment_write_form" id="journalEntry_comment_users">
              <ion-icon name="ios-chatbubbles"></ion-icon>{' '}
              {journalEntry.commentCount ? journalEntry.commentCount : 0}
            </a>
          </div>
          <div>
            <button
              className="link-btn share-icon"
              id={'shared_journal_' + journalEntry.id}
              onClick={this._copyURL}
            >
              <ion-icon name="ios-share-alt"></ion-icon>
              {'Share'}
            </button>
          </div>
        </div>
      ) : (
        ''
      )

    return (
      <div className="journal-readonly animate-fade-in-up">
        <div>
          <div className="author">
            <Link
              to={'/author/' + encodeURIComponent(journalEntry.author.id)}
              id={'journalEntry_author_id_' + journalEntry.author.id}
            >
              <img
                src={ProfilePic(journalEntry.author)}
                className="profile-pic"
                alt="User profile pic"
              />
            </Link>
            <time>
              <span style={{ textTransform: 'initial' }}>
                <Link
                  to={'/author/' + encodeURIComponent(journalEntry.author.id)}
                  id={'journalEntry_author_id_' + journalEntry.author.id}
                >
                  <b>
                    {journalEntry.author.displayName || journalEntry.author.id}
                  </b>
                </Link>
              </span>
              <br />
              {moment(journalEntry.createdAt).format('llll')}
            </time>
          </div>
          <p className="journal-entry-privacy">
            {journalEntry.book && (
              <Link
                to={'/book/' + journalEntry.book.id}
                id={'journalEntry_book_id_' + journalEntry.book.id}
                className="book-tag"
              >
                <span className="badge">
                  <ion-icon name="book"></ion-icon> {journalEntry.book.book}
                </span>
              </Link>
            )}{' '}
            {journalEntry.draft && (
              <span className="badge badge--grey">Draft</span>
            )}{' '}
            {journalEntry.privacy === 'PRIVATE' && (
              <span className="badge badge--grey">Private</span>
            )}
          </p>
        </div>
        <QuillEditor readOnly={true} value={journalEntry.content} />
        {journalInteraction}
      </div>
    )
  }
}

export default PublicJournalEntryContent
